import gameMachineImg from 'assets/vectors/game-machine.svg';
import gamePadLargeImg from 'assets/vectors/game-pad-large.svg';

import {
  LandingHeadingSectionBottomImg,
  LandingHeadingSectionContent,
  LandingHeadingSectionTopImg,
  LandingHeadingSectionWrapper,
} from './styled';

export const LandingHeadingSection = () => {
  return (
    <LandingHeadingSectionWrapper>
      <LandingHeadingSectionTopImg
        src={gamePadLargeImg}
        alt="game-page-large"
      />
      <LandingHeadingSectionContent>
        <div className="heading-section-title">BIGA ARCADES VISION</div>
        <div className="heading-section-title-extra">
          Capture levels, own levels, earn from levels.
        </div>
        <div className="heading-section-description">
          Gaming is a $250 billion dollar industry. Gamers should be making
          money too! Thanks to BIGA, now they can. Play games on BIGA, make the
          leaderboard, and earn an income straight to your bank. Simple as that!
        </div>
      </LandingHeadingSectionContent>
      <LandingHeadingSectionBottomImg src={gameMachineImg} alt="game-machine" />
    </LandingHeadingSectionWrapper>
  );
};

import { PopupButton } from '@typeform/embed-react';
import { Button } from 'antd';
import videoPosterImg from 'assets/vectors/game-video-bg.svg';
import styled, { css } from 'styled-components';

export const SocialText = styled.div`
  color: #fff;
  font-size: 15.1846px;
  font-weight: 400;
  line-height: 18px;
`;

export const SocialIcon = styled.img`
  @media (max-width: 576px) {
    width: 22px !important;
  }
`;

export const SocialWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  gap: 1.5rem;

  @media (max-width: 576px) {
    gap: 1rem;
  }
`;

export const LandingSectionWrapper = styled.div`
  padding: 0 8.88rem;
  @media (max-width: 1280px) {
    padding: 0 4.44rem;
  }
  @media (max-width: 768px) {
    padding: 0 1rem;
  }
`;

export const LandingHeadingSectionWrapper = styled(LandingSectionWrapper)`
  display: flex;
  flex-direction: column;

  margin-bottom: 2.84rem;
  @media (max-width: 1280px) {
    margin-bottom: 1.42rem;
  }
`;

export const LandingHeadingSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 3rem;
  @media (max-width: 1280px) {
    gap: 1.5rem;
  }

  .heading-section-title {
    color: #fff;
    font-family: 'Changeling Neo';
    font-size: 4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3.5rem;
    text-transform: uppercase;
    @media (max-width: 1280px) {
      font-size: 2rem;
      line-height: 1.75rem;
    }
  }

  .heading-section-title-extra {
    color: #fff;
    font-family: 'Neue Haas Grotesk Display Pro';
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.5rem; /* 125% */
    @media (max-width: 1280px) {
      font-size: 1rem;
      line-height: 1.25rem;
    }
  }

  .heading-section-description {
    color: #fff;
    text-align: center;
    font-family: 'Neue Haas Grotesk Display Pro';
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.5rem;
    margin: 0 7.35rem;
    @media (max-width: 768px) {
      margin: 0 1rem;
    }
    @media (max-width: 1280px) {
      font-size: 1.25rem;
      line-height: 1.25rem;
    }
  }
`;

export const LandingHeadingSectionTopImg = styled.img`
  width: 11.75rem;
  margin-bottom: 5.4rem;
  @media (max-width: 1280px) {
    width: 5.88rem;
    margin-bottom: 3rem;
  }
`;

export const LandingHeadingSectionBottomImg = styled.img`
  width: 14.75rem;
  margin-top: -3rem;
  margin-right: -7rem;
  @media (max-width: 1280px) {
    width: 7.38rem;
    margin-top: 0;
    margin-right: -1.5rem;
  }
  align-self: flex-end;
`;

export const LandingVideoSectionWrapper = styled(LandingSectionWrapper)`
  position: relative;

  background: linear-gradient(180deg, #878273 80%, #0b3885 20%);
  padding-top: 6.38rem;
  @media (max-width: 1280px) {
    padding-top: 3.19rem;
  }

  .video-section-title {
    color: #fff;
    text-align: center;
    font-family: 'Changeling Neo';
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3.5rem;
    text-transform: uppercase;
    margin-bottom: 3.75rem;
    @media (max-width: 1280px) {
      font-size: 1.5rem;
      line-height: 1.75rem;
      margin-bottom: 2.15rem;
    }
  }
`;

export const LandingVideoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1.5rem;
  }
`;

export const LandingVideo = styled.video`
  width: 60vw;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const LandingIframeWrapper = styled.div`
  position: relative;
  padding-bottom: 56%;
  height: 0;
  overflow: hidden;
  width: 100%;
  width: 60vw;
  @media (max-width: 768px) {
    width: 100%;
  }
  background-image: url(${videoPosterImg});
`;

export const LandingPreIframe = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const LandingIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const LandingVideoPlayWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;

  min-width: 10rem;
  margin-left: -5.125rem;
  @media (max-width: 1280px) {
    margin-left: -2.5rem;
  }
  @media (max-width: 768px) {
    margin-left: 0;
    justify-content: center;
  }
`;

export const LandingVideoFrame = styled.div`
  display: flex;
  align-items: center;
`;

export const LandingVideoPlayButton = styled.img`
  cursor: pointer;
  width: 164px;
  height: 164px;
  @media (max-width: 1280px) {
    width: 5rem;
  }

  scale: 1;
  :hover,
  :focus {
    scale: 1.1;
    transition: 0.1s ease all;
  }
`;

export const LandingVideoPlayLinkButton = styled.span`
  cursor: pointer;
  color: #fff;
  font-family: 'Changeling Neo';
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 701;
  line-height: 3.5rem;
  text-transform: uppercase;
  white-space: nowrap;

  text-decoration-line: underline;
  text-decoration-thickness: 0.05rem;
  text-underline-offset: 0.4rem;

  @media (max-width: 1280px) {
    font-size: 1.25rem;
    line-height: 1.75rem;
    text-underline-offset: 0.2rem;
  }
`;

export const LandingWaitListSectionWrapper = styled(LandingSectionWrapper)`
  margin-top: 10rem;
`;

export const LandingWaitListTitle = styled.div`
  color: #fff;
  font-family: 'Changeling Neo';
  font-size: 3rem;
  font-style: normal;
  font-weight: 701;
  line-height: 3.5rem;
  text-transform: uppercase;
  @media (max-width: 1280px) {
    font-size: 2rem;
    line-height: 2.2rem;
  }

  span.wait-list-text {
    color: #bc9c3f;

    text-decoration-line: underline;
    text-decoration-thickness: 0.05rem;
    text-underline-offset: 0.4rem;
  }
  @media (max-width: 1100px) {
    text-align: center;
  }
`;

export const LandingWaitListContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  margin-top: 1.5rem;
  @media (max-width: 1100px) {
    flex-direction: column;
    text-align: center;
  }
`;

export const LandingWaitListContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  margin-left: -1rem;

  color: #fff;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.5rem;
  @media (max-width: 1280px) {
    font-size: 1.25rem;
    line-height: 1.5rem;
    width: 70%;
  }
  @media (max-width: 1100px) {
    align-items: center;
  }
  position: relative;

  .wait-list-content-underlined {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
  }
`;

export const LandingWaitListContentBottomImg = styled.img`
  position: absolute;
  width: 8.75rem;
  top: 8rem;
  right: -1rem;
  @media (max-width: 1280px) {
    width: 5.5rem;
    top: 6rem;
    right: -2rem;
  }
`;

const baseButtonStyles = css`
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.08);
  background: transparent !important;
  padding: 0.625rem;
  width: fit-content;
  height: fit-content;

  :hover {
    border: 1px solid rgba(255, 255, 255, 0.08);
    text-decoration: none;
    background-color: transparent;
  }

  & > div {
    display: flex;
    padding: 1rem 1.5rem;
    gap: 1rem;
    min-width: 245px;
    align-items: center;

    @media (max-width: 720px) {
      padding: 0.3rem 0.5rem;
    }

    @media (max-width: 1280px) {
      min-width: 155px;
      padding: 0.5rem 0.75rem;
    }

    span,
    a {
      white-space: nowrap;
      @media (max-width: 720px) {
        font-size: 0.75rem;
      }
    }
  }
`;

export const LandingWaitListJoinButtonWrapper = styled(Button)`
  ${baseButtonStyles}
  & > div {
    background: rgba(255, 255, 255, 0.08);
    span,
    a {
      color: #fff;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.5rem;
      @media (max-width: 1280px) {
        font-size: 0.75rem;
      }
    }
  }
`;

export const LandingWhitepaperButtonWrapper = styled(Button)`
  ${baseButtonStyles}
  & > div {
    background: #fff;
    justify-content: center;
    span,
    a {
      color: #0b3885;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.5rem;
      @media (max-width: 1280px) {
        font-size: 0.75rem;
      }
    }
  }
`;

export const LandingWaitListUserNetImg = styled.img`
  height: 403.2px;
  margin-top: -2rem;
  width: 403.2px;
  max-width: 100%;
`;

export const LandingGameSectionWrapper = styled(LandingSectionWrapper)`
  margin-top: 10.75rem;
`;

export const LandingGameHeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LandingGameHeading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .game-heading-title {
    color: #fff;
    font-family: 'Changeling Neo';
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3rem;
    text-transform: uppercase;
    width: 70%;
    @media (max-width: 1280px) {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
  }

  .game-heading-description {
    color: #fff;
    font-family: 'Neue Haas Grotesk Display Pro';
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2rem;
    width: 70%;
    @media (max-width: 1280px) {
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }
`;

export const LandingGameWaitListJoinButton = styled(Button)`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 100%;
  width: fit-content;
  border: none;
  :hover {
    text-decoration: none;
    background-color: transparent;
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
  }
  @media (max-width: 1280px) {
    gap: 0.5rem;
  }
  background: transparent;

  span,
  a {
    color: #fff;
    font-family: 'Neue Haas Grotesk Display Pro';
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    @media (max-width: 1280px) {
      font-size: 0.75rem;
      line-height: 0.75rem;
    }
  }

  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
  padding: 0.5rem 0;

  white-space: nowrap;
`;

export const LandingGameListWrapper = styled.div`
  margin-top: 3rem;
  margin-bottom: 2.5rem;

  display: flex;
  flex-direction: column;
  @media (max-width: 948px) {
    gap: 1rem;
  }
`;

export const LandingGameRow = styled.div`
  display: flex;

  & .game-row-image {
    width: 50%;
    @media (max-width: 948px) {
      width: 100%;
    }
    display: flex;
    align-items: center;

    position: relative;
    height: 0;
    padding-top: 28%;
    @media (max-width: 948px) {
      padding-top: 55%;
    }
    & img {
      width: 100%;

      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  & .game-row-detail {
    width: 50%;
    @media (max-width: 948px) {
      width: 100%;
    }

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 2rem;

    & .game-row-detail-name {
      color: #fff;
      font-family: 'Changeling Neo';
      font-size: 2rem;
      font-style: normal;
      font-weight: 701;
      line-height: normal;
      text-transform: uppercase;

      padding-top: 1.5rem;
      border-top: 2px solid #878273;
      @media (max-width: 1400px) {
        font-size: 1.5rem;
        padding-top: 1.25rem;
      }
      @media (max-width: 1080px) {
        font-size: 1rem;
        padding-top: 0.75rem;
      }
    }
    & .game-row-detail-description {
      color: #fff;
      font-family: 'Neue Haas Grotesk Display Pro';
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      @media (max-width: 1400px) {
        font-size: 1rem;
        padding-top: 0.75rem;
      }
      @media (max-width: 1080px) {
        font-size: 0.75rem;
        padding-top: 0.5rem;
      }
    }
  }

  &:nth-child(even) {
    flex-direction: row-reverse;
    @media (max-width: 948px) {
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }

    & .game-row-detail {
      padding-right: 3rem;
      @media (max-width: 948px) {
        padding-right: 0;
      }
    }
  }
  &:nth-child(odd) {
    & .game-row-detail {
      padding-left: 3rem;
      @media (max-width: 948px) {
        padding-left: 0;
      }
    }
  }
  @media (max-width: 948px) {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
`;

export const LandingGamesFooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  color: #fff;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 3.5rem;
  text-decoration-line: underline;
  @media (max-width: 1280px) {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
  & a {
    color: #fff;
    text-decoration-line: underline;
    font-weight: 700;
  }

  & img {
    min-width: 28rem;
    max-width: 100%;
    object-fit: contain;
  }
  position: relative;
  & div {
    position: absolute;
    width: 70%;
    max-width: 100%;
  }
`;

export const LandingPartnerSectionWrapper = styled(LandingSectionWrapper)`
  /* padding-right: 14rem;
  padding-left: 14rem;
  @media (max-width: 1280px) {
    padding-right: 7rem;
    padding-left: 7rem;
  } */
  margin-top: 5rem;
  margin-bottom: 10.25rem;

  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 4rem;

  .partner-title {
    color: #fff;
    font-family: 'Changeling Neo';
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    @media (max-width: 1280px) {
      font-size: 1.5rem;
    }
  }
`;

export const LandingPartnerListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
`;

export const LandingDeveloperSectionWrapper = styled(LandingSectionWrapper)`
  background: #06204b;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;

  padding-top: 8.75rem;
  padding-bottom: 3rem;

  .lets-talk-title {
    color: #fff;
    text-align: center;
    font-family: 'Changeling Neo';
    font-size: 4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 4rem;
    text-transform: uppercase;
    width: 65%;
    @media (max-width: 1280px) {
      font-size: 2rem;
      line-height: 2rem;
    }

    @media (max-width: 448px) {
      font-size: 1rem;
      line-height: 1rem;
    }
  }
  .lets-talk-description {
    color: #fff;
    text-align: center;
    font-family: 'Neue Haas Grotesk Display Pro';
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 65%;
    @media (max-width: 1280px) {
      font-size: 0.75rem;
    }
  }
`;

export const LandingFooterDividerWrapper = styled(LandingSectionWrapper)`
  background: #06204b;
  div {
    height: 0.0625rem;
    background: rgba(255, 255, 255, 0.24);
  }
`;

export const LandingLetsTalkButtonWrapper = styled(PopupButton)`
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.08);
  background: transparent;

  display: inline-flex;
  width: fit-content;

  padding: 0;
`;

export const LandingLetsTalkButton = styled.div`
  background: rgba(255, 255, 255, 0.08);
  margin: 0.625rem;
  display: flex;
  align-items: center;

  span,
  a {
    color: #fff;
    text-align: center;
    font-family: 'Neue Haas Grotesk Display Pro';
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0 1.5rem;
    @media (max-width: 1280px) {
      font-size: 0.75rem;
      margin: 0 0.75rem;
    }
  }
`;

export const LandingFooterSectionWrapper = styled(LandingSectionWrapper)`
  background: #06204b;

  padding-top: 5rem;
  padding-bottom: 7.5rem;
`;

export const LandingFooterContentWrapper = styled.div`
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
`;

export const LandingFooterContentContactUs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  @media (max-width: 1100px) {
    gap: 2rem;
  }

  color: #fff;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (max-width: 1434px) {
    font-size: 0.8rem;
  }

  div:first-child {
    opacity: 0.6;
  }
`;

export const LandingFooterMailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .mail-to-contact {
    width: fit-content;
    color: #fff;
    opacity: 1;
    font-weight: 500;

    padding-bottom: 0.4rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
  }
`;

export const LandingFooterContentSocialLinksWrapper = styled.div`
  display: flex;
  gap: 3rem;
  @media (max-width: 1434px) {
    gap: 2rem;
  }

  & a {
    color: #fff;
    font-family: 'Neue Haas Grotesk Display Pro';
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    @media (max-width: 1434px) {
      font-size: 1.1rem;
    }
    @media (max-width: 1100px) {
      font-size: 0.8rem;
    }
  }

  & a.active-link {
    text-decoration-line: underline;
    text-decoration-thickness: 0.05rem;
    text-underline-offset: 0.4rem;
  }

  & a.blur-link {
    opacity: 0.6;
    text-decoration-line: none;
    &:focus,
    &:hover {
      text-decoration-line: underline;
      text-decoration-thickness: 0.05rem;
      text-underline-offset: 0.4rem;
      opacity: 1;
    }
  }
`;

export const LandingFooterContentPolicyLinksWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin-top: 2rem;
  @media (max-width: 1434px) {
    gap: 2rem;
  }

  & a {
    color: #fff;
    font-family: 'Neue Haas Grotesk Display Pro';
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media (max-width: 1434px) {
      font-size: 0.8rem;
    }
    @media (max-width: 1100px) {
      font-size: 0.8rem;
    }
  }

  & a.active-link {
    text-decoration-line: underline;
    text-decoration-thickness: 0.05rem;
    text-underline-offset: 0.4rem;
  }

  & a.blur-link {
    opacity: 0.6;
    text-decoration-line: none;
    &:focus,
    &:hover {
      text-decoration-line: underline;
      text-decoration-thickness: 0.05rem;
      text-underline-offset: 0.4rem;
      opacity: 1;
    }
  }
`;

export const LandingFooterContentPolicyLinksColumn = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2.5rem;
  @media (max-width: 1434px) {
    gap: 1.5rem;
  }
`;

export const LandingFooterContentSocialLinksColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  @media (max-width: 1434px) {
    gap: 1.5rem;
  }
`;

// export const LandingFooterWaitListJoinWrapper = styled.div`
//   &.footer-wait-list-join-wrapper-inside {
//     display: flex;
//     flex-direction: column;
//     gap: 10px;
//     @media (max-width: 768px) {
//       display: none;
//     }
//   }

//   &.footer-wait-list-join-wrapper-outside {
//     display: none;
//     @media (max-width: 768px) {
//       align-items: center;
//       display: flex;
//       flex-direction: column;
//       justify-content: center;
//       margin-top: 2rem;
//       gap: 10px;
//     }
//   }
// `;

export const LandingFooterWaitListJoinWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  &.footer-wait-list-join-wrapper-inside {
    @media (max-width: 768px) {
      display: none;
    }
  }

  &.footer-wait-list-join-wrapper-outside {
    display: none;
    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 2rem;
    }
  }
`;

export const LandingFooterBottomDiv = styled.div`
  height: 2.75rem;
  background: #0b3885;
`;

export const TopEarnersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 448px;
  max-width: 100%;
  margin-right: 142px;

  @media (max-width: 480px) {
    transform: scale(0.8) !important;
  }

  @media (max-width: 1280px) {
    margin: 0;
    transform: scale(0.9);
  }

  @media (max-width: 480px) and (max-height: 740px) {
    transform: scale(0.7) !important;
    @media (max-height: 700px) {
      position: absolute;
      top: 16%;
    }
  }
`;

export const FirstBlockWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid var(--border-border-white, rgba(255, 255, 255, 0.24));
`;

export const FirstBlockItem = styled.div`
  width: 100%;
  max-width: 100%;
  gap: 8px;
  padding: 16px 0;
  flex: 1 0 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-right: 1px solid var(--border-border-white, rgba(255, 255, 255, 0.24));

  &:last-child {
    border-right: none;
  }
`;

export const FirstBlockItemTitle = styled.div`
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  line-height: normal;
  font-family: Neue Haas Grotesk Display Pro;
`;
export const FirstBlockItemValue = styled.div`
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
  text-align: center;
  line-height: normal;
  font-family: Neue Haas Grotesk Display Pro;
`;

export const SecondBlockWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  height: 365px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--border-border-white, rgba(255, 255, 255, 0.24));
`;

export const SecondBlockTitle = styled.div`
  display: flex;
  padding: 16px 0px 8px 0px;
  justify-content: center;
  align-items: center;

  color: #fff;
  font-family: Changeling Neo;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
`;

export const SecondBlockButtonWrapper = styled.div`
  gap: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

export const SecondBlockButton = styled.div<{ activated?: boolean }>`
  padding: 16px 0;
  border-bottom: ${props => (props.activated ? '2px solid #fff' : 'none')};

  color: #fff;
  font-size: 14px;
  font-weight: ${props => (props.activated ? '700' : '400')};
  font-style: normal;
  line-height: normal;
  font-family: Neue Haas Grotesk Display Pro;
`;

export const SecondBlockItemWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--border-border-white, rgba(255, 255, 255, 0.24));
  border-bottom: none;
`;

export const SecondBlockItem = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid var(--border-border-white, rgba(255, 255, 255, 0.24));
  &:nth-child(5) {
    border-bottom: none;
  }
`;

export const SecondBlockLeft = styled.div`
  gap: 16px;
  display: flex;
  align-items: center;
`;

export const OrderWrapper = styled.div<{ order?: number }>`
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;

  ${({ order }) => {
    if (order === 1) {
      return css`
        background-color: #f0b805;
      `;
    } else if (order === 2) {
      return css`
        background-color: #a3b5cc;
      `;
    } else if (order === 3) {
      return css`
        background-color: #cd7f32;
      `;
    } else if (order === 4) {
      return css`
        background-color: #bbb9b4;
      `;
    } else if (order === 5) {
      return css`
        background-color: #0ebfe9;
      `;
    }
  }}
`;

export const OrderText = styled.div`
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  font-family: Neue Haas Grotesk Display Pro;
`;

export const ProfileWrapper = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
`;

export const ProfileAvatar = styled.img`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  user-select: none;
`;

export const ProfileName = styled.div`
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  font-family: Neue Haas Grotesk Display Pro;
`;

export const SecondBlockRight = styled.div`
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  font-family: Neue Haas Grotesk Display Pro;
`;

export const ScrollImage = styled.img`
  @media (max-width: 1280px) {
    height: 50px;
  }
`;

import { Col, Row, Spin } from 'antd';
import { useGetTokenInfo } from 'hooks/erc20/useGetTokenInfo';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { ApplicationModal } from 'redux/slices/application';
import { useModalIsOpen, useToggleModal } from 'redux/slices/application/hook';
import { addSupportedToken } from 'services/api';

import {
  AddThemedModal,
  SubmitButton,
  TextInputField,
  ThemedModal,
  Wrapper,
} from '../styled';
import { InfoStyled, WarningStyled } from './styled';

interface IAddTokenProps {
  onRefresh: () => void;
}

export const AddTokenModal = (props: IAddTokenProps) => {
  const isOpen = useModalIsOpen(ApplicationModal.ADD_TOKEN);
  const modalToggle = useToggleModal(ApplicationModal.ADD_TOKEN);

  const [address, setAddress] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmVisible, setConfirmVisible] = useState<boolean>(false);

  const handleAddressChange = e => {
    setAddress(e);
  };

  const handleAddToken = async () => {
    setConfirmVisible(false);
    setLoading(true);

    const response = await addSupportedToken({
      address,
    });
    if (response?.success) {
      toast.success('You have successfully added supported token');
      setAddress(undefined);
      props.onRefresh();
    } else toast.error('Add supported token failed!!');

    modalToggle();

    setLoading(false);
  };

  const { tokenInfo, loading: tokenLoading } = useGetTokenInfo(
    address,
    address as any,
  );

  return (
    <>
      <ThemedModal
        title={'Add supported token'}
        open={isOpen}
        onCancel={modalToggle}
        footer={false}
        width={550}
      >
        <Row>
          <Col sm={24}>
            <Spin spinning={tokenLoading}>
              <TextInputField
                type="string"
                value={address}
                onChange={e => handleAddressChange(e.target.value)}
                placeholder="Enter address"
                allowClear
              />
            </Spin>
            {!tokenInfo && address && !tokenLoading && (
              <WarningStyled>**Invalid token</WarningStyled>
            )}
            {tokenInfo && !tokenLoading && (
              <InfoStyled>Token: {tokenInfo.symbol}</InfoStyled>
            )}
          </Col>
        </Row>
        <Wrapper>
          <SubmitButton
            height="40px"
            width="150px"
            onClick={() =>
              tokenInfo && !tokenLoading && setConfirmVisible(true)
            }
            loading={loading}
            disabled={!tokenInfo || tokenLoading}
          >
            Add
          </SubmitButton>
        </Wrapper>
      </ThemedModal>
      <AddThemedModal
        visible={confirmVisible}
        onOk={handleAddToken}
        onCancel={() => setConfirmVisible(false)}
        okText={'Add'}
        cancelText="No"
        width={400}
        style={{ marginTop: '50px' }}
      >
        <p>Do you want to add this token?</p>
      </AddThemedModal>
    </>
  );
};

import styled from 'styled-components';

export const AdminPanelContainer = styled.div`
  text-align: center;
  max-width: 900px;
  margin: auto;
  margin-top: 5rem;

  .ant-tabs-nav:before {
    border-bottom: none;
  }

  .ant-tabs-card > .ant-tabs-nav {
    border-top: #fff 1px solid;
    padding-top: 15px;
    padding-bottom: 25px;
    width: 900px;
    margin: 0 auto;
    .ant-tabs-tab {
      border-radius: 6.5px;
      background-color: rgb(255 255 255 / 46%);
      .ant-tabs-tab-btn {
        color: #0b3885;
        font-size: 17px;
        font-weight: 600;
        line-height: 20px;
      }
      &.ant-tabs-tab-active {
        background-color: #fff;
      }
    }
  }
`;

export const Text = styled.div`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
`;

export const AdminPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

export const ButtonBlockWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.08);

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const ButtonWrapper = styled(Text)<{ active?: boolean }>`
  padding: 16px 0;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => (props?.active ? '#fff' : 'none')};
  color: ${props => (props?.active ? '#0A3885' : '#fff')};

  cursor: pointer;
  user-select: none;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;

  &:hover {
    background: #fff;
    color: #0a3885;
  }

  @media (max-width: 1200px) {
    align-self: stretch;
  }
`;

export const ContentBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

export const FirstBlockContentWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const FirstBlockItemWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

import styled from 'styled-components';

export const ForgotPasswordWrapper = styled.div`
  background: var(--biga-blue);
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

export const Logo = styled.img`
  margin-top: 2.5rem;
  max-width: 150px;
  margin-bottom: 1rem;
  cursor: pointer;
`;

export const StepWrapper = styled.div`
  top: calc(56px + 3rem);
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  position: sticky;
`;

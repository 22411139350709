import splashVideoSrc from 'assets/videos/biga-splash.mp4';

interface IProps {
  url?: string;
  onEnded?: () => void;
  className: string;
}
export const SplashVideo = (props: IProps) => {
  return (
    <>
      <iframe
        src=""
        itemType="video/mp4"
        allow="autoplay"
        id="video"
        className="d-none"
      ></iframe>

      <video
        autoPlay={true}
        controls={false}
        loop={false}
        onEnded={() => {
          if (props.onEnded) {
            props.onEnded();
          }
        }}
        playsInline={true}
        className={props.className}
      >
        {/* <source
          src={process.env.REACT_APP_ASSET + 'videos/biGAsplash.mp4'}
          type="video/mp4"
        /> */}
        <source src={splashVideoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </>
  );
};

/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

export const everflowClick: () => Promise<void> = async () => {
  try {
    await EF.click({
      offer_id: EF.urlParameter('oid') ?? 1,
      affiliate_id: EF.urlParameter('affid') ?? 1,
      sub1: EF.urlParameter('sub1') ?? 1,
      sub2: EF.urlParameter('sub2') ?? 1,
      sub3: EF.urlParameter('sub3') ?? 1,
      sub4: EF.urlParameter('sub4') ?? 1,
      sub5: EF.urlParameter('sub5') ?? 1,
      uid: EF.urlParameter('uid') ?? 1,
      source_id: EF.urlParameter('source_id') ?? 1,
      transaction_id: EF.urlParameter('_ef_transaction_id') ?? 1,
    });
  } catch (err) {
    console.log('Everflow not existed ', err);
  }
};

export const everflowConversion: () => Promise<void> = async () => {
  try {
    await EF.conversion({
      offer_id: 1,
    });
  } catch (err) {
    console.log('Everflow not existed ', err);
  }
};

import { Spin } from 'antd';
import closeicon from 'assets/vectors/logo-profile-close.svg';
import { useLogOut } from 'hooks/useLogOut';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { ApplicationModal } from 'redux/slices/application';
import { useModalIsOpen, useToggleModal } from 'redux/slices/application/hook';
import { deletePlayerAccount } from 'services/api';

import {
  CloseIcon,
  CloseWrapper,
  DeleteText,
  ModalConfirmDeleteTitle,
  ModalDeleteAccountWrapper,
  ModalDeleteButton,
  ModalDeleteButtonWrapper,
  ModalDeleteContainer,
  ModalDeleteWrapper,
  ModalWrapper,
} from './styled';

export const DeleteAccount = () => {
  const open = useModalIsOpen(ApplicationModal.PROFILE_DELETE_ACCOUNT);
  const toggleModal = useToggleModal(ApplicationModal.PROFILE_DELETE_ACCOUNT);
  const handleCancel = () => {
    toggleModal();
  };

  const [loading, setLoading] = useState<boolean>(false);
  const logOut = useLogOut();

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const res = await deletePlayerAccount();
      await new Promise(res => setTimeout(res, 3000));
      if (res?.success) {
        toast.success('Your account has been permanently deleted');
        toggleModal();

        logOut();
      } else {
        toast.error(res?.message || 'Delete account Failed');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalWrapper
      visible={open}
      onCancel={handleCancel}
      maskClosable={true}
      footer={null}
      closable={false}
      width="448px"
    >
      <ModalDeleteAccountWrapper>
        <CloseWrapper>
          <CloseIcon src={closeicon} onClick={handleCancel} />
        </CloseWrapper>
        <ModalDeleteContainer>
          <Spin spinning={loading}>
            <ModalDeleteWrapper>
              <ModalConfirmDeleteTitle>Last Chance !</ModalConfirmDeleteTitle>
              <DeleteText>
                Are you sure you want to delete the account?
              </DeleteText>
              <ModalDeleteButtonWrapper>
                <ModalDeleteButton onClick={handleSubmit}>
                  Delete permanently
                </ModalDeleteButton>
              </ModalDeleteButtonWrapper>
            </ModalDeleteWrapper>
          </Spin>
        </ModalDeleteContainer>
      </ModalDeleteAccountWrapper>
    </ModalWrapper>
  );
};

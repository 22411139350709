import { createSlice } from '@reduxjs/toolkit';

export enum ApplicationModal {
  DEPOSIT,
  GAME_DETAILS,
  REGISTER_GAME,
  REVIEW_INTEGRATE,
  UNREGISTERED,
  WITHDRAW,
  ADD_ADMIN,
  ADD_WHITELIST,
  ADD_TESTER,
  ADD_TOKEN,
  ADD_AFFILIATE,
  CONFIG_LEVEL_FEE,
  SET_LEVEL_FEE_FORM,
  PROFILE_UPLOAD_AVATAR,
  PROFILE_LEVEL_MASTERS,
  PROFILE_DELETE_ACCOUNT,
  PROFILE_CONFIRM_DELETE_ACCOUNT,
  PROFILE_VERIFY_OTP,
  GAME_DETAIL_DRAWER,
  CASHIER_SELECT_TOKEN,
  UPDATE_GAME,
  PLAYGAME_LEVEL_MASTER,
  PLAYGAME_LEADERBOARD,
  PLAYGAME_LEADERBOARD_TABLE,
  PAYMENT_CHECKOUT_SUCCESS,
  PAYMENT_CHECKOUT_ERROR,
}

type PopupList = Array<{
  key: string;
  show: boolean;
  removeAfterMs: number | null;
}>;

export interface ApplicationState {
  readonly openModal: ApplicationModal | null;
  readonly popupList: PopupList;
}

const initialState: ApplicationState = {
  openModal: null,
  popupList: [],
};

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setOpenModal(state, action) {
      state.openModal = action.payload;
    },
  },
});

export const { setOpenModal } = applicationSlice.actions;
export default applicationSlice.reducer;

import styled from 'styled-components';

export const MaintenanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  height: 100vh;
  gap: 3rem;
  @media (max-width: 1280px) {
    gap: 1.5rem;
  }

  .heading-section-title {
    color: #fff;
    font-family: 'Changeling Neo';
    font-size: 4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3.5rem;
    text-transform: uppercase;
    @media (max-width: 1280px) {
      font-size: 2rem;
      line-height: 1.75rem;
    }
  }

  .heading-section-description {
    color: #fff;
    text-align: center;
    font-family: 'Neue Haas Grotesk Display Pro';
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.5rem;
    margin: 0 7.35rem;
    @media (max-width: 768px) {
      margin: 0 1rem;
    }
    @media (max-width: 1280px) {
      font-size: 1.25rem;
      line-height: 1.25rem;
    }
  }
`;

import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { Dropdown, Tooltip } from 'antd';
import metaMaskIcon from 'assets/logo-metamask.png';
import geminiIcon from 'assets/vectors/login-gemini.svg';
import { LinkWalletModal } from 'components/Modals/LinkWallet';
import { PlayerStatus } from 'constants/enum/PlayerStatus';
import { PitRouter } from 'constants/routers';
import { formatUnits } from 'ethers/lib/utils';
import { useGetPlayer } from 'hooks/player/useGetPlayer';
import { useGetRemainingBalance } from 'hooks/token-balance/useGetRemainingBalance';
import useConnectors from 'hooks/useConnectors';
import { useDisconnect } from 'hooks/useDisconnect';
import { useLogOut } from 'hooks/useLogOut';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setSignar } from 'redux/slices/signar';
import { loginWallet } from 'redux/slices/user';
import { useAppSelector } from 'redux/store';
import { LocalhostStorage } from 'utils/sessionStorage';
import { injected, WalletConnectInstance } from 'utils/wallets/connector';

import {
  BigaCreditsText,
  BigaCreditsValueText,
  BigaCreditsWrapper,
  ConnectButtonText,
  ConnectButtonWrapper,
  ConnectDecriptionText,
  ConnectTitleText,
  ConnectWalletText,
  ConnectWalletWrapper,
  HeaderWrapper,
  Line,
  LogoHeaderRedDot,
  MenuMainButton,
  MenuMainButtonLogo,
  MenuMainButtonText,
  MenuMainButtonWrapper,
  MenuMainDecriptionStepsText,
  MenuMainDecriptionText,
  MenuMainDecriptionWrapper,
  MenuMainTextWrapper,
  MenuMainWrapper,
  MenuWrapper,
} from './styled';

interface IHeaderProps {
  theme?: string;
}

export default function Header({ theme }: IHeaderProps) {
  const { account, activate } = useWeb3React<Web3Provider>();
  const { onConnectMetamaskWallet } = useConnectors();
  const { refreshToken } = useAppSelector(state => state.refreshToken);

  const { remainingBalance } = useGetRemainingBalance(
    process.env.REACT_APP_DEFAULT_TOKEN_ADDRESS,
    refreshToken,
  );

  const CHAIN_ID = LocalhostStorage.get('chainId');
  const dispatch = useDispatch();
  const disconnect = useDisconnect();

  useEffect(() => {
    void (() => {
      const isConnected = Boolean(LocalhostStorage.get('isConnected'));
      const typeOfConnector = LocalhostStorage.get('typeOfConnector');

      if (isConnected && typeOfConnector === 'Metamask') {
        activate(injected(CHAIN_ID));
        dispatch(loginWallet(LocalhostStorage.get('account')));
      }

      if (isConnected && typeOfConnector === 'Walletconnect') {
        activate(WalletConnectInstance);
        dispatch(loginWallet(LocalhostStorage.get('account')));
      }
    })();
  }, [CHAIN_ID]);

  useEffect(() => {
    const isConnected = Boolean(LocalhostStorage.get('isConnected'));
    const walletAddress = LocalhostStorage.get('account');
    dispatch(setSignar(LocalhostStorage.get('isConnected')));

    if (!isEmpty(account)) {
      if (
        isConnected &&
        account?.toUpperCase() !== walletAddress?.toUpperCase()
      ) {
        disconnect();
      }
    }
    if (!isConnected) {
      // navigate(PitRouter.CONNECT);
      // disconnect();
    }
  }, [account]);

  const [refresh, setRefresh] = useState<boolean>(false);
  const { loading } = useGetPlayer(null, refresh);
  const navigate = useNavigate();

  const handleNavigaLogin = () => {
    navigate(PitRouter.LOGIN);
  };

  const handleNavigaRegister = () => {
    navigate(PitRouter.REGISTER);
  };

  useLogOut({
    refresh,
    onRefresh: () => {
      setRefresh(!refresh);
    },
  });

  const { playerInfo } = useAppSelector(state => state.player);

  const menu = (
    <MenuWrapper>
      <MenuMainWrapper>
        <MenuMainTextWrapper>
          <ConnectTitleText>Connect Wallet</ConnectTitleText>
          <ConnectDecriptionText>
            Connect your wallet by selecting below options.
          </ConnectDecriptionText>
        </MenuMainTextWrapper>

        <MenuMainButtonWrapper>
          <MenuMainButton onClick={onConnectMetamaskWallet}>
            <MenuMainButtonLogo src={metaMaskIcon} />
            <MenuMainButtonText>Connect Metamask</MenuMainButtonText>
          </MenuMainButton>
          <Tooltip title="Coming soon">
            <MenuMainButton disabled>
              <MenuMainButtonLogo src={geminiIcon} />
              <MenuMainButtonText>Connect Gemini</MenuMainButtonText>
            </MenuMainButton>
          </Tooltip>
        </MenuMainButtonWrapper>

        <MenuMainDecriptionWrapper>
          <MenuMainDecriptionText>
            Dont know how to connect your wallet? Follow these simple {''}
            <MenuMainDecriptionStepsText>steps</MenuMainDecriptionStepsText> to
            set up
          </MenuMainDecriptionText>
        </MenuMainDecriptionWrapper>
      </MenuMainWrapper>
    </MenuWrapper>
  );

  const { hasBackground } = useAppSelector(root => root.template);
  return (
    <HeaderWrapper hasBackground={hasBackground}>
      {playerInfo && playerInfo.status === PlayerStatus.UploadedProfile ? (
        <>
          <BigaCreditsWrapper>
            <BigaCreditsText>BIGA credits: </BigaCreditsText>
            <BigaCreditsValueText>
              {remainingBalance?.amount ? (
                <>
                  {parseFloat(
                    formatUnits(
                      remainingBalance.amount,
                      remainingBalance.tokenInfo?.decimals || 18,
                    ),
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  {' ($'}
                  {(
                    parseFloat(
                      formatUnits(
                        remainingBalance.amount,
                        remainingBalance.tokenInfo?.decimals || 18,
                      ),
                    ) / 10
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  {')'}
                </>
              ) : (
                0
              )}
            </BigaCreditsValueText>
          </BigaCreditsWrapper>
          <Line />
          <ConnectWalletWrapper>
            <LogoHeaderRedDot active={!!account} />
            {account ? (
              <CopyToClipboard
                onCopy={() => {
                  toast.success('COPY successfully');
                }}
                text={account}
              >
                <ConnectWalletText>{`${account.substring(
                  0,
                  6,
                )}...${account.substring(38)}`}</ConnectWalletText>
              </CopyToClipboard>
            ) : (
              <Dropdown overlay={menu} trigger={['click']}>
                <ConnectWalletText>
                  {account ? (
                    <span>{`${account.substring(0, 6)}...${account.substring(
                      38,
                    )}`}</span>
                  ) : (
                    'Connect wallet'
                  )}
                </ConnectWalletText>
              </Dropdown>
            )}
          </ConnectWalletWrapper>
          <LinkWalletModal
            refresh={refresh}
            onRefresh={() => {
              setRefresh(!refresh);
            }}
          />
        </>
      ) : (
        <ConnectButtonWrapper>
          <ConnectButtonText onClick={handleNavigaLogin}>
            Login
          </ConnectButtonText>
          <Line />
          <ConnectButtonText onClick={handleNavigaRegister}>
            Register
          </ConnectButtonText>
        </ConnectButtonWrapper>
      )}
    </HeaderWrapper>
  );
}

import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getDetailPoster } from 'services/api';

interface IPosterInfo {
  gameId: string;
  walletAddress: string;
  level: string;
  player: {
    walletAddress: string;
    username: string;
  };
  game: {
    name: string;
    tokenAddress: string;
    tokenSymbol: string;
    tokenName: string;
  };
  twitterShareUrl: string;
}

export const useGetPosterInfo = (id: string) => {
  const [data, setData] = useState<IPosterInfo>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    void (async () => {
      if (id) {
        setLoading(true);
        const res = await getDetailPoster(id);
        if (res?.success) {
          setData(res?.payload?.data);
        } else {
          toast.error(res?.message || 'Get poster failed');
        }
        setLoading(false);
      }
    })();
  }, [id]);
  return { data, loading };
};

import { useEffect, useState } from 'react';
import { getTopMetricsTopGames, ITimeRange } from 'services/api';

interface ITopMetricsTopGames {
  sortedByTotalEntryFee: IEntryFeeData[];
  sortedByTotalPlays: ITotalPlaysData[];
  supportedToken: supportedToken;
}

interface IEntryFeeData {
  totalEntryFee: string;
  totalPlays: number;
  gameId: string;
  name: string;
  scoringModel: string;
}

interface ITotalPlaysData {
  totalEntryFee: string;
  totalPlays: number;
  gameId: string;
  name: string;
  scoringModel: string;
}

interface supportedToken {
  name: string;
  symbol: string;
  address: string;
  decimals: number;
  tokenPrice: number;
}

export const useGetTopMetricsTopGames = (params: ITimeRange) => {
  const [topMetricsTopGames, setTopMetricsTopGames] =
    useState<ITopMetricsTopGames>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    void (async () => {
      setLoading(true);
      const res = await getTopMetricsTopGames(params);
      if (res?.success) {
        setTopMetricsTopGames(res?.payload?.data);
      }
      setLoading(false);
    })();
  }, [params]);
  return {
    topMetricsTopGames,
    loading,
  };
};

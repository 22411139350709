import styled from 'styled-components';

export const Text = styled.div`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
`;

export const CashierWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 56px 142px 155px;
`;

export const CashierMainWrapper = styled.div`
  width: 1038px;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    & {
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: 50px;
    }
  }
`;

export const CashierLeftContent = styled.div`
  width: 376px;

  gap: 24px;
  display: flex;
  flex-direction: column;
`;

export const LeftContentHeader = styled(Text)`
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: Changeling Neo;
`;

export const LeftContentBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LeftContentBodyItem = styled.div`
  gap: 16px;
  display: flex;
  padding: 16px 0px;
  align-items: flex-start;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  &:last-child {
    border-bottom: none;
  }
`;

export const LeftContentBodyItemNumberWrapper = styled.div`
  gap: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.08);
  background: rgba(255, 255, 255, 0.08);

  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
`;

export const LeftContentBodyItemContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const LeftContentBodyItemTitle = styled(Text)`
  font-size: 18px;
  font-weight: 600;
`;

export const LeftContentBodyItemContent = styled(Text)`
  width: 320px;

  font-size: 16px;
  font-weight: 400;
  opacity: 0.6;
`;

export const CashierRightContent = styled.div`
  width: 448px;

  gap: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  padding: 0px 32px 24px 32px;
`;

export const CashierRightContentFirstBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.08);
  }

  &:first-child::after {
    width: 100%;
  }
`;

export const FirstBlockButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
`;

export const FirstBlockButton = styled(Text)<{
  active?: boolean;
}>`
  padding: 20px 58.5px;
  cursor: pointer;
  user-select: none;
  text-align: center;
  font-size: 18px;
  font-weight: 600;

  color: ${props => (props.active ? '#0a3885' : '#000')};
  border-bottom: ${props => (props.active ? '2px solid #0a3885' : 'none')};
`;

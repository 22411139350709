export const CONVERT_2_NUMBER = 10 ** 18;
export const SECONDS_PER_DAY = 60 * 60 * 24;
export const PRICE_DECIMAL_PART = 10 ** 4;
export const NULL_ADDRESS = '0x0000000000000000000000000000000000000000';
export const MAX_UINT_256 =
  '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
export const dayTerms = [2592000, 5184000, 7776000];
export const ETH_ADDRESS = '0x0000000000000000000000000000000000000000';
export const ONE_DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;
export const ONE_HOUR_IN_MILLISECONDS = 1 * 60 * 60 * 1000;
export const ONE_WEEK_IN_MILLISECONDS = 7 * 24 * 60 * 60 * 1000;
export const ONE_MONTH_IN_MILLISECONDS = 30 * 24 * 60 * 60 * 1000;

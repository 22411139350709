import closeicon from 'assets/vectors/logo-profile-close.svg';
import { PitRouter } from 'constants/routers';
import { useNavigate } from 'react-router-dom';
import { ApplicationModal } from 'redux/slices/application';
import { useModalIsOpen, useToggleModal } from 'redux/slices/application/hook';

import {
  BodyAccountWrapper,
  BodyButtonWrapper,
  BodyText,
  BodyTextWrapper,
  ButtonText,
  ButtonWrapper,
  CloseIcon,
  CloseWrapper,
  HeaderFirstText,
  HeaderSecondText,
  HeaderTextWrapper,
  Line,
  ModalLevelMasterBody,
  ModalLevelMasterContainer,
  ModalLevelMasterHeader,
  ModalLevelMasterWrapper,
  ModalWrapper,
} from './styled';

export const Leaderboard = ({
  shareTwitterURL,
}: {
  shareTwitterURL?: string;
}) => {
  const open = useModalIsOpen(ApplicationModal.PLAYGAME_LEADERBOARD);
  const toggleModal = useToggleModal(ApplicationModal.PLAYGAME_LEADERBOARD);
  const handleCancel = () => {
    toggleModal();
  };

  const navigate = useNavigate();

  return (
    <ModalWrapper
      visible={open}
      onCancel={handleCancel}
      maskClosable={true}
      footer={null}
      closable={false}
      width="448px"
    >
      <ModalLevelMasterWrapper>
        <CloseWrapper>
          <CloseIcon src={closeicon} onClick={handleCancel} />
        </CloseWrapper>
        <ModalLevelMasterContainer>
          <ModalLevelMasterHeader>
            <HeaderTextWrapper>
              <HeaderFirstText>You are on the Leaderboard</HeaderFirstText>
              <HeaderSecondText>Congratulations!</HeaderSecondText>
            </HeaderTextWrapper>
          </ModalLevelMasterHeader>

          <ModalLevelMasterBody>
            <BodyTextWrapper>
              <BodyText>
                With the arcade activated, earnings will flow until you&apos;re
                dethroned. Play more, share your status, and invite challenges.
              </BodyText>
            </BodyTextWrapper>

            <BodyButtonWrapper>
              <ButtonWrapper>
                <ButtonText onClick={handleCancel}>Continue</ButtonText>
                <Line />
                <ButtonText onClick={() => navigate(PitRouter.GAME_LIST)}>
                  Back to Arcade
                </ButtonText>
                <Line />
                <a href={shareTwitterURL} target="_blank" rel="noreferrer">
                  <ButtonText>Share</ButtonText>
                </a>
              </ButtonWrapper>
            </BodyButtonWrapper>

            <BodyAccountWrapper onClick={() => navigate(PitRouter.PROFILE)}>
              My Account
            </BodyAccountWrapper>
          </ModalLevelMasterBody>
        </ModalLevelMasterContainer>
      </ModalLevelMasterWrapper>
    </ModalWrapper>
  );
};

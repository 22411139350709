import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { ping } from 'services/api';

import { MaintenancePage } from '../pages/Maintenance';

const MaintenanceRoute = () => {
  const [isMaintenance, setIsMaintenance] = useState(false);

  useEffect(() => {
    ping()
      .then(res => {
        if (!res.success) setIsMaintenance(true);
      })
      .catch(() => {
        setIsMaintenance(true);
      });
  }, []);

  return isMaintenance ? <MaintenancePage /> : <Outlet />;
};

export default MaintenanceRoute;

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import bigaLogo from 'assets/vectors/logo-biga-arcade.svg';
import { RegisterCongratulations } from 'components/Register/Congratulation';
import { FirstStep } from 'components/Register/FirstStep';
import { RegisterForm } from 'components/Register/RegisterForm';
import { RegisterSetupProfile } from 'components/Register/SetUpProfile';
import { RegisterVerifyCode } from 'components/Register/VerifyCode';
import { PlayerStatus } from 'constants/enum/PlayerStatus';
import { RegisterStep } from 'constants/enum/RegisterStep';
import { TokenType } from 'constants/enum/TokenType';
import { PitRouter } from 'constants/routers';
import { useGetPlayer } from 'hooks/player/useGetPlayer';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useLogOut } from 'hooks/useLogOut';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { loginUser } from 'redux/slices/player';
import { loginOAuth, oneTimeTokenForMiniApp } from 'services/api';
import { LocalhostStorage } from 'utils/sessionStorage';

import { LoadingPage, Logo, RegisterWrapper, StepWrapper } from './styled';

export const SignUpAccount = ({
  defaultStep,
}: {
  defaultStep: RegisterStep;
}) => {
  const [currentStep, setCurrentStep] = useState<RegisterStep>(defaultStep);
  const [refreshCountDown, setRefreshCountDown] = useState<boolean>(false);
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  let bot: boolean | undefined;
  let telegramId: number | undefined;
  const botParam: string = searchParams.get('bot');
  const telegramIdParam: string = searchParams.get('telegramId');
  if (botParam) {
    bot = botParam === 'true';
  }
  if (telegramIdParam) {
    telegramId = parseInt(telegramIdParam);
  }

  const { pathname } = useLocation();
  const { account } = useActiveWeb3React();
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    LocalhostStorage.set('path', pathname);
  }, [pathname]);
  const navigate = useNavigate();
  const [params] = useState({
    walletAddress: account,
  });
  const queryParameters = new URLSearchParams(window.location.search);
  const code = queryParameters.get('code');
  const state = queryParameters.get('state');

  useEffect(() => {
    void (async () => {
      if (code && state) {
        const responseData = await loginOAuth({
          code,
          state,
          telegramId: telegramId,
        });
        if (responseData?.success) {
          LocalhostStorage.set(
            'accessToken',
            responseData?.payload?.data?.accessToken,
          );
          LocalhostStorage.set(
            'refreshToken',
            responseData?.payload?.data?.refreshToken,
          );
          LocalhostStorage.set('tokenType', TokenType.CARV);

          dispatch(loginUser(responseData?.payload?.data));

          if (
            responseData?.payload?.data?.status === PlayerStatus.UploadedProfile
          ) {
            navigate(PitRouter.GAME_LIST);
          }
          if (
            responseData?.payload?.data?.status === PlayerStatus.VerifiedOTP
          ) {
            setCurrentStep(RegisterStep.setupProfile);
          }
        } else {
          toast.error(responseData?.message || 'Login failed');
        }
      }
    })();
  }, [code, navigate, state]);

  const { playerInfo, loading } = useGetPlayer(params, refresh);

  useLogOut({
    onRefresh: () => {
      setRefresh(!refresh);
    },
  });

  useEffect(() => {
    void (async () => {
      if (!code || !state) {
        // if (!playerInfo) {
        //   setCurrentStep(RegisterStep.welcome);
        // }

        if (playerInfo?.status === PlayerStatus.WaitToVerifyOTP) {
          setCurrentStep(RegisterStep.verifyCode);
        }
        if (playerInfo?.status === PlayerStatus.VerifiedOTP) {
          setCurrentStep(RegisterStep.setupProfile);
        }
        if (playerInfo?.status === PlayerStatus.UploadedProfile) {
          // setCurrentStep(RegisterStep.congratulation);
          navigate(PitRouter.GAME_LIST);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerInfo]);
  const [emailAddress, setEmailAddress] = useState<string>('');

  const stepElement = useMemo(() => {
    switch (currentStep) {
      case RegisterStep.welcome:
        return (
          <FirstStep
            onSignUp={() => {
              setCurrentStep(RegisterStep.enterDetail);
            }}
          />
        );
      case RegisterStep.enterDetail:
        return (
          <RegisterForm
            onBack={() => {
              setCurrentStep(RegisterStep.welcome);
            }}
            onContinue={() => {
              // setRefresh(!refresh);
              setCurrentStep(RegisterStep.verifyCode);
            }}
            setWaitToVerifyEmailAddress={setEmailAddress}
          />
        );
      case RegisterStep.verifyCode:
        return (
          <RegisterVerifyCode
            onBack={() => {
              setCurrentStep(RegisterStep.enterDetail);
            }}
            refreshCountDown={refreshCountDown}
            onResend={() => {
              setRefreshCountDown(!refreshCountDown);
            }}
            onContinue={() => {
              setRefresh(!refresh);
              setCurrentStep(RegisterStep.setupProfile);
            }}
            waitToVerifyEmailAddress={emailAddress}
          />
        );
      case RegisterStep.setupProfile:
        return (
          <RegisterSetupProfile
            onContinue={async () => {
              if (bot) {
                try {
                  const { expiresIn, token } = await oneTimeTokenForMiniApp({
                    telegramId: telegramId,
                  });

                  window.location.replace(
                    'tg://resolve?domain=BigArcadeBot/games&startapp=' + token,
                  );
                } catch (e) {
                  toast.error(e?.message || 'Get one time token failed');
                }
                return;
              }
              setCurrentStep(RegisterStep.congratulation);
            }}
          />
        );
      case RegisterStep.congratulation:
        return <RegisterCongratulations />;

      default:
        break;
    }
  }, [currentStep, refreshCountDown, refresh, emailAddress]);

  return (
    <RegisterWrapper>
      <Logo
        src={bigaLogo}
        alt="logo"
        onClick={() => {
          navigate(PitRouter.HOME);
        }}
      />
      {loading && (
        <LoadingPage>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        </LoadingPage>
      )}
      <StepWrapper>{stepElement}</StepWrapper>
    </RegisterWrapper>
  );
};

import { Input } from 'antd';
import styled from 'styled-components';

export const Text = styled.div`
  font-style: normal;
  line-height: normal;
  font-family: Neue Haas Grotesk Display Pro;
`;

export const GameListContainer = styled.div`
  text-align: center;
  max-width: 900px;
  margin: auto;
  margin-top: 5rem;

  .ant-tabs-nav:before {
    border-bottom: none;
  }

  .ant-tabs-card > .ant-tabs-nav {
    border-top: #fff 1px solid;
    padding-top: 15px;
    padding-bottom: 25px;
    width: 900px;
    margin: 0 auto;
    .ant-tabs-tab {
      border-radius: 6.5px;
      background-color: rgb(255 255 255 / 46%);
      .ant-tabs-tab-btn {
        color: #0b3885;
        font-size: 17px;
        font-weight: 600;
        line-height: 20px;
      }
      &.ant-tabs-tab-active {
        background-color: #fff;
      }
    }
  }
`;

export const InputSearchWrapper = styled(Input)`
  color: #0b3885;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  width: 200px;
  border-radius: 6.5px;
  padding: 8px 20px;

  .ant-input-affix-wrapper,
  .ant-input {
    border: none;
    outline: none;
    box-shadow: none;
    background-color: transparent;

    padding: 0;
    color: #0b3885;
    font-size: 17px;
    text-align: center;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    font-family: Neue Haas Grotesk Display Pro;

    &:focus,
    &:hover,
    &.ant-input-affix-wrapper:focus,
    &.ant-input-affix-wrapper:hover {
      border: none;
      outline: none;
      box-shadow: none;
    }
    &::placeholder {
      color: #000;
      opacity: 0.24;
    }
    &:-ms-input-placeholder {
      color: #000;
      opacity: 0.24;
    }
    &::-ms-input-placeholder {
      color: #000;
      opacity: 0.24;
    }
    .anticon.anticon-close-circle {
      color: #000;
      opacity: 0.24;
    }
  }
`;

export const GeneralButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0;
  @media (max-width: 1280px) {
    gap: 10px;
  }
`;

export const ButtonTabsWrapper = styled.div`
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6.5px;
  background: rgba(255, 255, 255, 0.08);
`;

export const ButtonWrapper = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
  border-radius: 6.5px;
  background: ${props => (props?.active ? '#fff' : 'none')};
  color: ${props => (props?.active ? '#0A3885' : '#fff')};
`;

export const ButtonText = styled(Text)`
  padding: 12px 0;
  cursor: pointer;
  user-select: none;
  width: 100%;

  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
`;

import { Button } from 'antd';
import styled from 'styled-components';

export const Text = styled.div`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
`;

export const FirstBlock = styled.div`
  gap: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const FirstBlockTitle = styled(Text)`
  color: #000;
  font-size: 16px;
  font-weight: 500;
`;

export const FirstBlockValue = styled(Text)`
  color: var(--brand-primary, #0a3885);
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
`;

export const SecondBlock = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #f6f6f9;
  width: 100%;
`;

export const SecondBlockItemWrapper = styled.div`
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
`;

export const SecondBlockItem = styled.div`
  display: flex;
  padding: 12px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const SecondBlockItemTitle = styled(Text)`
  color: #000;
  font-size: 16px;
  font-weight: 500;
`;

export const SecondBlockItemValue = styled(Text)`
  color: #000;
  font-size: 16px;
  font-weight: 500;
  text-align: right;
`;

export const SecondBlockTotalWrapper = styled.div`
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
`;

export const SecondBlockTotalTitle = styled(Text)`
  color: #000;
  font-size: 16px;
  font-weight: 500;
`;

export const SecondBlockTotalValue = styled(Text)`
  color: #000;
  font-size: 16px;
  font-weight: 700;
  text-align: right;
`;

export const ThirdBlock = styled.div`
  gap: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const ThirdBlockButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 16px;
`;

export const ThirdBlockButtonBackWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.08);
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;

export const ThirdBlockButtonBack = styled(Text)`
  padding: 16px 32px;

  color: #000;
  font-size: 18px;
  font-weight: 500;
`;

export const ThirdBlockButtonConfirmWrapper = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  outline: 0;
  padding: 0;
  width: 100%;
  border: none;
  height: auto;
  background: #0a3885;

  color: #fff;
  font-weight: 600;
  font-size: 18px;

  &.ant-btn:active:not(:disabled),
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    background: var(--biga-blue);
    outline: 0;
  }
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    color: #fff;
    border: none;
    background: var(--biga-blue);
    outline: 0;
  }
`;

export const ThirdBlockButtonGeminiWrapper = styled.div<{ disabled?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 1px solid rgba(11, 56, 133, 0.24);
  user-select: none;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? '0.4' : '1')};
  padding: 16px 0;
`;

export const ThirdBlockButtonGemini = styled(Text)`
  color: #1d1d1d;
  font-size: 18px;
  font-weight: 500;
`;

export const ThirdBlockSelectTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

export const Line = styled.div`
  flex: 1;
  height: 1px;
  background: rgba(0, 0, 0, 0.16);
`;

export const Title = styled(Text)`
  color: #000;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
`;

export const ThirdBlockButtonPayWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  /* align-self: stretch; */
  width: 100%;

  & > .ant-tooltip-disabled-compatible-wrapper {
    flex: 1;
  }
`;

export const ThirdBlockButtonPay = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  outline: 0;
  width: 100%;
  border: 1px solid rgba(11, 56, 133, 0.24);
  height: auto;
  background: #fff;

  color: #fff;
  font-weight: 600;
  font-size: 18px;

  padding: 16px 0;

  &.ant-btn:active:not(:disabled),
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    background: #fff;
    outline: 0;
    border: 1px solid rgba(11, 56, 133, 0.24);
  }
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    color: #fff;
    border: 1px solid rgba(11, 56, 133, 0.24);
    background: #fff;
    outline: 0;
  }
  &.ant-btn[disabled],
  &.ant-btn[disabled]:active,
  &.ant-btn[disabled]:focus,
  &.ant-btn[disabled]:hover {
    opacity: 0.4;
  }
`;

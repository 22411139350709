import { useEffect, useState } from 'react';
import { getRemainingBalance } from 'services/api';

export interface IRemainingBalance {
  tokenAddress?: string;
  amount?: string;
  usedAmount?: string;
  lockedAmount?: string;
  tokenInfo: {
    address: string;
    name: string;
    symbol: string;
    decimals: number;
  };
}

export const useGetRemainingBalance = (
  tokenAddress: string,
  refresh?: boolean,
) => {
  const [remainingBalance, setRemainingBalance] = useState<IRemainingBalance>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    void (async () => {
      if (tokenAddress) {
        setLoading(true);
        const res = await getRemainingBalance({ tokenAddress });
        if (res?.success) {
          setRemainingBalance(res?.payload?.data);
        }
        setLoading(false);
      }
    })();
  }, [tokenAddress, refresh]);
  return { remainingBalance, loading };
};

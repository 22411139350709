import starBottomImg from 'assets/vectors/poster-stars-bottom.svg';
import starTopImg from 'assets/vectors/poster-stars-top.svg';
import { useGetPosterInfo } from 'hooks/useGetPosterInfo';
import { useLocation } from 'react-router-dom';
import HomepageTemplate from 'template/Homepage';

import {
  FailedText,
  PosterTitle,
  PosterWrapper,
  ShareTwitterButton,
  SubTitleBox,
  TextStyled,
} from './styled';

export const PosterPage = () => {
  const location = useLocation();

  const gameSession = new URLSearchParams(location.search).get('sessionId');
  const { data } = useGetPosterInfo(gameSession);
  return (
    <HomepageTemplate theme={'white'}>
      <PosterWrapper>
        {data ? (
          <>
            <PosterTitle>
              <img
                src={starTopImg}
                alt="stars-1"
                className="poster-title-stars-top-left"
              />
              <h1 className="poster-title">Level {data?.level} Completed</h1>
              <img
                src={starBottomImg}
                alt="stars-1"
                className="poster-title-stars-bottom-right"
              />
            </PosterTitle>
            <SubTitleBox>
              <TextStyled>
                Congratulations, {data?.player?.username}! You&apos;ve completed
                level and become the owner.
              </TextStyled>

              <ShareTwitterButton
                type="button"
                target="_blank"
                href={data?.twitterShareUrl}
              >
                <div>Share your progress on Twitter</div>
              </ShareTwitterButton>
            </SubTitleBox>
          </>
        ) : (
          <FailedText>Something went wrong</FailedText>
        )}
      </PosterWrapper>
    </HomepageTemplate>
  );
};

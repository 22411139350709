import walletIcon from 'assets/vectors/logo-cashier-wallet.svg';

import { WalletNotConnectedText, WalletNotConnectedWrapper } from './styled';

export const WalletNotConnected = () => {
  return (
    <WalletNotConnectedWrapper>
      <img src={walletIcon} />
      <WalletNotConnectedText>Wallet not connected</WalletNotConnectedText>
    </WalletNotConnectedWrapper>
  );
};

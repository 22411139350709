import styled from 'styled-components';

export const Text = styled.div`
  font-style: normal;
  line-height: normal;
  font-family: Neue Haas Grotesk Display Pro;
`;

export const AddText = styled(Text)`
  color: #000;
  font-size: 14px;
  font-weight: 500;
`;

export const WarningStyled = styled.p`
  font-size: 12px;
  color: red;
  margin-top: 10px;
  margin-bottom: 0;
`;

export const InfoStyled = styled.p`
  font-size: 16px;
  color: #0b3885;
  margin-top: 10px;
  margin-bottom: 0;
  font-weight: 700;
`;

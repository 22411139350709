import { ArrowLeftOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { ApplicationModal } from 'redux/slices/application';
import { useModalIsOpen, useToggleModal } from 'redux/slices/application/hook';
import { setLevelFee } from 'services/api';
import { blockInvalidChar } from 'utils/formatNumber';

import {
  BackButton,
  HeaderContainer,
  HeaderTitle,
} from '../ConfigLevelFee/styled';
import { SubmitButton, TextInputField, ThemedModal, Wrapper } from '../styled';
import { LabelStyled } from './styled';

export interface IWithdrawProps {
  gameId?: string;
  tokenAddress?: string;
  tokenSymbol?: string;
  decimals?: number;
  defaultEntryFee?: string;
  onRefresh: () => void;
}

export const LevelFeeFormModal = (props: IWithdrawProps) => {
  const isOpen = useModalIsOpen(ApplicationModal.SET_LEVEL_FEE_FORM);
  const configLevelFeeToggle = useToggleModal(
    ApplicationModal.CONFIG_LEVEL_FEE,
  );
  const setLevelFeeToggle = useToggleModal(ApplicationModal.SET_LEVEL_FEE_FORM);
  const [loading, setLoading] = useState<boolean>(false);

  const [amount, setAmount] = useState<string>();
  const [level, setLevel] = useState<number>();
  const [levelName, setLevelName] = useState<string>();

  const [confirmVisible, setConfirmVisible] = useState<boolean>(false);

  const handleAmountChange = value => {
    setAmount(
      value && value.indexOf('.') >= 0
        ? value.slice(0, value.indexOf('.') + Number(props?.decimals || 18) + 1)
        : value,
    );
  };

  const handleLevelChange = e => {
    setLevel(e ? Math.floor(e) || level : e);
  };

  const handleSubmit = async () => {
    setConfirmVisible(true);
  };

  const handleConfirmOk = async () => {
    setConfirmVisible(false);
    setLoading(true);

    const response = await setLevelFee({
      gameId: props.gameId,
      levels: [level.toString()],
      levelNames: [levelName || ''],
      entryFees: [parseUnits(amount, props?.decimals).toString()],
    });
    if (response?.success) {
      toast.success('You have successfully set level!');
      setAmount(undefined);
      setLevel(undefined);
      setLevelName(undefined);

      configLevelFeeToggle();
      props.onRefresh();
    } else toast.error(response?.message || 'Set level failed!!');

    setLoading(false);
  };

  const ableToSubmit = useMemo(() => {
    return Number(amount) >= 0 && level && amount;
  }, [amount, level]);

  return (
    <>
      <ThemedModal
        title={
          <HeaderContainer>
            <BackButton
              onClick={() => {
                configLevelFeeToggle();
                setAmount(undefined);
                setLevel(undefined);
                setLevelName(undefined);
              }}
            >
              <ArrowLeftOutlined />
            </BackButton>
            <HeaderTitle>Set level</HeaderTitle>
          </HeaderContainer>
        }
        open={isOpen}
        onCancel={() => {
          setLevelFeeToggle();
          setAmount(undefined);
          setLevel(undefined);
          setLevelName(undefined);
        }}
        footer={false}
        width={550}
      >
        <Row gutter={[8, 8]} align={'middle'}>
          <Col sm={4}>
            <LabelStyled>Level:</LabelStyled>
          </Col>
          <Col sm={20}>
            <TextInputField
              type="number"
              min={1}
              step={1}
              value={level}
              onChange={e => handleLevelChange(e.target.value)}
              placeholder="Enter level"
              allowClear
              onKeyDown={blockInvalidChar}
            />
          </Col>
          <Col sm={4}>
            <LabelStyled>Name:</LabelStyled>
          </Col>
          <Col sm={20}>
            <TextInputField
              type="string"
              value={levelName}
              onChange={e => setLevelName(e.target.value)}
              placeholder="Enter level name"
              allowClear
            />
          </Col>
          <Col sm={4}>
            <LabelStyled>Amount:</LabelStyled>
          </Col>
          <Col sm={20}>
            <TextInputField
              type="number"
              min={0}
              step={0.01}
              value={amount}
              onChange={e => handleAmountChange(e.target.value)}
              placeholder="Enter amount"
              allowClear
              onKeyDown={blockInvalidChar}
            />
          </Col>
        </Row>
        <div style={{ textAlign: 'right', padding: '15px 0px' }}>
          Default entry fee:{' '}
          {formatUnits(props?.defaultEntryFee || 0, props?.decimals || 18)}{' '}
          {props?.tokenSymbol}
        </div>
        <Wrapper>
          <SubmitButton
            height="40px"
            width="150px"
            disabled={!ableToSubmit}
            loading={loading}
            onClick={() => handleSubmit()}
          >
            Submit
          </SubmitButton>
        </Wrapper>
      </ThemedModal>
      <ThemedModal
        visible={confirmVisible}
        onOk={handleConfirmOk}
        onCancel={() => setConfirmVisible(false)}
        okText="Yes"
        cancelText="No"
        width={400}
        style={{ marginTop: '50px' }}
      >
        <p>Do you want to set fee for this level?</p>
      </ThemedModal>
    </>
  );
};

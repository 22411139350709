import { GameScoringModelType } from 'constants/enum/GameScoringModelType';
import { useEffect, useState } from 'react';
import { getListLevel } from 'services/api';

export interface IGameStatic {
  gameId: string;
  playsAmount: number;
  totalOfSpentAmount: string;
  totalOfRevenue: string;
  totalOfUSDRevenue: string;
  playerAmount: number;
  minLevel?: number;
  maxLevel?: number;
}

export const useGetListLevel = (
  gameId: string,
  scoringModel: GameScoringModelType = null,
) => {
  const [listLevel, setListLevel] = useState<string[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    void (async () => {
      if (gameId && scoringModel == GameScoringModelType.score) {
        setLoading(true);
        const res = await getListLevel(gameId);
        if (res?.success) {
          setListLevel(res?.payload?.data);
        }
        setLoading(false);
      }
    })();
  }, [gameId, scoringModel]);
  return { listLevel, loading };
};

import { Spin } from 'antd';
import { GaActions, GaCategories, GaLabels } from 'constants/enum/GaEvent';
import React, { useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { toast } from 'react-toastify';
import { createDesktopSubscription, resendOtp, verifyOtp } from 'services/api';
import { LocalhostStorage } from 'utils/sessionStorage';

import {
  BackText,
  ButtonWrapper,
  CenterTextError,
  Line,
  RegisterStepBlueLink,
  RegisterStepDescription,
  RegisterStepTitle,
  RegisterVerifyCodeBodyWrapper,
  RegisterVerifyCodeInput,
  RegisterVerifyCodeInputWrapper,
  RegisterVerifyCodeStepHeadingWrapper,
  RegisterVerifyCodeStepTimer,
  RegisterVerifyCodeWrapper,
  TextErrorWrapper,
} from './styled';

const RE_DIGIT = new RegExp(/^\d+$/);

interface IProps {
  //   value: string;
  //   OTP_LENGTH: number;
  //   onChange: (value: string) => void;
  refreshCountDown: boolean;
  onBack: () => void;
  onResend: () => void;
  onContinue: () => void;
  waitToVerifyEmailAddress: string;
}

const OTP_LENGTH = 6;
// 2 minutes
const OTP_EXPIRED_DURATION = 1 * 60;

export const RegisterVerifyCode = ({
  onBack,
  onResend,
  onContinue,
  waitToVerifyEmailAddress,
}: IProps) => {
  const [countDownTime, setCountDownTime] = useState<number>(
    OTP_EXPIRED_DURATION * 1000 + Date.now(),
  );
  const [countDown, setCountDown] = useState<number>(
    countDownTime - Date.now(),
  );

  useEffect(() => {
    const timerInternal = setInterval(() => {
      if (countDownTime > Date.now()) {
        setCountDown(countDownTime - Date.now());
      } else {
        setCountDown(0);
        clearInterval(timerInternal);
      }
    }, 1000);

    return () => {
      clearInterval(timerInternal);
    };
  }, [countDownTime]);

  const minutes = useMemo(
    () => Math.floor(countDown / 60 / 1000).toString(),
    [countDown],
  );
  const seconds = useMemo(
    () =>
      Math.ceil((countDown / 1000) % 60)
        .toString()
        .padStart(2, '0'),
    [countDown],
  );

  const [value, setValue] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const valueItems = useMemo(() => {
    const valueArray = value.split('');
    const items: Array<string> = [];

    for (let i = 0; i < OTP_LENGTH; i++) {
      const char = valueArray[i];

      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push('');
      }
    }

    return items;
  }, [value]);

  const focusToNextInput = (target: HTMLElement) => {
    const nextElementSibling =
      target.nextElementSibling as HTMLInputElement | null;

    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };
  const focusToPrevInput = (target: HTMLElement) => {
    const previousElementSibling =
      target.previousElementSibling as HTMLInputElement | null;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };

  const inputOnChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number,
  ) => {
    setErrorMsg('');
    const target = e.target;
    let targetValue = target.value.trim();
    const isTargetValueDigit = RE_DIGIT.test(targetValue);

    if (!isTargetValueDigit && targetValue !== '') {
      return;
    }

    const nextInputEl = target.nextElementSibling as HTMLInputElement | null;

    // only delete digit if next input element has no value
    if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== '') {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : ' ';

    const targetValueLength = targetValue.length;

    if (targetValueLength === 1) {
      const newValue =
        value.substring(0, idx) + targetValue + value.substring(idx + 1);

      setValue(newValue);
      if (newValue.length === OTP_LENGTH) {
        setLoading(true);
        const res = await verifyOtp(waitToVerifyEmailAddress, newValue);
        if (res?.success) {
          LocalhostStorage.set('accessToken', res?.payload?.data?.accessToken);
          ReactGA.event({
            category: GaCategories.GENERAL,
            action: GaActions.ACTION_SIGNUP_VERIFY_CODE,
            label: GaLabels.LABEL_SIGNUP_VERIFY_CODE,
          });
          onContinue();
        } else {
          setValue('');
          setErrorMsg(res?.message || 'Invalid OTP. Please try again.');
        }
        setLoading(false);
      }

      if (!isTargetValueDigit) {
        return;
      }

      focusToNextInput(target);
    } else if (targetValueLength === OTP_LENGTH) {
      setValue(targetValue);
      if (targetValue.length === OTP_LENGTH) {
        setLoading(true);
        const res = await verifyOtp(waitToVerifyEmailAddress, targetValue);
        if (res?.success) {
          LocalhostStorage.set('accessToken', res?.payload?.data?.accessToken);
          ReactGA.event({
            category: GaCategories.GENERAL,
            action: GaActions.ACTION_SIGNUP_VERIFY_CODE,
            label: GaLabels.LABEL_SIGNUP_VERIFY_CODE,
          });
          onContinue();
        } else {
          setValue('');
          setErrorMsg(res?.message || 'Invalid OTP. Please try again.');
        }
        setLoading(false);
      }

      target.blur();
    }
  };

  const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    const target = e.target as HTMLInputElement;

    if (key === 'ArrowRight' || key === 'ArrowDown') {
      e.preventDefault();
      return focusToNextInput(target);
    }

    if (key === 'ArrowLeft' || key === 'ArrowUp') {
      e.preventDefault();
      return focusToPrevInput(target);
    }

    const targetValue = target.value;

    // keep the selection range position
    // if the same digit was typed
    target.setSelectionRange(0, targetValue.length);

    if (e.key !== 'Backspace' || targetValue !== '') {
      return;
    }

    focusToPrevInput(target);
  };
  const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { target } = e;

    // keep focusing back until previous input
    // element has value
    const prevInputEl =
      target.previousElementSibling as HTMLInputElement | null;

    if (prevInputEl && prevInputEl.value === '') {
      return prevInputEl.focus();
    }

    target.setSelectionRange(0, target.value.length);
  };

  const handleResendClick = async () => {
    const res = await resendOtp(waitToVerifyEmailAddress);

    if (res) {
      setValue('');
      setErrorMsg('');
      setCountDownTime(OTP_EXPIRED_DURATION * 1000 + Date.now());
      onResend();
      toast.success(res?.message || 'OTP sent successfully!');
    } else {
      toast.error(res?.message || 'Failed to resend OTP. Please try again.');
    }
  };

  const errorText = 'Your code is incorrect, please try again.';
  const backText =
    'If you still have trouble, please go back \n and start again or use a different email address.';

  return (
    <RegisterVerifyCodeWrapper>
      <RegisterVerifyCodeStepHeadingWrapper>
        <RegisterStepTitle>Enter code</RegisterStepTitle>
        <RegisterStepDescription>
          Please check your email, we’ve sent you a 6 digit code
        </RegisterStepDescription>
      </RegisterVerifyCodeStepHeadingWrapper>

      <Spin spinning={loading}>
        <RegisterVerifyCodeBodyWrapper>
          <RegisterVerifyCodeInputWrapper>
            {valueItems.map((digit, idx) => (
              <RegisterVerifyCodeInput
                key={idx}
                type="text"
                inputMode="numeric"
                autoComplete="one-time-code"
                pattern="\d{1}"
                maxLength={OTP_LENGTH}
                className="otp-input"
                value={digit}
                onChange={e => inputOnChange(e, idx)}
                onKeyDown={inputOnKeyDown}
                onFocus={inputOnFocus}
                autoFocus={idx === 0}
              />
            ))}
          </RegisterVerifyCodeInputWrapper>

          {!!errorMsg && (
            <TextErrorWrapper>
              <CenterTextError>{errorText}</CenterTextError>
              <BackText>{backText}</BackText>
            </TextErrorWrapper>
          )}

          <ButtonWrapper>
            <div className="button-link-wrapper">
              <RegisterStepBlueLink onClick={onBack}>
                Go back
              </RegisterStepBlueLink>
            </div>

            <Line />

            <div className="button-link-wrapper">
              {Number(minutes) || Number(seconds) ? (
                <RegisterVerifyCodeStepTimer>
                  {minutes}:{seconds}
                </RegisterVerifyCodeStepTimer>
              ) : (
                <RegisterStepBlueLink onClick={handleResendClick}>
                  Resend code
                </RegisterStepBlueLink>
              )}
            </div>
          </ButtonWrapper>
        </RegisterVerifyCodeBodyWrapper>
      </Spin>
    </RegisterVerifyCodeWrapper>
  );
};

import { useEffect, useState } from 'react';
import { getTopEarners, ITime } from 'services/api';

export interface ITopEarners {
  accumulatedRevenue: number;
  avatarURL?: string;
  username: string;
  walletAddress?: string;
}

export const useGetTopEarners = (params: ITime, refresh?: boolean) => {
  const [topEarners, setTopEarners] = useState<ITopEarners[]>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    void (async () => {
      setLoading(true);
      const res = await getTopEarners(params);
      if (res?.success) {
        setTopEarners(res?.payload?.data);
      }
      setLoading(false);
    })();
  }, [params, refresh]);
  return { topEarners, loading };
};

import { Button } from 'antd';
import styled from 'styled-components';

export const Description = styled.div`
  text-align: center;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* color: #d1d1d1; */
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
`;

export const LinkButton = styled(Button)<{ height?: string; width?: string }>`
  color: #000;
  border: 1px solid #d1d1d1;
  border-radius: 6.5px;
  font-size: 17px;
  font-weight: 600;
  height: ${props => (props.height ? props.height : '50px')};
  width: ${props => (props.width ? props.width : '250px')};
  background-color: #fff;
  margin-top: 20px;

  :hover,
  :focus,
  :active {
    color: #000;
    background-color: #fff;
    transform: scale(1.05);
    border: 1px solid #d1d1d1;
  }

  :active {
    transform: scale(0.9);
  }

  ::after {
    display: none;
  }

  :disabled,
  :disabled:hover {
    color: #000;
    background-color: #fff;
    border: 1px solid #d1d1d1;
    opacity: 0.6;
  }
`;

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Col, Form, Radio, Row, Tooltip } from 'antd';
import defaultAvatarImg from 'assets/images/default-user-profile.png';
import { parseUnits } from 'ethers/lib/utils';
import { ISupportedToken } from 'hooks/supported-token/useGetListSupportedTokenForScroll';
import { UploadFileDTO } from 'interfaces/dto/UploadFileDTO';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { ApplicationModal } from 'redux/slices/application';
import { useModalIsOpen, useToggleModal } from 'redux/slices/application/hook';
import { registerGame } from 'services/api';
import { truncateText } from 'utils/helper';

import {
  CheckBoxStyled,
  CheckBoxText,
  CheckBoxWrapper,
  DividerStyled,
  GroupTitleStyled,
  AutoHeightInputWrapper as InputWrapper,
  LabelStyled,
  RadioGroupStyled,
  TextField,
} from '../IntegrateGame/styled';
import { StyledForm, SubmitButton, ThemedModal, Wrapper } from '../styled';
import {
  AvatarImg,
  BackButton,
  BackgroundGameImage,
  FormItemInput,
  FormItemWrapper,
  HeaderContainer,
  HeaderTitle,
  UploadFileZipWrapper,
} from './styled';

export interface IntegrateInfo {
  name: string;
  description: string;
  slug: string;
  activeVersion: string;
  backgroundImg: UploadFileDTO;
  buildFile: UploadFileDTO;
  tags: string[];
  token: ISupportedToken;
  provider?: {
    username: string;
    avatarURL?: string;
  };
  treasury?: {
    username: string;
    avatarURL?: string;
  };
  isExternal: boolean;
  isStoshi: boolean;
  isTestingGame: boolean;
  defaultEntryFee: string;
  providerFeeRate?: number;
  levelOwnerFeeRate: number;
  affiliateFeeRate: number;
  platformFeeRate: number;
  treasuryFeeRate?: number;
  isPlatformAdmin?: boolean;
  isInWhitelist?: boolean;
  scoringModel: string;
  minLevel: number;
  maxLevel: number;
}

export const ReviewIntegrate = (
  props: IntegrateInfo & { onRefresh: () => void },
) => {
  const [loading, setLoading] = useState<boolean>(false);

  const isOpen = useModalIsOpen(ApplicationModal.REVIEW_INTEGRATE);
  const reviewIntegrateToggle = useToggleModal(
    ApplicationModal.REVIEW_INTEGRATE,
  );
  const integrateToggle = useToggleModal(ApplicationModal.REGISTER_GAME);
  const [form] = Form.useForm();

  const handleInternalGame = async () => {
    const {
      backgroundImg,
      buildFile,
      defaultEntryFee,
      description,
      isExternal,
      isStoshi,
      isTestingGame,
      levelOwnerFeeRate,
      name,
      platformFeeRate,
      slug,
      activeVersion,
      tags,
      token,
      provider,
      providerFeeRate,
      affiliateFeeRate,
      treasury,
      treasuryFeeRate,
      scoringModel,
      minLevel,
      maxLevel,
    } = props;

    setLoading(true);

    const formData = new FormData();

    formData.append('name', name);
    formData.append('slug', slug);
    formData.append('activeVersion', activeVersion);
    formData.append('description', description);
    formData.append('tags', tags as any);
    formData.append('buildFile', buildFile);
    formData.append('backgroundImg', backgroundImg);
    formData.append('isExternal', isExternal as any);
    formData.append('isStoshi', isStoshi as any);
    formData.append('isTestingGame', isTestingGame as any);
    formData.append('tokenAddress', token.address);
    if (treasury?.username) {
      formData.append('treasuryUsername', treasury?.username);
    }
    if (provider?.username) {
      formData.append('providerUsername', provider?.username);
    }
    formData.append(
      'defaultEntryFee',
      Number(defaultEntryFee) > 0
        ? parseUnits(defaultEntryFee, props?.token?.decimals || 18).toString()
        : '0',
    );
    formData.append(
      'levelOwnerFeeRate',
      (Number(levelOwnerFeeRate) * 100) as any,
    );
    formData.append('platformFeeRate', (Number(platformFeeRate) * 100) as any);
    if (providerFeeRate) {
      formData.append(
        'providerFeeRate',
        (Number(providerFeeRate) * 100) as any,
      );
    }
    formData.append(
      'affiliateFeeRate',
      (Number(affiliateFeeRate) * 100) as any,
    );
    if (treasuryFeeRate) {
      formData.append(
        'treasuryFeeRate',
        (Number(treasuryFeeRate) * 100) as any,
      );
    }
    formData.append('minLevel', Number(minLevel) as any);
    formData.append('maxLevel', Number(maxLevel) as any);
    formData.append('scoringModel', scoringModel);

    const response = await registerGame(formData);

    if (response?.success) {
      toast.success('Register your game success!!');
      onCloseModal();
      props.onRefresh();
    } else {
      toast.error('Register game failed!!');
    }

    setLoading(false);
  };

  const onCloseModal = () => {
    reviewIntegrateToggle();
    form.resetFields();
    props.onRefresh();
  };

  const handleBackButton = () => {
    integrateToggle();
  };

  const modalHeader = (
    <HeaderContainer>
      <BackButton onClick={handleBackButton} disabled={loading}>
        <ArrowLeftOutlined />
      </BackButton>
      <HeaderTitle>Review Your Game</HeaderTitle>
    </HeaderContainer>
  );

  return (
    <>
      <ThemedModal
        title={modalHeader}
        open={isOpen}
        onCancel={onCloseModal}
        footer={false}
        width={870}
      >
        <StyledForm
          onFinish={handleInternalGame}
          autoComplete="off"
          layout="vertical"
          labelcolor="black"
          form={form}
        >
          <FormItemWrapper>
            <GroupTitleStyled>Game Info</GroupTitleStyled>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <FormItemInput className="mt-4" name={['game-name']}>
                  <Tooltip color="#23c879" placement="top" title={props?.name}>
                    <InputWrapper>
                      <LabelStyled param="125px">Game name</LabelStyled>
                      <TextField flex="1">
                        {truncateText(props?.name, 20, 0)}
                      </TextField>
                    </InputWrapper>
                  </Tooltip>
                </FormItemInput>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <FormItemInput className="mt-4" name={['game-slug']}>
                  <Tooltip color="#23c879" placement="top" title={props?.slug}>
                    <InputWrapper>
                      <LabelStyled param="125px">Game path</LabelStyled>
                      <TextField flex="1">
                        {truncateText(props?.slug, 20, 0)}
                      </TextField>
                    </InputWrapper>
                  </Tooltip>
                </FormItemInput>
              </Col>
              <Col xs={24}>
                <FormItemInput className="mt-4" name={['game-description']}>
                  <InputWrapper>
                    <LabelStyled param="125px">Description</LabelStyled>
                    <TextField wrap="true" flex="1">
                      {props?.description &&
                        JSON.parse(props?.description)
                          ?.split('\n')
                          .map((item, key) => <div key={key}>{item}</div>)}
                    </TextField>
                  </InputWrapper>
                </FormItemInput>
                <FormItemInput className="mt-4" name={['game-scoring-model']}>
                  <InputWrapper>
                    <LabelStyled>Scoring model</LabelStyled>
                    <TextField flex="1">
                      {props?.scoringModel
                        ?.replace(/([A-Z])/g, ' $1')
                        ?.slice(1)}
                    </TextField>
                  </InputWrapper>
                </FormItemInput>
                <FormItemInput className="mt-4" name={['game-tags']}>
                  <InputWrapper>
                    <LabelStyled param="125px">Tags</LabelStyled>
                    <TextField wrap="true" flex="1">
                      {props?.tags?.join(', ')}
                    </TextField>
                  </InputWrapper>
                </FormItemInput>
                <FormItemInput
                  className="mt-4"
                  name={['game-background-img-url']}
                >
                  <InputWrapper style={{ justifyContent: 'space-between' }}>
                    <LabelStyled>Background</LabelStyled>
                    <BackgroundGameImage src={props?.backgroundImg?.url} />
                  </InputWrapper>
                </FormItemInput>
                <FormItemInput
                  className="mt-4"
                  name={['game-background-img-url']}
                >
                  <InputWrapper style={{ justifyContent: 'space-between' }}>
                    <LabelStyled>Build file</LabelStyled>
                    <UploadFileZipWrapper>
                      {props?.buildFile?.name}
                    </UploadFileZipWrapper>
                  </InputWrapper>
                </FormItemInput>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <FormItemInput className="mt-4" name={['game-name']}>
                  <Tooltip color="#23c879" placement="top" title={props?.name}>
                    <InputWrapper>
                      <LabelStyled param="125px">Min level</LabelStyled>
                      <TextField flex="1">{props?.minLevel}</TextField>
                    </InputWrapper>
                  </Tooltip>
                </FormItemInput>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <FormItemInput className="mt-4" name={['game-slug']}>
                  <Tooltip color="#23c879" placement="top" title={props?.slug}>
                    <InputWrapper>
                      <LabelStyled param="125px">Max level</LabelStyled>
                      <TextField flex="1">{props?.maxLevel}</TextField>
                    </InputWrapper>
                  </Tooltip>
                </FormItemInput>
              </Col>
            </Row>
            <>
              <DividerStyled />
              <GroupTitleStyled>Game Release</GroupTitleStyled>
              <Row gutter={8} className="mt-4">
                <Col xs={24}>
                  <FormItemInput
                    className="mt-4"
                    name={['game-build-file-url']}
                  >
                    <InputWrapper>
                      <LabelStyled>Game version</LabelStyled>
                      <TextField flex="1">{props?.activeVersion}</TextField>
                    </InputWrapper>
                  </FormItemInput>

                  <CheckBoxWrapper
                    className="mt-4"
                    param={
                      props?.isTestingGame
                        ? props?.isTestingGame.toString()
                        : undefined
                    }
                  >
                    <CheckBoxText>Test mode</CheckBoxText>
                    <CheckBoxStyled
                      param={
                        props?.isTestingGame
                          ? props?.isTestingGame.toString()
                          : undefined
                      }
                      checked={props?.isTestingGame}
                    />
                  </CheckBoxWrapper>
                </Col>
              </Row>
            </>
            {props?.isPlatformAdmin && (
              <>
                <DividerStyled />
                <GroupTitleStyled>Game Type</GroupTitleStyled>
                <Row gutter={8} className="mt-4">
                  <Col xs={24}>
                    <CheckBoxWrapper
                      param={
                        !props?.isExternal
                          ? (!props?.isExternal).toString()
                          : undefined
                      }
                    >
                      <CheckBoxText>Internal game</CheckBoxText>
                      <CheckBoxStyled
                        param={
                          !props?.isExternal
                            ? (!props?.isExternal).toString()
                            : undefined
                        }
                        checked={!props?.isExternal}
                        disabled={props?.isExternal}
                      />
                    </CheckBoxWrapper>
                    {!props.isExternal && (
                      <RadioGroupStyled
                        value={props?.isStoshi}
                        disabled={props?.isExternal}
                      >
                        <Radio value={false}>General game</Radio>
                        <Radio value={true}>Stoshi game</Radio>
                      </RadioGroupStyled>
                    )}
                    <CheckBoxWrapper
                      param={
                        props?.isExternal
                          ? props?.isExternal.toString()
                          : undefined
                      }
                    >
                      <CheckBoxText>External game</CheckBoxText>
                      <CheckBoxStyled
                        param={
                          props?.isExternal
                            ? props?.isExternal.toString()
                            : undefined
                        }
                        checked={props?.isExternal}
                        disabled={!props?.isExternal}
                      />
                    </CheckBoxWrapper>
                  </Col>
                </Row>
              </>
            )}
            <DividerStyled />
            <GroupTitleStyled>Distribution Config</GroupTitleStyled>
            <Row gutter={8}>
              <Col xs={24} sm={24} md={24} lg={14}>
                <FormItemInput className="mt-4" name={['token']}>
                  <Tooltip
                    color="#23c879"
                    placement="top"
                    title={`${props?.token?.name} (${props?.token?.symbol}): ${props?.token?.address}`}
                  >
                    <InputWrapper>
                      <LabelStyled param="125px">Tokens used</LabelStyled>
                      <TextField flex="1">
                        {props?.token?.name} ({props?.token?.symbol})
                        {/* {truncateText(props?.token?.address, 15, 4)} */}
                      </TextField>
                    </InputWrapper>
                  </Tooltip>
                </FormItemInput>
                {(props.isExternal ||
                  (props.isInWhitelist && !props.isPlatformAdmin)) && (
                  <FormItemInput className="mt-4" name={['provider']}>
                    <Tooltip
                      color="#23c879"
                      placement="top"
                      title={props?.provider?.username}
                    >
                      <InputWrapper>
                        <LabelStyled param="125px">Provider</LabelStyled>
                        <TextField flex="1">
                          <AvatarImg
                            src={props?.provider?.avatarURL || defaultAvatarImg}
                          />
                          {props?.provider?.username}
                        </TextField>
                      </InputWrapper>
                    </Tooltip>
                  </FormItemInput>
                )}
                {props?.isStoshi && (
                  <FormItemInput className="mt-4" name={['treasury']}>
                    <Tooltip
                      color="#23c879"
                      placement="top"
                      title={props?.treasury?.username}
                    >
                      <InputWrapper>
                        <LabelStyled param="125px">Treasury</LabelStyled>
                        <TextField flex="1">
                          <AvatarImg
                            src={props?.treasury?.avatarURL || defaultAvatarImg}
                          />
                          {props?.treasury?.username}
                        </TextField>
                      </InputWrapper>
                    </Tooltip>
                  </FormItemInput>
                )}
                <FormItemInput className="mt-4" name={['default-fee']}>
                  <InputWrapper>
                    <LabelStyled param="200px">Default entry fee</LabelStyled>
                    <Tooltip
                      color="#23c879"
                      placement="top"
                      title={props?.defaultEntryFee}
                    >
                      <TextField flex="1">{props?.defaultEntryFee}</TextField>
                    </Tooltip>
                  </InputWrapper>
                </FormItemInput>
              </Col>

              <Col xs={24} sm={24} md={24} lg={10}>
                <FormItemInput className="mt-4" name={['level-owner-profit']}>
                  <InputWrapper>
                    <LabelStyled>Level owner profit</LabelStyled>
                    <TextField flex="1">{props?.levelOwnerFeeRate}%</TextField>
                  </InputWrapper>
                </FormItemInput>
                {props.isStoshi && (
                  <FormItemInput className="mt-4" name={['treasury-profit']}>
                    <InputWrapper>
                      <LabelStyled>Third party profit</LabelStyled>
                      <TextField flex="1">{props?.treasuryFeeRate}%</TextField>
                    </InputWrapper>
                  </FormItemInput>
                )}
                {(props.isExternal ||
                  (props.isInWhitelist && !props.isPlatformAdmin)) && (
                  <FormItemInput className="mt-4" name={['provider-profit']}>
                    <InputWrapper>
                      <LabelStyled>Provider profit</LabelStyled>
                      <TextField flex="1">{props?.providerFeeRate}%</TextField>
                    </InputWrapper>
                  </FormItemInput>
                )}
                <FormItemInput className="mt-4" name={['affiliate-profit']}>
                  <InputWrapper>
                    <LabelStyled>Affiliate profit</LabelStyled>
                    <TextField flex="1">{props?.affiliateFeeRate}%</TextField>
                  </InputWrapper>
                </FormItemInput>
                <FormItemInput className="mt-4" name={['provider-profit']}>
                  <InputWrapper>
                    <LabelStyled>Platform profit</LabelStyled>
                    <TextField flex="1">{props?.platformFeeRate}%</TextField>
                  </InputWrapper>
                </FormItemInput>
              </Col>
            </Row>
          </FormItemWrapper>

          <Wrapper>
            <SubmitButton
              loading={loading}
              type="primary"
              htmlType="submit"
              disabled={false}
            >
              Submit
            </SubmitButton>
          </Wrapper>
        </StyledForm>
      </ThemedModal>
    </>
  );
};

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useWeb3React } from '@web3-react/core';
import { Collapse, Empty, Form, Spin, Switch } from 'antd';
import BackgroundProfile from 'assets/images/background-profile.png';
import defaultProfileImg from 'assets/images/default-user-profile.png';
import revenueicon from 'assets/vectors/logo-profile-revenue.svg';
import { ConfirmDelete } from 'components/Modals/UserProfile/DeleteAccount/ConfirmDelete';
import { LevelMasters } from 'components/Modals/UserProfile/LevelMasters';
import { UploadAvatar } from 'components/Modals/UserProfile/UploadAvatar';
import { VerifyOtp } from 'components/Modals/UserProfile/VerifyOTP';
import { GameSetting } from 'constants/enum/GameSetting';
import { ethers } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import { useGetNotificationSetting } from 'hooks/notification-setting/useGetNotificationSetting';
import useEnableNotification from 'hooks/notification/useEnableNotification';
import { useGetPlayerGameRevenueListForScroll } from 'hooks/profile/useGetPlayerGameRevenueListForScroll';
import { useGetPlayerGameStatistics } from 'hooks/profile/useGetPlayerGameStatistics';
import { useGetRemainingBalance } from 'hooks/token-balance/useGetRemainingBalance';
import useOneSignal from 'hooks/useOneSignal';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ApplicationModal } from 'redux/slices/application';
import { useToggleModal } from 'redux/slices/application/hook';
import { loginUser } from 'redux/slices/player';
import { useAppSelector } from 'redux/store';
import signMessage from 'services/Contracts/signMessage';
import {
  ILoginByWallet,
  setupProfile,
  updateNotificationSetting,
  updatePlayerEmail,
  updateWallet,
} from 'services/api';
import HomepageTemplate from 'template/Homepage';
import { LocalhostStorage } from 'utils/sessionStorage';
import Web3 from 'web3';
import {
  AddText,
  Avatar,
  AvatarWrapper,
  BIGAInfoHeaderWrapper,
  BIGAInfoRightWrapper,
  BIGAInfoWrapper,
  BIGATextWrapper,
  BIGATitle,
  BIGAValue,
  Background,
  BackgroundWrapper,
  CollapseCancelButton,
  CollapseContentWrapper,
  CollapseHeaderWrapper,
  CollapseInputWrapper,
  CollapseSaveButton,
  CollapseSaveButtonWrapper,
  CollapseTitleHeader,
  CollapseWrapper,
  DeleteButton,
  DeleteButtonWrapper,
  InfoDetail,
  InfoLeftWrapper,
  InfoRightWrapper,
  InfoRightWrapperForScroll,
  InfoTextWrapper,
  InfoTitle,
  InfoTitleText,
  InfoValueText,
  InfoWrapper,
  LoadMoreButtonWrapper,
  LoadMoreContentWrapper,
  LoadMoreIcon,
  LoadMoreText,
  ProfileAvatarWrapper,
  ProfileContentWrapper,
  ProfileInfoWrapper,
  ProfileWrapper,
  RevenueLogo,
  RevenueTitle,
  RevenueTitleWrapper,
  RevenueValue,
  RevenueWrapper,
  UnderlineText,
  Username,
  UsernameWrapper,
  WithdrawText,
  WithdrawWrapper,
} from './styled';

export const Profile = () => {
  const { account } = useWeb3React();
  const [isUsernameEditing, setIsUsernameEditing] = useState(false);
  const [isEmailEditing, setIsEmailEditing] = useState(false);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const { playerInfo, refreshAvatar } = useAppSelector(state => state.player);

  const handleUsernameChange = event => {
    setUsername(event.target.value);
  };

  const dispatch = useDispatch();

  const handleUsernameEditClick = () => {
    setIsUsernameEditing(prevIsUsernameEditing => !prevIsUsernameEditing);
  };

  const handleEmailEditClick = () => {
    setIsEmailEditing(prevIsEmailEditing => !prevIsEmailEditing);
  };

  const handleCancelUsernameClick = () => {
    setIsUsernameEditing(false);
  };

  const handleCancelEmailClick = () => {
    setIsEmailEditing(false);
  };

  const handleSaveUsernameClick = async () => {
    try {
      setUsernameUpdating(true);

      const formData = new FormData();
      formData.append('username', username);

      const res = await setupProfile(formData);
      if (res?.success) {
        setUsername('');
        setIsUsernameEditing(false);
        const updatedPlayerInfo = { ...playerInfo, username: username };
        dispatch(loginUser(updatedPlayerInfo));
        toast.success('Update username successfully');
      } else {
        toast.error(res?.message || 'Update username failed');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setUsernameUpdating(false);
    }
  };

  const onUpdatedEmail = async () => {
    const updatedPlayerInfo = { ...playerInfo, emailAddress: email };
    dispatch(loginUser(updatedPlayerInfo));
    toast.success('Update email successfully');
    setEmail('');

    toggleVerifyOtpModal();
    handleCancelEmailClick();
  };

  const toggleUploadAvatarModal = useToggleModal(
    ApplicationModal.PROFILE_UPLOAD_AVATAR,
  );
  const showAvatarModal = () => {
    toggleUploadAvatarModal();
  };

  const toggleLevelMastersModal = useToggleModal(
    ApplicationModal.PROFILE_LEVEL_MASTERS,
  );
  const showLevelMastersModal = () => {
    toggleLevelMastersModal();
  };

  const toggleDeleteAccountModal = useToggleModal(
    ApplicationModal.PROFILE_CONFIRM_DELETE_ACCOUNT,
  );
  const showDeleteAccountModal = () => {
    toggleDeleteAccountModal();
  };

  const toggleVerifyOtpModal = useToggleModal(
    ApplicationModal.PROFILE_VERIFY_OTP,
  );
  const showVerifyOtpModal = async () => {
    try {
      setEmailUpdating(true);

      const res = await updatePlayerEmail({ emailAddress: email });
      if (res?.success) {
        toggleVerifyOtpModal();
      } else {
        toast.error(res?.message || 'Update email failed');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setEmailUpdating(false);
    }
  };

  const [refresh, setRefresh] = useState<boolean>(false);
  const [usernameUpdating, setUsernameUpdating] = useState<boolean>(false);
  const [emailUpdating, setEmailUpdating] = useState<boolean>(false);
  const [params, setParams] = useState({
    pageSize: 5,
    pageIndex: 1,
    isCurrentMaster: false,
    isRecent: true,
  });
  const [metamaskAccount, setMetamaskAccount] = useState('');

  const handleRefresh = () => {
    setRefresh(prevRefresh => !prevRefresh);
  };

  const { playerGameStatistics } = useGetPlayerGameStatistics(null, refresh);

  const { remainingBalance } = useGetRemainingBalance(
    process.env.REACT_APP_DEFAULT_TOKEN_ADDRESS,
  );

  const {
    playerGameRevenueList,
    loading: playerGameRevenueListLoading,
    total: playerGameRevenueListTotal,
  } = useGetPlayerGameRevenueListForScroll(params, refresh);

  const handleScroll = useCallback(
    e => {
      if (
        e.currentTarget.scrollTop + e.currentTarget.offsetHeight >=
          e.currentTarget.scrollHeight &&
        !playerGameRevenueListLoading &&
        playerGameRevenueList?.length < playerGameRevenueListTotal
      ) {
        setParams({
          ...params,
          pageIndex: params.pageIndex + 1,
        });
      }
    },
    [
      params,
      playerGameRevenueListLoading,
      playerGameRevenueListTotal,
      playerGameRevenueList,
    ],
  );
  const avatarURL = useMemo(() => {
    return playerInfo?.avatarURL
      ? `${playerInfo?.avatarURL}?timestamp=${Date.now()}`
      : '';
  }, [playerInfo?.avatarURL, refreshAvatar]);

  const { notificationSettingList } = useGetNotificationSetting();
  const [notificationSettings, setNotificationSettings] = useState([]);
  const { getSubscriptionID, checkInitOneSignal } = useOneSignal();
  const isEnableNotificateion = useEnableNotification();

  useEffect(() => {
    if (notificationSettingList.length > 0) {
      setNotificationSettings(notificationSettingList);
    }
    // else {
    //   const defaultSettings = Object.values(GameSetting).map(type => ({
    //     type,
    //     enabled: false,
    //   }));

    //   updateNotificationSetting(defaultSettings);
    //   setNotificationSettings(defaultSettings);
    // }
  }, [notificationSettingList]);

  useEffect(() => {
    const fetchMetamaskAccount = async () => {
      if (!window.ethereum) {
        return;
      }
      try {
        const web3 = new Web3(window.ethereum);
        const accounts = await web3.eth.getAccounts();
        const currentAccount = accounts[0];
        setMetamaskAccount(currentAccount);

        window.ethereum.on('accountsChanged', async function (newAccounts) {
          const updatedAccounts = await web3.eth.getAccounts();
          const newCurrentAccount = updatedAccounts[0];
          setMetamaskAccount(newCurrentAccount);
        });
      } catch (error) {
        console.error(error);
      }
    };

    fetchMetamaskAccount();
  }, []);
  const handleNotificationToggle = async (type, checked) => {
    const updatedSettings = notificationSettings.map(setting => {
      if (setting.type === type) {
        return { ...setting, enabled: checked };
      }
      return setting;
    });

    setNotificationSettings(updatedSettings);
    const resUpdate = await updateNotificationSetting(updatedSettings);
    if (resUpdate?.success) {
      const DesktopNotification = resUpdate?.payload?.data
        .filter(item => item.type === GameSetting.DesktopNotification)
        .map(item => item.enabled)[0];
    }
  };

  const getNotificationTitle = type => {
    switch (type) {
      case GameSetting.EmailNotification:
        return 'Email Notification';
      case GameSetting.DesktopNotification:
        return 'Desktop Notification';
      default:
        return 'Unknown Notification';
    }
  };

  const handleChangeAccount = async () => {
    try {
      if (!metamaskAccount) return;
      let signMess;
      const typeOfConnector =
        LocalhostStorage.get('typeOfConnector') ?? 'Metamask';
      if (metamaskAccount && typeOfConnector) {
        if (typeOfConnector === 'Metamask') {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const dataSignature = await signMessage(
            metamaskAccount,
            provider.provider,
          );
          signMess = dataSignature;
        } else {
          return;
        }
      }
      if (metamaskAccount && !signMess?.success) {
        toast.error('User denied message signature.');
        return;
      }
      const linkWalletParams: ILoginByWallet = {
        message: signMess.data.message,
        signature: signMess.data.signature,
        walletAddress: signMess.data.account,
      };
      const linkWalletCall = await updateWallet(linkWalletParams);
      if (!linkWalletCall.success) {
        toast.error(linkWalletCall.message);
        return;
      }
      const updatedPlayerInfo = {
        ...playerInfo,
        walletAddress: signMess.data.account,
      };
      dispatch(loginUser(updatedPlayerInfo));
      handleRefresh();
      LocalhostStorage.set('account', signMess.data.account ?? account);
      toast.success('Change wallet address success');
    } catch (err) {
      toast.error('Change wallet address failed');
    }
  };

  return (
    <HomepageTemplate>
      <ProfileWrapper>
        <BackgroundWrapper>
          <Background src={BackgroundProfile} />
          <ProfileAvatarWrapper>
            <AvatarWrapper>
              <Avatar
                src={avatarURL ? avatarURL : defaultProfileImg}
                alt="avatar profile"
                onClick={showAvatarModal}
              />
            </AvatarWrapper>
            <UsernameWrapper>
              <Username>{playerInfo?.username || 'Username'}</Username>
            </UsernameWrapper>
          </ProfileAvatarWrapper>
          <UploadAvatar onRefresh={handleRefresh} />
        </BackgroundWrapper>

        <ProfileContentWrapper>
          <ProfileInfoWrapper>
            <InfoLeftWrapper>
              <InfoTitle>Account Information</InfoTitle>
              <InfoDetail>
                Manage your account details, such as email address, balance, and
                wallet address. Make changes if needed.
              </InfoDetail>
            </InfoLeftWrapper>

            <InfoRightWrapper>
              <InfoWrapper>
                <InfoTextWrapper>
                  <InfoTitleText>Username</InfoTitleText>
                  <InfoValueText>
                    {playerInfo?.username || 'Username'}
                  </InfoValueText>
                </InfoTextWrapper>
                <UnderlineText onClick={handleUsernameEditClick}>
                  Edit
                </UnderlineText>
              </InfoWrapper>
              {isUsernameEditing && (
                <Collapse activeKey="1">
                  <CollapseWrapper>
                    <Spin spinning={usernameUpdating}>
                      <CollapseContentWrapper>
                        <CollapseHeaderWrapper>
                          <CollapseTitleHeader>Username</CollapseTitleHeader>
                          <CollapseCancelButton
                            onClick={handleCancelUsernameClick}
                          >
                            Cancel
                          </CollapseCancelButton>
                        </CollapseHeaderWrapper>

                        <Form
                          onFinish={handleSaveUsernameClick}
                          autoComplete="off"
                          disabled={usernameUpdating}
                        >
                          <Form.Item
                            name="username"
                            rules={[
                              {
                                min: 6,
                                message:
                                  'Username must be minimum 6 characters',
                              },
                              {
                                max: 20,
                                message:
                                  'Username has a maximum of 20 characters',
                              },
                              {
                                pattern: new RegExp(
                                  '^[A-Za-z]*[A-Za-z][a-zA-Z0-9_.]{5,19}$',
                                ),
                                message:
                                  'Username must start with a letter. Allowed \ncharacters are a-z (both lower case and upper case), \n0-9, _(underscore), and .(dot)',
                              },
                            ]}
                            validateFirst={true}
                          >
                            <CollapseInputWrapper
                              type="text"
                              placeholder="Username"
                              value={username}
                              onChange={handleUsernameChange}
                            />
                          </Form.Item>

                          <CollapseSaveButtonWrapper>
                            <CollapseSaveButton
                              htmlType="submit"
                              disabled={usernameUpdating}
                            >
                              Save
                            </CollapseSaveButton>
                          </CollapseSaveButtonWrapper>
                        </Form>
                      </CollapseContentWrapper>
                    </Spin>
                  </CollapseWrapper>
                </Collapse>
              )}
              <InfoWrapper>
                <InfoTextWrapper>
                  <InfoTitleText>Email address</InfoTitleText>
                  <InfoValueText>
                    {playerInfo?.emailAddress || 'Email address'}
                  </InfoValueText>
                </InfoTextWrapper>
                <UnderlineText onClick={handleEmailEditClick}>
                  Edit
                </UnderlineText>
              </InfoWrapper>

              {isEmailEditing && (
                <Collapse activeKey="2">
                  <CollapseWrapper>
                    <Spin spinning={emailUpdating}>
                      <CollapseContentWrapper>
                        <CollapseHeaderWrapper>
                          <CollapseTitleHeader>
                            Email address
                          </CollapseTitleHeader>
                          <CollapseCancelButton
                            onClick={handleCancelEmailClick}
                          >
                            Cancel
                          </CollapseCancelButton>
                        </CollapseHeaderWrapper>
                        <Form
                          onFinish={showVerifyOtpModal}
                          autoComplete="off"
                          disabled={emailUpdating}
                        >
                          <Form.Item
                            name="email"
                            rules={[
                              {
                                pattern: new RegExp(
                                  '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$',
                                ),
                                message: 'Invalid email',
                              },
                            ]}
                          >
                            <CollapseInputWrapper
                              type="text"
                              placeholder="Email address"
                              disabled={emailUpdating}
                              value={email}
                              onChange={e => setEmail(e.target.value)}
                            />
                          </Form.Item>
                          <CollapseSaveButtonWrapper>
                            <CollapseSaveButton
                              htmlType="submit"
                              disabled={emailUpdating}
                            >
                              Save
                            </CollapseSaveButton>
                          </CollapseSaveButtonWrapper>
                        </Form>
                      </CollapseContentWrapper>
                    </Spin>
                  </CollapseWrapper>
                  <VerifyOtp
                    waitToVerifyEmailAddress={email}
                    onSuccess={onUpdatedEmail}
                  />
                </Collapse>
              )}
              <InfoWrapper>
                <InfoTextWrapper>
                  <InfoTitleText>Wallet address</InfoTitleText>
                  <InfoValueText>
                    {playerInfo?.walletAddress
                      ? playerInfo?.walletAddress
                      : 'No wallet address is connected'}
                  </InfoValueText>
                </InfoTextWrapper>
                {playerInfo?.walletAddress &&
                  playerInfo?.walletAddress !== metamaskAccount && (
                    <UnderlineText onClick={handleChangeAccount}>
                      Change
                    </UnderlineText>
                  )}
              </InfoWrapper>
              <InfoWrapper>
                <InfoTextWrapper>
                  <InfoTitleText>Wallet balance</InfoTitleText>
                  <InfoValueText>
                    {remainingBalance?.amount
                      ? parseFloat(
                          formatUnits(
                            remainingBalance.amount,
                            remainingBalance.tokenInfo?.decimals,
                          ),
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : 0}
                  </InfoValueText>
                </InfoTextWrapper>
                <WithdrawWrapper>
                  <WithdrawText>Withdraw</WithdrawText>
                  <AddText>Add</AddText>
                </WithdrawWrapper>
              </InfoWrapper>
            </InfoRightWrapper>
          </ProfileInfoWrapper>

          <ProfileInfoWrapper>
            <InfoLeftWrapper>
              <InfoTitle>Current Game Stats</InfoTitle>
              <InfoDetail>
                Track your current gaming achievements, and high scores to see
                how you&apos;re performing in different games.
              </InfoDetail>
            </InfoLeftWrapper>

            <InfoRightWrapper>
              <InfoWrapper>
                <InfoTextWrapper>
                  <InfoTitleText>Game played</InfoTitleText>
                  <InfoValueText>
                    {playerGameStatistics?.gamesPlayed || 0}
                  </InfoValueText>
                </InfoTextWrapper>
                <BIGATextWrapper>
                  <BIGAValue>
                    {playerGameStatistics?.spentAmount?.amount
                      ? parseFloat(
                          formatUnits(
                            playerGameStatistics?.spentAmount?.amount,
                            playerGameStatistics?.spentAmount?.decimals,
                          ),
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : 0}
                  </BIGAValue>
                  <BIGATitle>BIGA Spent</BIGATitle>
                </BIGATextWrapper>
              </InfoWrapper>
              <InfoWrapper>
                <InfoTextWrapper>
                  <InfoTitleText>Revenue Breakdown</InfoTitleText>
                  <InfoValueText>
                    {playerGameStatistics?.currentRevenueCount || 0}
                  </InfoValueText>
                </InfoTextWrapper>
                <UnderlineText onClick={showLevelMastersModal}>
                  Details
                </UnderlineText>
              </InfoWrapper>

              <LevelMasters
                levelMastered={playerGameStatistics?.levelMastered}
                gameLevelMastered={
                  playerGameStatistics?.gameAmountOfLevelMastered
                }
              />
            </InfoRightWrapper>
          </ProfileInfoWrapper>

          <ProfileInfoWrapper>
            <InfoLeftWrapper>
              <InfoTitle>Revenue History</InfoTitle>
              <InfoDetail>
                View your previous revenue generated and explore how your
                leaderboard positions in high score games contributed to your
                earnings from the past.
              </InfoDetail>
            </InfoLeftWrapper>

            <BIGAInfoRightWrapper>
              <BIGAInfoWrapper>
                <BIGAInfoHeaderWrapper>
                  <RevenueWrapper>
                    <RevenueValue>
                      {'$'}
                      {playerGameStatistics?.revenueAmount?.amount
                        ? (
                            parseFloat(
                              formatUnits(
                                playerGameStatistics?.revenueAmount?.amount,
                                playerGameStatistics?.revenueAmount?.decimals,
                              ),
                            ) / 10
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : 0}
                    </RevenueValue>
                    <RevenueTitleWrapper>
                      <RevenueLogo src={revenueicon} />
                      <RevenueTitle>Total Revenue </RevenueTitle>
                    </RevenueTitleWrapper>
                  </RevenueWrapper>
                </BIGAInfoHeaderWrapper>
                <Spin spinning={playerGameRevenueListLoading}>
                  <InfoRightWrapperForScroll
                    // onScroll={handleScroll}
                    isEmpty={!playerGameRevenueList?.length}
                  >
                    {playerGameRevenueList?.length ? (
                      playerGameRevenueList?.map(
                        ({ level, revenueAmount, gameName }, index) => (
                          <InfoWrapper key={index}>
                            <InfoTextWrapper>
                              <InfoTitleText>{gameName}</InfoTitleText>
                              <InfoValueText>Level {level}</InfoValueText>
                            </InfoTextWrapper>
                            <BIGATextWrapper>
                              <BIGAValue>
                                {'$'}
                                {revenueAmount?.amount
                                  ? (
                                      parseFloat(
                                        formatUnits(
                                          revenueAmount?.amount,
                                          revenueAmount?.decimals,
                                        ),
                                      ) / 10
                                    ).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })
                                  : 0}
                              </BIGAValue>
                              <BIGATitle>Revenue</BIGATitle>
                            </BIGATextWrapper>
                          </InfoWrapper>
                        ),
                      )
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                  </InfoRightWrapperForScroll>

                  {playerGameRevenueList?.length <
                    playerGameRevenueListTotal && (
                    <LoadMoreButtonWrapper>
                      <LoadMoreContentWrapper
                        onClick={() => {
                          setParams({
                            ...params,
                            pageIndex: params.pageIndex + 1,
                          });
                        }}
                      >
                        <LoadMoreText>Load more</LoadMoreText>
                        <LoadMoreIcon />
                      </LoadMoreContentWrapper>
                    </LoadMoreButtonWrapper>
                  )}
                </Spin>
              </BIGAInfoWrapper>
            </BIGAInfoRightWrapper>
          </ProfileInfoWrapper>
          {isEnableNotificateion && (
            <ProfileInfoWrapper>
              <InfoLeftWrapper>
                <InfoTitle>Notifications Settings</InfoTitle>
                <InfoDetail>
                  Allow to manage notification preferences directly, giving you
                  full control over how you receive updates from BIGA.
                </InfoDetail>
              </InfoLeftWrapper>
              <InfoRightWrapper>
                {notificationSettings.map(setting => (
                  <InfoWrapper key={setting.type}>
                    <InfoTextWrapper>
                      <InfoTitleText>
                        {getNotificationTitle(setting.type)}
                      </InfoTitleText>
                    </InfoTextWrapper>
                    <Switch
                      checked={setting.enabled}
                      onChange={checked =>
                        handleNotificationToggle(setting.type, checked)
                      }
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                    />
                  </InfoWrapper>
                ))}
              </InfoRightWrapper>
            </ProfileInfoWrapper>
          )}
          <DeleteButtonWrapper>
            <DeleteButton onClick={showDeleteAccountModal}>
              Delete account
            </DeleteButton>
          </DeleteButtonWrapper>
          <ConfirmDelete />
        </ProfileContentWrapper>
      </ProfileWrapper>
    </HomepageTemplate>
  );
};

import kektImg from 'assets/images/games/kekt.png';
import stoshiRunImg from 'assets/images/games/stoshi-run.png';
import stoshiWorldImg from 'assets/images/games/stoshi-world.png';
import wenLamboImg from 'assets/images/games/wen-lambo.png';
import bigarcadeCircle from 'assets/vectors/biga-arcade-circle.svg';
import gamePadSmallImg from 'assets/vectors/game-pad-small.svg';
import { PitRouter } from 'constants/routers';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import {
  LandingGameHeading,
  LandingGameHeadingWrapper,
  LandingGameListWrapper,
  LandingGameRow,
  LandingGameSectionWrapper,
  LandingGamesFooterWrapper,
  LandingGameWaitListJoinButton,
} from './styled';

const gameData = [
  {
    name: 'Stoshi',
    imgUrl: stoshiWorldImg,
    description: `Stoshi is a 2D platform maze game where you must collect coins and reach the wallet without dying. Avoid enemies and obstacles as you navigate the maze, and use your skills to pass each level and achieve Level Master status. Each level is more challenging than the last, so you'll need to be quick, agile, and strategic to succeed. Stoshi is a fun and challenging game that will test your skills.`,
  },
  {
    name: 'Kekt',
    imgUrl: kektImg,
    description: `Pepe navigates the worlds of critters to get to his nemesis (other famous memes). Each boss has their own unique skills, and Pepe must conquer each world before challenging the boss to take ownership of that world.`,
  },
  {
    name: 'Wen Lambo',
    imgUrl: wenLamboImg,
    description: `Wen Lambo is a combination of speed racing and drifting through an endless track that becomes more challenging over time. Players compete to achieve the highest score and claim ownership of the track.`,
  },
  {
    name: 'Stoshi Run',
    imgUrl: stoshiRunImg,
    description: `Stoshi is back to take on the challenge of freerunning across modern suburbia against his opponents. What starts off easy becomes progressively harder as the game progresses, with opponents becoming faster. Timing and skill must be almost flawless to stay in the race and achieve a new high score.`,
  },
];

export const LandingGamesSection = () => {
  const navigate = useNavigate();
  const handleRouterPublicAlphaButton = () => {
    if (process.env.REACT_APP_ENV === 'staging') {
      window.location.href = process.env.REACT_APP_PUBLIC_ALPHA_LINK;
    } else {
      navigate(PitRouter.REGISTER);
    }
  };

  return (
    <LandingGameSectionWrapper>
      <LandingGameHeadingWrapper>
        <LandingGameHeading>
          <div className="game-heading-title">Games</div>
          <div className="game-heading-description">
            Multiple Games, Multiple Levels, Multiple Scoring Models. Find the
            right game for you.&quot; And become a level master and earn.
          </div>
        </LandingGameHeading>

        <LandingGameWaitListJoinButton
          id="seTt8WvJ"
          onClick={() => handleRouterPublicAlphaButton()}
        >
          <img src={gamePadSmallImg} alt="game-pad-small" />
          <span>Play now</span>
        </LandingGameWaitListJoinButton>
      </LandingGameHeadingWrapper>

      <LandingGameListWrapper>
        {gameData.map((game, index) => (
          <LandingGameRow key={index}>
            <div className="game-row-image">
              <img src={game.imgUrl} alt="stoshi-world" />
            </div>
            <div className="game-row-detail">
              <div className="game-row-detail-name">{game.name}</div>
              <div className="game-row-detail-description">
                {game.description}
              </div>
            </div>
          </LandingGameRow>
        ))}
      </LandingGameListWrapper>

      <LandingGamesFooterWrapper>
        <img src={bigarcadeCircle} alt="bigarcade-circle" />
        <div>
          New games added every month. Join our{' '}
          <Link to="https://twitter.com/bigaarcade" target="_blank">
            Twitter
          </Link>{' '}
          or{' '}
          <Link to="https://discord.gg/nGcGdS7tT3" target="_blank">
            Discord
          </Link>{' '}
          to stay up to date.
        </div>
      </LandingGamesFooterWrapper>
    </LandingGameSectionWrapper>
  );
};

import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { Tooltip } from 'antd';
import geminiIcon from 'assets/vectors/login-gemini.svg';
import applepayIcon from 'assets/vectors/logo-cashier-applepay.svg';
import arrowDownIcon from 'assets/vectors/logo-cashier-arrowdown.svg';
import paypalIcon from 'assets/vectors/logo-cashier-paypal.svg';
import stripeIcon from 'assets/vectors/logo-cashier-stripe.svg';
import { SelectToken } from 'components/Modals/Cashier';
import { PaymentErrorModal } from 'components/Modals/PaymentCheckout/PaymentError';
import { PaymentSuccessModal } from 'components/Modals/PaymentCheckout/PaymentSuccess';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { useGetSupportedToken } from 'hooks/cashier/useGetSupportedToken';
import useContractInfo from 'hooks/contract/useContractInfo';
import useERC20Callback from 'hooks/erc20/useERC20Callback';
import { useGetTokenInfo } from 'hooks/erc20/useGetTokenInfo';
import { useDisconnect } from 'hooks/useDisconnect';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDebounce } from 'react-use';
import { ApplicationModal } from 'redux/slices/application';
import { useToggleModal } from 'redux/slices/application/hook';
import { setSignar } from 'redux/slices/signar';
import { loginWallet } from 'redux/slices/user';
import {
  convertSupportedToPayment,
  getPaymentCheckoutSessionLink,
  IPaymentCheckout,
} from 'services/api';
import { LocalhostStorage } from 'utils/sessionStorage';
import { injected, WalletConnectInstance } from 'utils/wallets/connector';

import { PaymentMethodEnum } from '../../../constants/enum/PaymentMethod';
import { ConfirmPurchase } from '../ConfirmPurchase';
import { TokenSelected } from '../TokenSelected';
import { WalletNotConnected } from '../WalletNotConnected';
import {
  CashierRightContentFourthBlock,
  CashierRightContentSecondBlock,
  CashierRightContentThirdBlock,
  FourthBlockBalanceUnit,
  FourthBlockBalanceUnitText,
  FourthBlockBalanceWrapper,
  FourthBlockButton,
  FourthBlockButtonGemini,
  FourthBlockButtonPay,
  FourthBlockButtonPayWrapper,
  FourthBlockButtonTextGemini,
  FourthBlockButtonWrapper,
  FourthBlockContentWrapper,
  FourthBlockSelectButton,
  FourthBlockSelectButtonText,
  FourthBlockSelectButtonWrapper,
  FourthBlockSelectTitle,
  FourthBlockSelectWrapper,
  Gemini,
  Line,
  LinePay,
  ThirdBlockErrorText,
  ThirdBlockTitle,
  ThirdBlockUnit,
  ThirdBlockValue,
  Title,
  TitlePay,
} from './styled';

const numbers = [10, 50, 100, 200, 500, 1000];

export const Purchase = ({ setDisabled }) => {
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [convertedAmount, setConvertedAmount] = useState('0');
  const [convertedInputAmount, setConvertedInputAmount] = useState('0');
  const [debouncedInputAmount, setDebouncedInputAmount] = useState('0');
  const [refresh, setRefresh] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalOpened, setModalOpened] = useState(false);

  const inputRef = useRef(null);
  const queryParams = new URLSearchParams(window.location.search);
  const success = queryParams.get('success');
  const contractInfo = useContractInfo();

  const toggleSelectTokenModal = useToggleModal(
    ApplicationModal.CASHIER_SELECT_TOKEN,
  );

  const togglePaymentCheckoutSuccess = useToggleModal(
    ApplicationModal.PAYMENT_CHECKOUT_SUCCESS,
  );

  const togglePaymentCheckoutError = useToggleModal(
    ApplicationModal.PAYMENT_CHECKOUT_ERROR,
  );

  const showSelectTokenModal = () => {
    toggleSelectTokenModal();
  };

  const [showConfirm, setShowConfirm] = useState(false);

  const preventCharacter = e => {
    if (e.key === '-' || e.key === '.') {
      e.preventDefault();
    }
  };

  const { supportToken } = useGetSupportedToken(
    process.env.REACT_APP_DEFAULT_TOKEN_ADDRESS,
  );

  useDebounce(
    () => {
      void (async () => {
        setDebouncedInputAmount(selectedNumber);
      })();
    },
    500,
    [selectedNumber],
  );

  useEffect(() => {
    const convertAmount = async () => {
      setConvertedAmount('0');
      setConvertedInputAmount('0');
      if (debouncedInputAmount) {
        if (selectedItem) {
          const inputAmount = debouncedInputAmount.toString();
          // setLoading(true);

          const config = {
            supportedTokenAddress: supportToken?.address,
            paymentTokenAddress: selectedItem?.address,
            amount: parseUnits(
              debouncedInputAmount.toString(),
              supportToken?.decimals || 18,
            ).toString(),
          };
          const res = await convertSupportedToPayment(config);

          // setLoading(false);

          if (res?.success) {
            const { convertedAmount } = res?.payload?.data;
            setConvertedAmount(convertedAmount);
            setConvertedInputAmount(inputAmount);
          } else {
            toast.error(res?.message || 'Something went wrong');
          }
        }
      }
    };

    convertAmount();
  }, [selectedItem, debouncedInputAmount, supportToken]);

  const handleChangeNumber = async number => {
    if (number >= 0 && number < 1e6) {
      const decimalIndex = String(number).indexOf('.');

      if (decimalIndex !== -1)
        setSelectedNumber(String(number).slice(0, decimalIndex));
      else setSelectedNumber(String(number));
    }
  };

  const handleSelectNumber = async number => {
    const parsedValue = parseFloat(number);
    const sanitizedValue =
      isNaN(parsedValue) || !isFinite(parsedValue) ? '' : parsedValue;
    setSelectedNumber(
      sanitizedValue !== selectedNumber ? sanitizedValue : null,
    );
  };

  const handleNextClick = () => {
    if (
      selectedNumber !== null &&
      selectedNumber > 0 &&
      selectedItem !== null &&
      ableToPurchase &&
      tokenInfo?.tokenAddress === selectedItem?.address
    ) {
      setShowConfirm(true);
    }
  };

  const handleBackClick = () => {
    setShowConfirm(false);
    setSelectedNumber(null);
  };

  const { account, activate, chainId } = useWeb3React<Web3Provider>();

  const CHAIN_ID = LocalhostStorage.get('chainId');
  const dispatch = useDispatch();
  const disconnect = useDisconnect();

  useEffect(() => {
    void (() => {
      const isConnected = Boolean(LocalhostStorage.get('isConnected'));
      const typeOfConnector = LocalhostStorage.get('typeOfConnector');

      if (isConnected && typeOfConnector === 'Metamask') {
        activate(injected(CHAIN_ID));
        dispatch(loginWallet(LocalhostStorage.get('account')));
      }

      if (isConnected && typeOfConnector === 'Walletconnect') {
        activate(WalletConnectInstance);
        dispatch(loginWallet(LocalhostStorage.get('account')));
      }
    })();
  }, [CHAIN_ID]);

  useEffect(() => {
    const isConnected = Boolean(LocalhostStorage.get('isConnected'));
    const walletAddress = LocalhostStorage.get('account');
    dispatch(setSignar(LocalhostStorage.get('isConnected')));

    if (!isEmpty(account)) {
      if (
        isConnected &&
        account?.toUpperCase() !== walletAddress?.toUpperCase()
      ) {
        disconnect();
      }
    }
    if (!isConnected) {
      // navigate(PitRouter.CONNECT);
      // disconnect();
    }
  }, [account]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef, selectedNumber]);

  const { tokenInfo, loading: tokenLoading } = useGetTokenInfo(
    selectedItem?.address,
    refresh,
  );

  const ableToPurchase = useMemo(() => {
    return (
      selectedNumber?.toString() === convertedInputAmount &&
      Number(convertedAmount) &&
      Number(tokenInfo?.balanceOf) >=
        Number(formatUnits(convertedAmount, selectedItem?.decimals || 18))
    );
  }, [
    convertedAmount,
    convertedInputAmount,
    tokenInfo?.balanceOf,
    selectedItem,
    selectedNumber,
  ]);

  const ableToPayment = useMemo(() => {
    return Number(selectedNumber) >= 5;
  }, [selectedNumber]);

  const { approve } = useERC20Callback(selectedItem?.address);

  const handleApprove = async () => {
    try {
      if (!contractInfo?.BIGAContractAddress) return;
      setLoading(true);
      setDisabled(true);
      const res = await approve(contractInfo.BIGAContractAddress);
      if (res) {
        toast.success('Approve success!!!');
        setRefresh(!refresh);
      }
    } catch (e) {
      toast.error(e.reason);
      console.log(e);
    } finally {
      setLoading(false);
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (success === 'true' && !modalOpened) {
      setModalOpened(true);
      togglePaymentCheckoutSuccess();
    } else if (success === 'false' && !modalOpened) {
      setModalOpened(true);
      togglePaymentCheckoutError();
    }
  }, [
    success,
    togglePaymentCheckoutSuccess,
    togglePaymentCheckoutError,
    modalOpened,
  ]);

  const handlePaymentCheckoutStripe = async (
    paymentMethod: PaymentMethodEnum,
  ) => {
    const res = await getPaymentCheckoutSessionLink(
      paymentMethod,
      selectedNumber,
    );
    console.log({ res });
    if (res?.success) {
      const { data } = res?.payload;
      if (data?.url) {
        window.location.href = data.url;
      }
    } else {
      toast.error(res.message || 'Something went wrong!');
    }
  };

  return (
    <>
      {/* {account ? ( */}
      <>
        {showConfirm ? (
          <ConfirmPurchase
            onBack={handleBackClick}
            value={selectedNumber}
            confirmData={selectedItem}
            convertData={convertedAmount}
            setDisabled={setDisabled}
          />
        ) : (
          <>
            <CashierRightContentSecondBlock>
              Purchase In
            </CashierRightContentSecondBlock>

            <CashierRightContentThirdBlock>
              <ThirdBlockTitle>Enter purchasing amount</ThirdBlockTitle>
              <ThirdBlockValue
                min="0"
                type="number"
                placeholder="0"
                bordered={false}
                onKeyPress={preventCharacter}
                onChange={e => handleChangeNumber(e.target.value)}
                value={selectedNumber !== null ? selectedNumber.toString() : ''}
                addonAfter={selectedNumber ? 'BIGA' : ''}
                ref={inputRef}
                disabled={loading}
              />
              <ThirdBlockUnit>
                {selectedItem &&
                  formatUnits(
                    selectedNumber ? convertedAmount : '0',
                    selectedItem?.decimals || 18,
                  ) +
                    ' ' +
                    selectedItem?.symbol}
              </ThirdBlockUnit>
              {Boolean(selectedNumber) && !ableToPayment && (
                <ThirdBlockErrorText>Minimum amount is 5</ThirdBlockErrorText>
              )}
            </CashierRightContentThirdBlock>

            <CashierRightContentFourthBlock>
              <FourthBlockContentWrapper>
                <FourthBlockSelectWrapper>
                  <FourthBlockSelectTitle>
                    <Line />
                    <Title>Or select any credits</Title>
                    <Line />
                  </FourthBlockSelectTitle>
                  <FourthBlockSelectButtonWrapper>
                    {numbers.map(number => (
                      <FourthBlockSelectButton
                        key={number}
                        disabled={loading}
                        onClick={() => handleSelectNumber(number)}
                        className={
                          number === selectedNumber ? 'number-active' : ''
                        }
                      >
                        <FourthBlockSelectButtonText>
                          {number}
                        </FourthBlockSelectButtonText>
                      </FourthBlockSelectButton>
                    ))}
                  </FourthBlockSelectButtonWrapper>
                </FourthBlockSelectWrapper>

                <FourthBlockBalanceWrapper>
                  <TokenSelected item={selectedItem} />
                  <FourthBlockBalanceUnit
                    onClick={showSelectTokenModal}
                    disabled={loading}
                  >
                    <FourthBlockBalanceUnitText>
                      {selectedItem?.name || 'Select Token'}
                    </FourthBlockBalanceUnitText>
                    <img src={arrowDownIcon} alt="Arrow Down Icon" />
                  </FourthBlockBalanceUnit>
                </FourthBlockBalanceWrapper>
              </FourthBlockContentWrapper>

              <FourthBlockButtonWrapper>
                {Number(
                  formatUnits(convertedAmount, selectedItem?.decimals || 18),
                ) > Number(tokenInfo?.allowance) &&
                Number(tokenInfo?.balanceOf) >=
                  Number(
                    formatUnits(convertedAmount, selectedItem?.decimals || 18),
                  ) ? (
                  <FourthBlockButton
                    onClick={() => handleApprove()}
                    loading={loading}
                    disabled={
                      !selectedNumber ||
                      selectedNumber <= 0 ||
                      !selectedItem ||
                      tokenInfo?.tokenAddress !== selectedItem?.address ||
                      !ableToPurchase
                    }
                  >
                    Approve
                  </FourthBlockButton>
                ) : (
                  <FourthBlockButton
                    onClick={handleNextClick}
                    disabled={
                      !selectedNumber ||
                      selectedNumber <= 0 ||
                      !selectedItem ||
                      tokenInfo?.tokenAddress !== selectedItem?.address ||
                      !ableToPurchase
                    }
                  >
                    Next
                  </FourthBlockButton>
                )}
                {/* <Tooltip title="Coming soon">
                    <FourthBlockButtonGemini>
                      <FourthBlockButtonTextGemini disabled>
                        <Gemini>
                          <img src={geminiIcon} alt="Gemini Icon" />
                          <div>Purchase with Gemini</div>
                        </Gemini>
                      </FourthBlockButtonTextGemini>
                    </FourthBlockButtonGemini>
                  </Tooltip> */}
                <FourthBlockSelectTitle>
                  <LinePay />
                  <TitlePay>OR</TitlePay>
                  <LinePay />
                </FourthBlockSelectTitle>
                <FourthBlockButtonPayWrapper>
                  <FourthBlockButtonPay
                    onClick={() =>
                      handlePaymentCheckoutStripe(PaymentMethodEnum.STRIPE)
                    }
                    disabled={!ableToPayment}
                  >
                    <img src={stripeIcon} />
                  </FourthBlockButtonPay>
                  <FourthBlockButtonPay
                    onClick={() =>
                      handlePaymentCheckoutStripe(PaymentMethodEnum.PAYPAL)
                    }
                    disabled={!ableToPayment}
                  >
                    <img src={paypalIcon} />
                  </FourthBlockButtonPay>
                  <FourthBlockButtonPay
                    onClick={() =>
                      handlePaymentCheckoutStripe(PaymentMethodEnum.APPLE_PAY)
                    }
                    disabled={!ableToPayment}
                  >
                    <img src={applepayIcon} />
                  </FourthBlockButtonPay>
                </FourthBlockButtonPayWrapper>
              </FourthBlockButtonWrapper>
              <SelectToken
                onItemSelected={value => {
                  setSelectedItem(value);
                  setConvertedAmount('0');
                  setConvertedInputAmount('0');
                }}
              />
              <PaymentSuccessModal />
              <PaymentErrorModal />
            </CashierRightContentFourthBlock>
          </>
        )}
      </>
      {/* ) : (
        <WalletNotConnected />
      )} */}
    </>
  );
};

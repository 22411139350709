import { Form } from 'antd';
import { PitRouter } from 'constants/routers';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { resetPassword } from 'services/api';

import {
  ForgotFirstStepButtonWrapper,
  ForgotStepButton,
  ForgotStepButtonWrapper,
  ForgotStepDescription,
  ForgotStepTitle,
  FormWrapper,
  PasswordInputStyle,
  Wrapper,
} from './styled';

interface IResetPasswordFormProps {
  token: string;
}

export const ResetPasswordForm = (props: IResetPasswordFormProps) => {
  const navigate = useNavigate();

  const [inputPass, setInputPass] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const { token } = props;
      const res: any = await resetPassword({ password: inputPass, token });
      if (res?.success) {
        toast.success('Reset password successfully!');
        navigate(PitRouter.LOGIN);
      } else toast.error(res?.message || '"Token already used!');
    } catch (e) {
      toast.error(e.reason || 'Reset password failed!');
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <ForgotStepTitle>Create new password</ForgotStepTitle>
      <ForgotStepDescription>
        Your new password must be different from previous used passwords.
      </ForgotStepDescription>
      <ForgotFirstStepButtonWrapper>
        <FormWrapper>
          <Form
            className="reset-password-form"
            onFinish={handleSubmit}
            autoComplete="off"
            disabled={loading}
          >
            <Form.Item
              name="password"
              rules={[
                {
                  min: 8,
                  message: 'Password must be minimum 8 characters.',
                },
                {
                  max: 30,
                  message: 'Password must be maximum 30 characters.',
                },
                {
                  validator: (_, value, callback) => {
                    if (value && !new RegExp('[0-9]').test(value)) {
                      callback('Password must contains at least one number.');
                    } else {
                      callback();
                    }
                  },
                },
                {
                  validator: (_, value, callback) => {
                    if (value && !new RegExp('[a-zA-Z]').test(value)) {
                      callback('Password must contains at least one alphabet.');
                    } else {
                      callback();
                    }
                  },
                },
                {
                  validator: (_, value, callback) => {
                    if (value && !new RegExp('[!@#$%^&*()+=_-]').test(value)) {
                      callback(
                        'Password must contain at least one special character \n from the following: !@#$%^&*()+=_-',
                      );
                    } else {
                      callback();
                    }
                  },
                },
                {
                  validator: (_, value, callback) => {
                    if (value && !/^[^<>?,."|~`';:{}[\]/\\]+$/.test(value)) {
                      callback(
                        'Password must not contain special characters \n from the following : <,>.?/|\\~`:;"\'{[]} ',
                      );
                    } else {
                      callback();
                    }
                  },
                },
                {
                  validator: (_, value, callback) => {
                    if (value && new RegExp('\\s+').test(value)) {
                      callback('Password must not contain any white space.');
                    } else {
                      callback();
                    }
                  },
                },
              ]}
              validateFirst
            >
              <PasswordInputStyle
                onChange={e => setInputPass(e.target.value)}
                type="password"
                placeholder="Password"
                required
              />
            </Form.Item>
            <Form.Item
              className="mb-2"
              name="re-pass"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Password doesn’t match'));
                  },
                }),
              ]}
              dependencies={['password']}
            >
              <PasswordInputStyle
                type="password"
                placeholder="Confirm password"
                required
              />
            </Form.Item>
            <ForgotStepButtonWrapper>
              <ForgotStepButton htmlType="submit" loading={loading}>
                Reset password
              </ForgotStepButton>
            </ForgotStepButtonWrapper>
          </Form>
        </FormWrapper>
      </ForgotFirstStepButtonWrapper>
    </Wrapper>
  );
};

export enum AdminStatisticDayFilterType {
  ONE_DAY = '1 day',
  SEVEN_DAYS = '7 days',
  THIRTY_DAYS = '30 days',
  NINETY_DAYS = '90 days',
  ALL_TIME = 'All time',
}

export enum GamerDataDayFilterType {
  TWENTY_FOUR_HOURS = '24 hours',
  FORTY_EIGHT_HOURS = '48 hours',
  ONE_WEEK = '1 week',
  ONE_MONTH = '1 month',
  ALL_TIME = 'All time',
}

export enum TimeType {
  HOURS = 'hours',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

import { Tooltip } from 'antd';
import { IGameActive } from 'hooks/games/useGetGameActive';

import {
  GameRecommentContent,
  GameRecommentContentName,
  GameRecommentContentTag,
  GameRecommentContentTags,
  GameRecommentImg,
  GameRecommentItemWrapper,
} from './styled';

export const GameRecommentItem = (
  props: IGameActive & { showDetail?: () => void },
) => {
  return (
    <GameRecommentItemWrapper>
      <GameRecommentImg
        src={props?.backgroundImgUrl}
        onClick={() => props?.showDetail && props?.showDetail()}
      />

      <GameRecommentContent>
        <Tooltip title={props?.name}>
          <GameRecommentContentName
            onClick={() => props?.showDetail && props?.showDetail()}
          >
            {props?.name}
          </GameRecommentContentName>
        </Tooltip>
        <GameRecommentContentTags>
          <Tooltip title={props?.tags?.join(' / ')}>
            <GameRecommentContentTag>
              {props?.tags?.join(' / ')}
            </GameRecommentContentTag>
          </Tooltip>
        </GameRecommentContentTags>
      </GameRecommentContent>
    </GameRecommentItemWrapper>
  );
};

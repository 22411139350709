import { Spin } from 'antd';
import defaultProfileImg from 'assets/images/default-user-profile.png';
import { formatUnits } from 'ethers/lib/utils';
import { useGetTopEarners } from 'hooks/landingpage/useGetTopEarners';
import { useGetTotalArcadeRevenue } from 'hooks/landingpage/useGetTotalArcadeRevenue';
import { useGetTotalUniquePlayers } from 'hooks/landingpage/useGetTotalUniquePlayers';
import { useState } from 'react';
import { ITime } from 'services/api';

import {
  FirstBlockItem,
  FirstBlockItemTitle,
  FirstBlockItemValue,
  FirstBlockWrapper,
  OrderText,
  OrderWrapper,
  ProfileAvatar,
  ProfileName,
  ProfileWrapper,
  SecondBlockButton,
  SecondBlockButtonWrapper,
  SecondBlockItem,
  SecondBlockItemWrapper,
  SecondBlockLeft,
  SecondBlockRight,
  SecondBlockTitle,
  SecondBlockWrapper,
  TopEarnersWrapper,
} from './styled';

export const TopEarners = () => {
  const [activeButton, setActiveButton] = useState<ITime>({
    timeType: 'AllTime',
  });

  const handleButtonClick = (time: ITime) => {
    setActiveButton(time);
  };

  const { totalUniquePlayers } = useGetTotalUniquePlayers();
  const { totalArcadeRevenue } = useGetTotalArcadeRevenue();
  const { topEarners, loading } = useGetTopEarners(activeButton);

  return (
    <TopEarnersWrapper>
      <FirstBlockWrapper>
        <FirstBlockItem>
          <FirstBlockItemTitle>Unique Players</FirstBlockItemTitle>
          <FirstBlockItemValue>
            {totalUniquePlayers?.toLocaleString() ?? 0}
          </FirstBlockItemValue>
        </FirstBlockItem>
        <FirstBlockItem>
          <FirstBlockItemTitle>Total Revenue</FirstBlockItemTitle>
          <FirstBlockItemValue>
            {'$'}
            {totalArcadeRevenue?.totalEntryFee
              ? (
                  parseFloat(
                    formatUnits(
                      totalArcadeRevenue?.totalEntryFee,
                      totalArcadeRevenue?.decimals || 0,
                    ),
                  ) * parseFloat(totalArcadeRevenue?.tokenPrice || '0')
                ).toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                })
              : 0}
          </FirstBlockItemValue>
        </FirstBlockItem>
      </FirstBlockWrapper>

      <SecondBlockWrapper>
        <SecondBlockTitle>Top 5 Earners</SecondBlockTitle>
        <SecondBlockButtonWrapper>
          <SecondBlockButton
            activated={activeButton.timeType === 'AllTime'}
            onClick={() => handleButtonClick({ timeType: 'AllTime' })}
          >
            All time
          </SecondBlockButton>
          {/* <SecondBlockButton
            activated={activeButton.timeType === 'Month'}
            onClick={() => handleButtonClick({ timeType: 'Month' })}
          >
            Month
          </SecondBlockButton>
          <SecondBlockButton
            activated={activeButton.timeType === 'Day'}
            onClick={() => handleButtonClick({ timeType: 'Day' })}
          >
            Day
          </SecondBlockButton> */}
        </SecondBlockButtonWrapper>
        <SecondBlockItemWrapper>
          <Spin spinning={loading}>
            {topEarners.map((item, index) => (
              <SecondBlockItem key={index}>
                <SecondBlockLeft>
                  <OrderWrapper order={index + 1}>
                    <OrderText>{index + 1}</OrderText>
                  </OrderWrapper>
                  <ProfileWrapper>
                    <ProfileAvatar src={item.avatarURL || defaultProfileImg} />
                    <ProfileName>{item.username}</ProfileName>
                  </ProfileWrapper>
                </SecondBlockLeft>
                <SecondBlockRight>
                  {'$'}
                  {item.accumulatedRevenue.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  }) || 0}
                </SecondBlockRight>
              </SecondBlockItem>
            ))}
          </Spin>
        </SecondBlockItemWrapper>
      </SecondBlockWrapper>
    </TopEarnersWrapper>
  );
};

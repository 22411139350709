import { Button, Form, Input } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  text-align: center;
  background: #fff;
  padding: 2.5rem;
  max-width: 100%;
  width: 28rem;
  margin-bottom: 1rem;
`;
export const WrapperImage = styled.img`
  width: 50px;
  height: 50px;
  margin-top: 3rem;
  margin-bottom: 1rem;
`;

export const SuccessWrapper = styled.div``;

export const ForgotStepTitle = styled.div`
  color: var(--biga-blue);
  text-align: center;
  font-family: 'Changeling Neo';
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 5px;
  max-width: 28rem;
`;

export const ForgotStepDescription = styled.div`
  color: var(--biga-blue);
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  max-width: 28rem;
`;

export const ForgotStepButton = styled(Button)`
  background: var(--biga-blue);

  display: flex;
  width: 100%;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;

  color: #fff;
  text-align: center;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  height: auto;
  outline: 0;
  border: none;
  /* cursor: pointer; */

  &.ant-btn:active:not(:disabled),
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    background: var(--biga-blue);
    outline: 0;
  }
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    color: #fff;
    border: none;
    background: var(--biga-blue);
    outline: 0;
  }
`;

export const ForgotFirstStepWrapper = styled(Wrapper)`
  padding: 4.19rem 1.94rem 3.25rem;
  max-width: 100%;
`;

export const ForgotFirstStepFooter = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
`;

export const ForgotFirstStepButtonWrapper = styled.div`
  margin: 2.5rem 0.5rem 1.5rem;
`;

export const ForgotBackButton = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  margin-bottom: 1.5rem;

  color: #191919;
  text-align: center;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  cursor: pointer;
`;

export const FormWrapper = styled.div`
  margin-top: 2.5rem;
  /* margin-bottom: 1.5rem; */

  .ant-input-affix-wrapper {
    border: 1px solid rgba(0, 0, 0, 0.08);
    background: #fff;
    /* height: 38px; */
    border-radius: 0;
    :focus,
    :active,
    :focus-visible {
      border: 1px solid rgba(0, 0, 0, 0.08);
      box-shadow: none;
    }
    :hover {
      border: 1px solid rgba(0, 0, 0, 0.08);
      box-shadow: none;
    }
  }
  .ant-form-item-explain-error {
    color: #ff3b3b;
    text-align: right;
    font-family: 'Neue Haas Grotesk Display Pro';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    white-space: pre-wrap;
  }

  .ant-form-item {
    margin: 0;
  }
  .ant-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  input:-webkit-autofill:visited {
    -webkit-box-shadow: 0 0 0 35px rgb(245 247 250) inset !important;
    /* -webkit-box-shadow: none !important; */
    /* background-color: #fff !important; */
  }

  .ant-input-status-error:not(.ant-input-disabled):not(
      .ant-input-borderless
    ).ant-input,
  .ant-input-status-error:not(.ant-input-disabled):not(
      .ant-input-borderless
    ).ant-input:hover {
    border: 1px solid rgba(10, 56, 133, 0.08);
    background: rgba(10, 56, 133, 0.04);
    color: #191919;
    box-shadow: none;
  }

  .ant-input-affix-wrapper-status-error:not(
      .ant-input-affix-wrapper-disabled
    ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
  .ant-input-affix-wrapper-status-error:not(
      .ant-input-affix-wrapper-disabled
    ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    border: 1px solid rgba(10, 56, 133, 0.08);
    box-shadow: none;
    outline: none;
    background: rgba(10, 56, 133, 0.04);
    input {
      border: none;
      background: transparent;
    }
  }
`;

export const CustomFormItem = styled(Form.Item)`
  border: none;
`;

export const InputStyle = styled(Input)`
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: #fff;
  /* border-radius: 3.5px; */
  /* font-size: 20px;
  font-weight: 450; */

  color: #191919;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  /* height: 60px; */
  padding: 1.25rem 1rem;
  :focus,
  :active,
  :focus-visible {
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: none;
  }
  :hover {
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: none;
  }
  ::placeholder {
    color: rgba(25, 25, 25, 0.4);
    font-weight: 400;
  }
`;

export const PasswordInputStyle = styled(Input.Password)`
  border: 1px solid rgba(0, 0, 0, 0.08);
  /* border-radius: 3.5px !important;
  font-size: 20px; */
  color: rgba(25, 25, 25, 0.4);
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  box-shadow: none;
  outline: none;

  /* height: 60px !important; */
  padding: 1.025rem 1rem;
  :focus,
  :active,
  :focus-visible {
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: none;
  }
  :hover {
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: none;
  }
  input::placeholder {
    color: rgba(25, 25, 25, 0.4);
    font-weight: 400;
  }
`;

export const TextError = styled.p`
  color: #ff3b3b;
  /* text-align: center; */
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
`;

export const TextErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  & > img {
    cursor: pointer;
  }
`;

export const CenterTextError = styled(TextError)`
  text-align: center;
  white-space: pre-wrap;
`;

export const BackText = styled.div`
  white-space: pre-wrap;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 30px;

  & > .button-link-wrapper {
    flex: 2;
    display: flex;
    /* flex-wrap: wrap; */
  }

  & > .button-link-wrapper:first-of-type {
    justify-content: flex-end;
  }

  & > .button-link-wrapper:last-of-type {
    justify-content: flex-start;
  }

  & > .button-link-wrapper:first-of-type:after,
  & > .button-link-wrapper:last-of-type:before {
    content: '';
    display: inline-block;
  }
`;

export const Line = styled.div`
  width: 1px;
  height: 16px;
  background-color: var(--biga-blue);
`;

export const ForgotStepButtonWrapper = styled.div`
  margin-top: 0.5rem;
`;
export const ForgotSuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  width: 100%;
  gap: 24px;
  padding: 48px 24px;

  & > .email-icon {
    color: var(--biga-blue);
    scale: 3.5;
  }
`;
export const SuccessText = styled.span`
  font-size: 24px;
  font-weight: 600;
  color: var(--biga-blue);
  text-align: center;
`;

export const SuccessDetailText = styled.span`
  color: #000;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
`;

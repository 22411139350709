import { PlayerStatus } from 'constants/enum/PlayerStatus';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loginUser, logoutUser } from 'redux/slices/player';
import { getPlayer } from 'services/api';

export const useGetPlayer = (params: any, refresh?: boolean) => {
  interface IPlayerInfo {
    emailAddress: string;
    username?: string;
    walletAddress?: string;
    avatarURL?: string;
    status: PlayerStatus;
    roles: string[];

    //deprecated
    player: {
      username: string;
      highestWonLevel: any;
      walletAddress: string;
      listOwnedLevel: [];
      createdAt: number;
    };
    isRegistered: boolean;
    isLoggedIn: boolean;
  }
  const [playerInfo, setPlayerInfo] = useState<IPlayerInfo>();
  const [loading, setLoading] = useState(false);
  const [listOwnedLevel, setListOwnedLevel] = useState({});

  const dispatch = useDispatch();
  useEffect(() => {
    void (async () => {
      setLoading(true);

      const res = await getPlayer({});
      if (res?.success) {
        setPlayerInfo(res?.payload?.data);
        dispatch(loginUser(res?.payload?.data));
      } else {
        setPlayerInfo(null);
        // dispatch(logoutUser());
      }
      setLoading(false);
      if (res?.payload?.data?.listOwnedLevel) {
        const list = res.payload.data.listOwnedLevel.map(i => ({ level: i }));
        setListOwnedLevel(list);
      }
    })();
  }, [params, refresh]);

  return { playerInfo, loading, listOwnedLevel };
};

import { ExternalProvider } from '@ethersproject/providers';
import { CHAIN_NETWORK } from 'constants/chains';
import { LocalhostStorage } from 'utils/sessionStorage';

declare global {
  interface Window {
    ethereum: any;
  }
}
/**
 * Prompt the user to add app network as a network on Metamask, or switch to app network if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const setupNetwork = async (externalProvider?: ExternalProvider) => {
  const provider = externalProvider || window.ethereum;
  if (provider) {
    const chainId = parseInt(LocalhostStorage.get('chainId'));

    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId: `0x${chainId?.toString(16)}`,
          },
        ],
      });
      return true;
    } catch (error) {
      try {
        await provider.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: `0x${chainId?.toString(16)}`,
              chainName: CHAIN_NETWORK[chainId].chain_name,
              nativeCurrency: {
                name: CHAIN_NETWORK[chainId].name,
                symbol: CHAIN_NETWORK[chainId].symbol,
                decimals: CHAIN_NETWORK[chainId].decimals,
              },
              rpcUrls: [CHAIN_NETWORK[chainId].node],
              blockExplorerUrls: [`${CHAIN_NETWORK[chainId].base_url}`],
            },
          ],
        });
        return true;
      } catch (error) {
        console.error('Failed to setup the network in Metamask:', error);
        return false;
      }
    }
  } else {
    console.error(
      "Can't setup the app network on metamask because window.ethereum is undefined",
    );
    return false;
  }
};

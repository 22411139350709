import { DatePicker, Empty, Select } from 'antd';
import styled from 'styled-components';

const { RangePicker } = DatePicker;

export const Text = styled.div`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
`;

export const AdminStatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 56px 142px;
`;

export const AdminStatsContentWrapper = styled.div<{
  flexGap?: string;
  justifyContent?: string;
  alignItems?: string;
}>`
  display: flex;
  justify-content: ${({ justifyContent }) =>
    justifyContent || ' space-between'};
  align-items: ${({ flexGap }) => flexGap || 'flex-start'};

  @media (max-width: 1300px) {
    flex-direction: ${({ flexGap }) => (flexGap ? '' : 'column')};
    align-items: center;
    gap: ${({ flexGap }) => flexGap || '40px'};
  }
`;

export const AdminStatsContentTitle = styled(Text)`
  font-size: 24px;
  font-weight: 600;
  width: 30%;
  @media (max-width: 1300px) {
    width: 100%;
    text-align: center;
  }
`;

export const AdminStatsContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  flex: 1;
  max-width: 100%;
  padding: 0px 40px;
  border: 1px solid rgba(255, 255, 255, 0.16);

  @media (max-width: 1300px) {
    width: 100%;
  }
`;

export const AdminStatsContentBlockItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 0px;
  width: 100%;

  @media (max-width: 720px) {
    flex-direction: column;
    gap: 8px;
    flex-wrap: wrap;
  }
`;

export const ChartWrapper = styled.div`
  padding-bottom: 32px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  &:last-child {
    border-bottom: none;
  }
`;

export const AdminStatsContentItemTitle = styled(Text)`
  font-size: 16px;
  font-weight: 500;
`;

export const AdminStatsContentItemValue = styled(Text)`
  font-size: 16px;
  font-weight: 600;
`;

export const TimePickerWrapper = styled(RangePicker)`
  height: 39px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.16);

  .ant-picker-clear {
    color: #fff;
    background-color: rgba(50, 88, 153, 1);
    border-radius: 50%;
  }

  .ant-picker-input > input {
    text-align: center;
    color: #fff;
  }

  .ant-picker-range-separator {
    color: #fff;
  }

  .ant-picker-suffix {
    color: #fff;
  }

  .anticon svg {
    color: #fff;
  }

  :hover {
    .ant-picker-suffix {
      opacity: 0;
    }
  }
`;

export const ToolTipChartWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  background-color: #fff;
  padding: 8px 16px 16px 16px;
`;

export const ToolTipProfile = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const ToolTipProfileAvatar = styled.img`
  width: 28px;
  height: 28px;
  border-radius: 50%;
`;

export const ToolTipProfileName = styled(Text)`
  font-size: 14px;
  font-weight: 600;
  color: #000;
`;

export const ToolTipText = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  color: #000;
`;

export const SelectDaysFilter = styled(Select)`
  border: 1px solid rgba(255, 255, 255, 0.9);
  /* border-radius: 6.5px; */
  color: #0b3885;
  font-size: 17px;
  font-weight: 600;
  height: 38px;
  width: 140px;
  text-align: center;
  background: rgba(255, 255, 255, 0.9);
  margin-left: 20px;

  .ant-select-selector {
    .ant-select-selection-item {
      padding-right: 0;
      font-size: 15px;
    }
  }

  @media (max-width: 640px) {
    margin-top: 10px;
    width: 150px;
  }

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    display: flex;
    align-items: center;
    height: 38px;
    border: none;
    background-color: rgba(243, 244, 246, 1);
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background: transparent;
    color: white;
    padding: 0 36px 0 13px;
  }

  &.ant-select-dropdown.ant-select-dropdown-empty {
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
  }

  &.ant-select {
    border: 1px solid rgba(50, 88, 153, 1);
    background: none;

    :hover {
      border: 1px solid rgba(64, 169, 255, 1);
    }

    & .ant-select-arrow {
      svg path {
        fill: white;
      }
    }
  }
`;

export const EmptyWrapper = styled(Empty)`
  color: #fff;
`;

import { CloseCircleOutlined } from '@ant-design/icons';
import { Modal, UploadFile, UploadProps } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import dragIcon from 'assets/vectors/login-drag-pan.svg';
import { GaActions, GaCategories, GaLabels } from 'constants/enum/GaEvent';
import { useEffect, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import ReactGA from 'react-ga4';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { loginUser } from 'redux/slices/player';
import { useAppSelector } from 'redux/store';
import { setupProfile } from 'services/api';
import { dataURLToBlob } from 'utils/image';

import {
  CustomFormItem,
  RegisterSetupProfileForm,
  RegisterSetupProfileHeadingWrapper,
  RegisterSetupProfileInput,
  RegisterSetupProfileWrapper,
  RegisterStepButton,
  RegisterStepDescription,
  RegisterStepTitle,
  RegisterUploadedImageFrame,
  RegisterUploadProfileContainer,
  RegisterUploadProfileDragAndDrop,
  RegisterUploadProfileDragger,
} from './styled';

interface IProps {
  onContinue: () => void;
}

// 1MB
const MAX_AVATAR_SIZE = 1024 * 1024;

export const RegisterSetupProfile = ({ onContinue }: IProps) => {
  const [avatar, setAvatar] = useState();
  const [previewImage, setPreviewImage] = useState('');
  const [savedImage, setSavedImage] = useState('');
  const inputRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isUsernameEmpty, setIsUsernameEmpty] = useState(true);

  const [form] = useForm();

  const getBase64 = (file: any): Promise<string | null | ArrayBuffer> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => resolve(e.target ? e.target.result : null);
      reader.onerror = error => reject(error);
    });
  };

  const uploadProps: UploadProps = {
    name: 'avatar',
    multiple: false,
    // onRemove: async file => {
    //   setAvatar(undefined);
    // },
    beforeUpload: async (_, fileList) => {
      handleAvatar();

      const files = [];
      for (const file of fileList) {
        const slip = file as UploadFile;
        if (slip && !slip.url && !slip.preview) {
          const preview = (await getBase64(slip))?.toString() || '';
          slip.preview = preview;
          slip.url = preview;
          slip.thumbUrl = preview;
          setPreviewImage(preview);
        }
        files.push(slip);
      }
      setAvatar(files[0]);
      form.setFieldValue('avatar', files);
      await form.validateFields();
      return false;
    },
    fileList: avatar ? [avatar] : [],
    accept: 'image/jpg,image/jpeg,image/png,image/JPG,image/JPEG,image/PNG',
    onPreview: async (file: any) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file);
      }

      setPreviewImage(file.preview);
      //   setPreviewVisible(true);
    },
    showUploadList: false,
    maxCount: 1,
  };

  const dispatch = useDispatch();
  const playerInfo = useAppSelector(state => state.player.playerInfo);
  const handleSubmit = async () => {
    try {
      setLoading(true);

      ReactGA.event({
        category: GaCategories.SIGNUP,
        action: GaActions.ACTION_SIGNUP_SETUP_PROFILE,
        label: GaLabels.LABEL_SIGNUP_SETUP_PROFILE,
      });

      const values = await form.validateFields();
      const { username } = values;
      let croppedAvatar;

      if (avatar) {
        const blob = dataURLToBlob(savedImage);
        const { type, name, uid } = avatar as UploadFile;
        croppedAvatar = new File([blob as BlobPart], name, { type });
        Object.assign(croppedAvatar, { uid });
      }

      const formData = new FormData();
      formData.append('username', username);
      if (croppedAvatar) {
        formData.append('avatar', croppedAvatar as any);
      }

      const res = await setupProfile(formData);
      if (res?.success) {
        dispatch(loginUser({ ...playerInfo, ...(res?.payload?.data || {}) }));
        onContinue();
      } else {
        toast.error(res?.message || 'Setup Profile Failed');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = e => {
    if (avatar && !savedImage) {
      e.stopPropagation();
      setVisible(true);
    }
  };

  useEffect(() => {
    if (avatar && !visible) {
      setVisible(true);
    }
  }, [avatar]);

  const handleCancel = () => {
    if (avatar && !savedImage) {
      handleAvatar();
    }
    setVisible(false);
  };

  const handleSave = () => {
    const canvasScaled = inputRef.current.getImageScaledToCanvas();
    const dataURL = canvasScaled.toDataURL();
    // setPreviewImage(dataURL);
    setSavedImage(dataURL);

    setVisible(false);
  };

  const handleAvatar = () => {
    setAvatar(undefined);
    setPreviewImage(undefined);
    setSavedImage(undefined);
    form.setFieldValue('avatar', []);
    form.validateFields();
  };
  return (
    <RegisterSetupProfileWrapper>
      <RegisterSetupProfileHeadingWrapper>
        <RegisterStepTitle>Profile setup</RegisterStepTitle>
        <RegisterStepDescription>
          Please add images and username to complete your profile setup
        </RegisterStepDescription>
      </RegisterSetupProfileHeadingWrapper>

      <RegisterSetupProfileForm
        // className="register-form"
        onFinish={handleSubmit}
        //   initialValues={{ remember: true }}
        autoComplete="off"
        form={form}
      >
        <CustomFormItem
          name="username"
          rules={[
            {
              required: true,
              message: 'Please enter your username',
            },
            {
              min: 6,
              message: 'Username must be minimum 6 characters',
            },
            {
              max: 20,
              message: 'Username has a maximum of 20 characters',
            },
            {
              pattern: new RegExp('^[A-Za-z]*[A-Za-z][a-zA-Z0-9_.]{5,19}$'),
              message:
                'Username must start with a letter. Allowed \ncharacters are a-z (both lower case and upper case), \n0-9, _(underscore), and .(dot)',
            },
          ]}
          validateFirst={true}
        >
          <RegisterSetupProfileInput
            //   onChange={e => setUsername(e.target.value)}
            required
            placeholder="Please enter a username"
            disabled={loading}
            prefix={<span style={{ color: 'red' }}>*</span>}
            onChange={e => {
              form.setFieldsValue({ username: e.target.value });
              setIsUsernameEmpty(e.target.value === '');
            }}
          />
        </CustomFormItem>

        <CustomFormItem
          name={'avatar'}
          rules={[
            {
              validator: (_, value, callback) => {
                if (value?.file?.size > MAX_AVATAR_SIZE) {
                  callback('Image must be less than 1MB');
                } else {
                  callback();
                }
              },
            },
          ]}
          validateFirst={true}
        >
          <RegisterUploadProfileDragger {...uploadProps}>
            <RegisterUploadProfileContainer>
              {avatar ? (
                <RegisterUploadedImageFrame
                  onClick={handleEditClick}
                  style={{
                    backgroundImage: `url(${savedImage})`,
                  }}
                >
                  <CloseCircleOutlined
                    onClick={e => {
                      e.stopPropagation();
                      handleAvatar();
                    }}
                  />
                </RegisterUploadedImageFrame>
              ) : (
                <RegisterUploadProfileDragAndDrop onClick={handleEditClick}>
                  <img src={dragIcon} alt="drag" />
                  <div>Drag and drop</div>
                </RegisterUploadProfileDragAndDrop>
              )}

              <div>Image size 100 x 100, less than 1 MB</div>
            </RegisterUploadProfileContainer>
          </RegisterUploadProfileDragger>
        </CustomFormItem>
        <Modal
          title="Edit Avatar"
          visible={visible}
          onCancel={handleCancel}
          onOk={handleSave}
          okText="Save"
          cancelText="Cancel"
          bodyStyle={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <AvatarEditor
            ref={inputRef}
            image={savedImage || previewImage}
            width={100}
            height={100}
            border={50}
            color={[255, 255, 255, 0.6]}
            scale={1}
            rotate={0}
          />
        </Modal>

        <RegisterStepButton
          htmlType="submit"
          loading={loading}
          disabled={loading || isUsernameEmpty}
        >
          Complete Signup
        </RegisterStepButton>
      </RegisterSetupProfileForm>
    </RegisterSetupProfileWrapper>
  );
};

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { ActionButton } from 'components/Button/styled';
import { LevelFeeTable } from 'components/Table/LevelFeeTable';
import { formatUnits } from 'ethers/lib/utils';
import { useGetGameInfo } from 'hooks/games/useGetGameInfo';
import { useState } from 'react';
import { ApplicationModal } from 'redux/slices/application';
import { useModalIsOpen, useToggleModal } from 'redux/slices/application/hook';

import { LevelFeeFormModal } from '../LevelFeeForm';
import { ThemedModal } from '../styled';
import {
  BackButton,
  HeaderContainer,
  HeaderTitle,
  StyledTitle,
} from './styled';

export const ConfigLevelFeeModal = ({
  gameId,
  refresh: refreshGameInfo,
}: {
  gameId: string;
  refresh?: boolean;
}) => {
  const isOpen = useModalIsOpen(ApplicationModal.CONFIG_LEVEL_FEE);
  const gameDetailToggle = useToggleModal(ApplicationModal.GAME_DETAILS);
  const setFeeToggle = useToggleModal(ApplicationModal.SET_LEVEL_FEE_FORM);
  const configFeeToggle = useToggleModal(ApplicationModal.CONFIG_LEVEL_FEE);

  const [refresh, setRefresh] = useState<boolean>(false);
  const { gameInfo } = useGetGameInfo(gameId, refreshGameInfo);

  const onCloseModal = () => {
    configFeeToggle();
    // form.resetFields();
  };

  return (
    <ThemedModal
      title={
        <HeaderContainer>
          <BackButton onClick={gameDetailToggle}>
            <ArrowLeftOutlined />
          </BackButton>
          <HeaderTitle>Configure level</HeaderTitle>
        </HeaderContainer>
      }
      open={isOpen}
      onCancel={onCloseModal}
      footer={false}
      width={870}
    >
      <StyledTitle>
        Default entry fee:{' '}
        {gameInfo
          ? `${formatUnits(
              gameInfo.defaultEntryFee,
              gameInfo.tokenInfo.decimals,
            )} ${gameInfo.tokenInfo.symbol}`
          : 0}
      </StyledTitle>

      <LevelFeeTable gameId={gameId} refresh={refresh}></LevelFeeTable>

      <Row style={{ justifyContent: 'center' }}>
        <Col>
          <ActionButton dark={'true'} onClick={() => setFeeToggle()}>
            Set level
          </ActionButton>
        </Col>
      </Row>
      <LevelFeeFormModal
        gameId={gameId}
        defaultEntryFee={gameInfo?.defaultEntryFee}
        tokenAddress={gameInfo?.tokenAddress}
        tokenSymbol={gameInfo?.tokenInfo?.symbol}
        decimals={gameInfo?.tokenInfo?.decimals}
        onRefresh={() => {
          setRefresh(!refresh);
        }}
      />
    </ThemedModal>
  );
};

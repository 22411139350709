import { Modal } from 'antd';
import styled from 'styled-components';

export const Text = styled.div`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
`;

export const ModalWrapper = styled(Modal)`
  & > .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
    .ant-modal-body {
      padding: 0;
    }
  }
`;

export const CloseWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  user-select: none;
`;

export const CloseIcon = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

export const ModalLevelMasterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ModalLevelMasterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ModalLevelMasterHeader = styled.div`
  display: flex;
  padding: 25px 83.5px 24px;
  justify-content: center;
  align-items: center;
  background: var(--biga-blue);
`;

export const HeaderTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeaderFirstText = styled(Text)`
  color: #ffd700;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
`;

export const HeaderSecondText = styled.div`
  color: #fff;
  text-align: center;
  font-family: Changeling Neo;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
`;

export const ModalLevelMasterBody = styled.div`
  display: flex;
  width: 100%;
  padding: 24px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  background: #fff;
`;

export const BodyTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 24px;
`;

export const BodyText = styled.div`
  color: #000;
  text-align: center;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0;
`;

export const BodyButtonWrapper = styled.div`
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const Line = styled.div`
  width: 1px;
  height: 32px;
  transform: skew(-10deg);
  background-color: var(--biga-blue, #0a3885);
`;

export const ButtonText = styled.div`
  cursor: pointer;

  color: var(--biga-blue, #0a3885);
  text-align: center;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const BodyAccountWrapper = styled.div`
  cursor: pointer;

  color: var(--biga-blue);
  text-align: center;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`;

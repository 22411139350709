import { useState } from 'react';
import { useDebounce } from 'react-use';
import { checkGamePathAvailable } from 'services/api';

export const useCheckGamePathAvailable = (path: string, refresh?: boolean) => {
  const [isAvailable, setIsAvailable] = useState<{
    isAvailable: boolean;
  }>({
    isAvailable: false,
  });
  const [checkedValue, setCheckedValue] = useState<string>('');
  const [loading, setLoading] = useState(false);
  useDebounce(
    () => {
      void (async () => {
        if (path) {
          setLoading(true);
          const res = await checkGamePathAvailable(path);
          setCheckedValue(path);
          if (res?.success) {
            setIsAvailable({
              isAvailable: res?.payload?.data,
            });
          } else {
            setIsAvailable(null);
          }
          setLoading(false);
        }
      })();
    },
    500,
    [path, refresh],
  );
  return { isAvailable, loading, checkedValue };
};

import { DeleteOutlined, UserAddOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import defaultAvatarImg from 'assets/images/default-user-profile.png';
import { DeleteThemedModal } from 'components/Modals/styled';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { ApplicationModal } from 'redux/slices/application';
import { useToggleModal } from 'redux/slices/application/hook';

import { AddTesterModal } from 'components/Modals/AdminPanel/AddTesterModal';
import { useGetTesters } from 'hooks/tester/useGetTesters';
import { removeTester } from 'services/api';
import {
  AdminPanelTable,
  BodyContentWrapper,
  HeaderContentItemDetail,
  HeaderContentItemValue,
  HeaderContentItemWrapper,
  HeaderContentWrapper,
  StyledPagination,
  TableContentWrapper,
  UserCellWrapper,
} from './styled';

export const TesterTable = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [params, setParams] = useState({ pageSize: 6, pageIndex: 1 });
  const [refresh, setRefresh] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [confirmVisible, setConfirmVisible] = useState<boolean>(false);
  const [confirmIndex, setConfirmIndex] = useState<number>(null);

  const {
    loading: testerLoading,
    testers,
    total,
  } = useGetTesters(params, refresh);

  const handleDelete = async () => {
    setConfirmVisible(false);

    const removeRes = await removeTester({
      username: testers[confirmIndex].username,
    });
    if (removeRes?.success) {
      toast.success('You have successfully removed tester');
      setRefresh(!refresh);
    } else toast.error('Remove tester failed!!');

    setLoading(false);
    setConfirmIndex(null);
  };

  const columns = [
    {
      title: '',
      dataIndex: 'key',
      key: 'key',
      render: value => params.pageSize * (currentPage - 1) + value + 1,
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      render: (username, record) => (
        <UserCellWrapper>
          <img src={record?.avatarURL || defaultAvatarImg} />
          <div>{username}</div>
        </UserCellWrapper>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record, index) => (
        <DeleteOutlined
          onClick={() => {
            setLoading(true);
            setConfirmIndex(index);
            setConfirmVisible(true);
          }}
          disabled={false}
          style={{ fontSize: '20px', cursor: 'pointer', color: '#ff525b' }}
        />
      ),
    },
  ];

  const addTesterToggle = useToggleModal(ApplicationModal.ADD_TESTER);

  return (
    <>
      <TableContentWrapper>
        <HeaderContentWrapper>
          <HeaderContentItemWrapper>
            <HeaderContentItemDetail>Amount of Testers</HeaderContentItemDetail>
            <HeaderContentItemValue>{total}</HeaderContentItemValue>
          </HeaderContentItemWrapper>
          <HeaderContentItemWrapper>
            <HeaderContentItemDetail>Add Tester</HeaderContentItemDetail>
            <UserAddOutlined
              style={{ fontSize: '24px', cursor: 'pointer' }}
              onClick={() => {
                addTesterToggle();
              }}
            />
            <AddTesterModal
              onRefresh={() => {
                setRefresh(!refresh);
              }}
            />
          </HeaderContentItemWrapper>
        </HeaderContentWrapper>
        <BodyContentWrapper>
          <AdminPanelTable
            dataSource={testers?.map((item, key) => ({ ...item, key }))}
            columns={columns}
            pagination={false}
            loading={testerLoading}
          />
          <Row justify="center" className="mb-5">
            {total > 6 && (
              <StyledPagination
                className="mt-4"
                pageSize={6}
                current={currentPage}
                onChange={page => {
                  setCurrentPage(page);
                  setParams({
                    ...params,
                    pageIndex: page,
                  });
                }}
                total={total}
                showSizeChanger={false}
              />
            )}
          </Row>
          <DeleteThemedModal
            visible={confirmVisible}
            onOk={handleDelete}
            onCancel={() => {
              setLoading(false);
              setConfirmVisible(false);
              setConfirmIndex(null);
            }}
            okText={'Delete'}
            cancelText="No"
            width={400}
            style={{ marginTop: '50px' }}
          >
            {confirmIndex !== null && (
              <p>
                Do you want to delete <b>{testers[confirmIndex]?.username}</b>{' '}
                from the Tester list?
              </p>
            )}
          </DeleteThemedModal>
        </BodyContentWrapper>
      </TableContentWrapper>
    </>
  );
};

import styled from 'styled-components';

export const FooterWrapper = styled.div`
  height: 90px;
  width: 100%;
  display: flex;
`;

export const FooterContent = styled.div<{ param?: string }>`
  align-items: center;
  color: ${props => (props.param === 'white' ? '#0b3885' : '#fff')};
  display: flex;
  justify-content: center;
  width: 100%;

  font-family: 'Changeling Neo';
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
`;

export const SideColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  width: 68px;
`;

import styled from 'styled-components';

export const Text = styled.div`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
`;

export const Image = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;

export const FourthBlockBalanceContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  & > img {
    user-select: none;
  }
`;

export const FourthBlockBalanceContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const FourthBlockBalanceContentValue = styled(Text)`
  color: #000;
  font-size: 16px;
  font-weight: 600;
`;

export const FourthBlockBalanceContentTitle = styled(Text)`
  color: #000;
  font-size: 12px;
  font-weight: 500;
  opacity: 0.6;
`;

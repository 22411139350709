import { Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import loadmoreicon from 'assets/icons/loadmore.svg';
import defaultProfileImg from 'assets/images/default-user-profile.png';
import { GameScoringModelType } from 'constants/enum/GameScoringModelType';
import {
  getScoringModels,
  ScoringModelLeaderboardTypes,
  ScoringModels,
} from 'constants/enum/ScoringModel';
import { formatUnits } from 'ethers/lib/utils';
import { IGameActive } from 'hooks/games/useGetGameActive';
import { useGetGameLeaderboard } from 'hooks/leaderboard/useGetGameLeaderboard';
import {
  IGameLevel,
  useGetGameLevelLeaderboard,
} from 'hooks/leaderboard/useGetGameLevel';
import {
  IGameScore,
  useGetGameScoreLeaderboard,
} from 'hooks/leaderboard/useGetGameScore';
import { useEffect, useState } from 'react';

import {
  HighlightCurrentOwner,
  LeaderboardScoreTable,
  LeaderboardTableStyled,
  LeaderBoardTableWrapper,
  LoadMoreButtonWrapper,
  LoadMoreContentWrapper,
  LoadMoreIcon,
  LoadMoreText,
  OwnerAvatar,
  OwnerInfoWrapper,
  OwnerName,
  OwnerWrapper,
} from './styled';

interface ILoadingGameProps {
  isMinimized?: boolean;
  tab?: string;
  selectedGameId?: string;
  gameInfo?: IGameActive;
  selectedLevel?: number;
  minLevel?: number;
  isScroll?: boolean;
}
export default function LeaderboardTable({
  tab,
  selectedGameId,
  gameInfo,
  isMinimized: minimized,
  selectedLevel,
  minLevel,
  isScroll,
}: ILoadingGameProps) {
  const [params, setParams] = useState({
    pageSize: 10,
    pageIndex: 1,
    gameId: selectedGameId,
    sortField: undefined,
    sortDirection: undefined,
    level: undefined,
  });

  useEffect(() => {
    const newParams = {
      ...params,
      pageIndex: 1,
      sortField: undefined,
      sortDirection: undefined,
      level: undefined,
      gameId: selectedGameId,
    };
    if (tab === GameScoringModelType.level) {
      setParams({ ...newParams });
    } else {
      setParams({
        ...newParams,
        level: selectedLevel || minLevel,
      });
    }
  }, [selectedGameId, selectedLevel, minLevel, tab]);

  const gameScoringModels = getScoringModels(gameInfo?.scoringModel);

  const {
    gameLevel: gameLevelData,
    gameScore: gameScoreData,
    loading,
    totalCount,
  } = useGetGameLeaderboard(params, gameScoringModels);

  const handleLevelTableChange = (pagination, filters, sorter) => {
    setParams({
      ...params,
      pageIndex: 1,
      sortField: sorter.order ? sorter.field : undefined,
      sortDirection: sorter.order
        ? sorter.order === 'descend'
          ? 'desc'
          : 'asc'
        : undefined,
    });
  };

  const handleScoreTableChange = (pagination, filters, sorter) => {
    setParams({
      ...params,
      pageIndex: 1,
      sortField: sorter.order
        ? sorter.field === 'player'
          ? 'playerName'
          : sorter.field
        : undefined,
      sortDirection: sorter.order
        ? sorter.order === 'descend'
          ? 'desc'
          : 'asc'
        : undefined,
    });
  };

  const getSortOrder = field => {
    return field === params.sortField
      ? params.sortDirection === 'asc'
        ? 'ascend'
        : 'descend'
      : null;
  };

  const levelColumns: ColumnsType<IGameLevel> = [
    {
      title: 'Level',
      dataIndex: 'level',
      sorter: true,
      sortOrder: getSortOrder('level'),
      render: (_, gameLevel) => gameLevel.levelName || gameLevel.level,
    },
    {
      title: 'Plays',
      dataIndex: 'playsAmount',
      sorter: true,
      sortOrder: getSortOrder('playsAmount'),
      render: value => value.toLocaleString(),
    },
    {
      title: 'Level revenue',
      dataIndex: 'totalOfRevenue',
      sorter: true,
      sortOrder: getSortOrder('totalOfRevenue'),
      render: (value, record) => {
        const formattedValue = (
          parseFloat(
            formatUnits(value || 0, gameInfo?.tokenInfo?.decimals || 18),
          ) / 10
        ).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });

        return Number(record?.levelFee) === 0 ? (
          <span>
            ${formattedValue}{' '}
            <Tooltip title="This is a free to play practice levels. To earn money try level that with BIGA credits">
              <b>(F2P)</b>
            </Tooltip>
          </span>
        ) : (
          <span>${formattedValue}</span>
        );
      },
    },
    {
      title: 'Owner',
      dataIndex: 'player',
      key: 'player',
      render: player => {
        return player.username ? (
          <OwnerWrapper>
            <OwnerAvatar src={player.avatarURL || defaultProfileImg} />
            <OwnerInfoWrapper>
              <OwnerName>{player.username}</OwnerName>
            </OwnerInfoWrapper>
          </OwnerWrapper>
        ) : (
          <span>N/A</span>
        );
      },
      className: 'column-align-left',
    },
    {
      title: 'Owner revenue',
      dataIndex: 'revenueOfCurrentOwner',
      sorter: true,
      sortOrder: getSortOrder('revenueOfCurrentOwner'),
      render: (value, record) => {
        const formattedValue = (
          parseFloat(
            formatUnits(value || 0, gameInfo?.tokenInfo?.decimals || 18),
          ) / 10
        ).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        return record.hasOwner || !value || Number(value) === 0 ? (
          `$${formattedValue}`
        ) : (
          <Tooltip title="These funds are available for the first person to claim">
            <HighlightCurrentOwner>${formattedValue}</HighlightCurrentOwner>
          </Tooltip>
        );
      },
    },
  ];

  const scoreColumns: ColumnsType<IGameScore> = [
    {
      title: 'Rank',
      dataIndex: 'rank',
      sorter: true,
      sortOrder: getSortOrder('rank'),
      render: rank => <span className="circle">{rank + 1}</span>,
    },
    {
      title: 'Name',
      dataIndex: 'player',
      key: 'player',
      render: player => (
        <OwnerWrapper>
          <OwnerAvatar src={player.avatarURL || defaultProfileImg} />
          <OwnerInfoWrapper>
            <OwnerName>{player.username}</OwnerName>
            {/* <OwnerDate>{player.yearOfBirth}</OwnerDate> */}
          </OwnerInfoWrapper>
        </OwnerWrapper>
      ),
      sorter: true,
      sortOrder: getSortOrder('playerName'),
      className: 'column-align-left',
    },
    {
      title: 'Attempts',
      dataIndex: 'playsAmount',
      render: value => value.toLocaleString(),
    },
    {
      title: 'Best Score',
      dataIndex: 'score',
      sorter: true,
      sortOrder: getSortOrder('score'),
      render: value => {
        return gameInfo?.scoringModel === ScoringModels.LOWEST_TIME
          ? value.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : value.toLocaleString();
      },
    },
    {
      title: 'Total Score',
      dataIndex: 'totalScore',
      sorter: true,
      sortOrder: getSortOrder('totalScore'),
      render: value => {
        return gameInfo?.scoringModel === ScoringModels.LOWEST_TIME
          ? value.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : value.toLocaleString();
      },
    },
    {
      title: 'Revenue',
      dataIndex: 'totalOfRevenue',
      sorter: true,
      sortOrder: getSortOrder('totalOfRevenue'),
      render: (value, record) => {
        const formattedValue = (
          parseFloat(
            formatUnits(value || 0, gameInfo?.tokenInfo?.decimals || 18),
          ) / 10
        ).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        return Number(record?.levelFee) === 0 ? (
          <span>
            ${formattedValue}{' '}
            <Tooltip title="This is a free to play practice levels. To earn money try level that with BIGA credits">
              <b>(F2P)</b>
            </Tooltip>
          </span>
        ) : (
          <span>${formattedValue}</span>
        );
      },
    },
  ];

  const minimizedLevelBase = columns => {
    const levelColumn = columns.find(column => column.title === 'Level');
    const ownerColumn = columns.find(column => column.title === 'Owner');

    return [levelColumn, ownerColumn];
  };

  const minimizedScoreBase = columns => columns.slice(0, 2);

  if (
    tab === GameScoringModelType.level &&
    gameInfo?.scoringModel === ScoringModels.LEVEL_PASS_HIGHEST_SCORE
  ) {
    levelColumns.push({
      title: 'Highest score',
      dataIndex: 'score',
      render: value => (typeof value === 'undefined' ? 'N/A' : value),
    });
  }

  if (
    tab === GameScoringModelType.level &&
    gameInfo?.scoringModel === ScoringModels.LEVEL_PASS_LOWEST_TIME
  ) {
    levelColumns.push({
      title: 'Lowest time',
      dataIndex: 'score',
      render: value =>
        typeof value === 'undefined' ? 'N/A' : value.toFixed(2),
    });
  }

  return (
    <LeaderBoardTableWrapper>
      {tab === GameScoringModelType.level ? (
        <LeaderboardTableStyled
          columns={minimized ? minimizedLevelBase(levelColumns) : levelColumns}
          dataSource={gameLevelData || []}
          pagination={false}
          onChange={handleLevelTableChange}
          loading={loading}
          scroll={isScroll ? { y: 300, x: 800 } : { y: 300 }}
          hasLoadMore={
            tab === GameScoringModelType.level
              ? gameLevelData?.length < totalCount
              : gameScoreData?.length < totalCount
          }
        />
      ) : (
        <LeaderboardScoreTable
          columns={minimized ? minimizedScoreBase(scoreColumns) : scoreColumns}
          dataSource={gameScoreData || []}
          pagination={false}
          onChange={handleScoreTableChange}
          loading={loading}
          scroll={isScroll ? { y: 300, x: 800 } : { y: 300 }}
          firstRankIndex={
            gameScoreData?.findIndex(item => item?.rank === 0) + 1
          }
          secondRankIndex={
            gameScoreData?.findIndex(item => item?.rank === 1) + 1
          }
          thirdRankIndex={
            gameScoreData?.findIndex(item => item?.rank === 2) + 1
          }
        />
      )}
      {(tab === GameScoringModelType.level
        ? gameLevelData?.length < totalCount
        : gameScoreData?.length < totalCount) && (
        <LoadMoreButtonWrapper className="load-more-button">
          <LoadMoreContentWrapper
            onClick={() => {
              setParams({
                ...params,
                pageIndex: params.pageIndex + 1,
              });
            }}
          >
            <LoadMoreText>Load more</LoadMoreText>
            <LoadMoreIcon src={loadmoreicon} />
          </LoadMoreContentWrapper>
        </LoadMoreButtonWrapper>
      )}
    </LeaderBoardTableWrapper>
  );
}

import styled from 'styled-components';

export const WalletNotConnectedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin: 100px 153px 202px;
  width: 142px;
`;

export const WalletNotConnectedText = styled.div`
  color: #000;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  text-align: center;
  line-height: normal;
  font-family: Neue Haas Grotesk Display Pro;
`;

export const truncateText = (
  text: string,
  startLength: number,
  endLength: number,
  maxLength = 0,
) => {
  const leng = Math.max(maxLength, startLength + endLength + 3);
  if (text?.length > leng) {
    let first = text?.substring(0, startLength);
    const second = text?.substring(text?.length - endLength, text?.length);
    while (first.length + second.length < leng) first = first + '.';
    return first + second;
  }
  return text;
};

export const returnSuccess = payload => {
  return { success: true, data: payload };
};

export const returnError = payload => {
  return { success: false, data: payload };
};

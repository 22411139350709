import { ReactComponent as LogoLeaderboard } from 'assets/vectors/logo-navbar-leaderboard.svg';
import { ReactComponent as LogoClosed } from 'assets/vectors/logo-play-game-close.svg';
import { ReactComponent as LogoExpanded } from 'assets/vectors/logo-play-game-expand.svg';
import styled from 'styled-components';

export const PlayGameContainer = styled.div`
  gap: 64px;
  margin: 30px 142px 200px 142px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  align-items: center;
`;

export const GameDetailWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  gap: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const GameDetailContentWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  /* gap: 32px; */
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const GameDetailContentTitleWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const GameDetailContentMainWrapper = styled.iframe`
  aspect-ratio: 16/9;
  width: 68vw;
  height: 100vh;
`;

export const GameDetailStatisticWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.24);

  @media (max-width: 1180px) {
    flex-direction: column;
  }
`;

export const GameDetailStatisticItem = styled.div`
  gap: 8px;
  width: 100%;
  max-width: 100%;
  padding: 16px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-right: 1px solid rgba(255, 255, 255, 0.24);
  &:last-child {
    border-right: none;
  }
`;

export const GameDetailStatisticItemTitle = styled.div`
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  font-family: Neue Haas Grotesk Display Pro;
`;

export const GameDetailStatisticItemValue = styled.div`
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
  font-family: Changeling Neo;
`;

export const GameRecommentWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  gap: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const GameRecommentText = styled.div`
  color: #fff;
  font-size: 28px;
  font-weight: 701;
  font-style: normal;
  text-align: center;
  line-height: normal;
  text-transform: uppercase;
  font-family: Changeling Neo;
`;

export const GameRecommentList = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

export const GameTitleStyled = styled.p`
  color: #fff;
  font-size: 40px;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
  font-family: Changeling Neo;
  margin: 0;
`;

export const LeaderboardButton = styled(LogoLeaderboard)`
  width: 32px;
  height: 32px;
  padding: 4px;
  background: #fff;
  cursor: pointer;
  user-select: none;
  outline: 8px solid rgba(255, 255, 255, 0.08);
`;

export const WarningText = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  font-family: Neue Haas Grotesk Display Pro;
  color: #fff;
`;

export const DropdownWrapper = styled.div`
  width: 380px;
  max-width: 100%;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .ant-table-tbody tr:nth-child(even) td {
    background-color: #f3f4f6;
  }

  .load-more-button {
    border-radius: 0 0 8px 8px;
  }
`;

export const DropdownHeaderWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background: #f4f5f6;
  border-radius: 8px 8px 0 0;
`;

export const CloseButton = styled(LogoClosed)`
  width: 24px;
  height: 24px;
  padding: 5px;
  background: #f4f5f6;
  cursor: pointer;
  user-select: none;
`;

export const ExpandWrapper = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

export const ExpandButton = styled(LogoExpanded)`
  width: 24px;
  height: 24px;
  padding: 5px;
  background: #f4f5f6;
`;

export const ExpandText = styled.div`
  color: #000;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  font-family: Neue Haas Grotesk Display Pro;
`;

import {
  CopyOutlined,
  DeleteOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import { Row, Tooltip } from 'antd';
import defaultAvatarImg from 'assets/images/default-user-profile.png';
import { AddAffiliateModal } from 'components/Modals/AdminPanel/AddAffiliateModal';
import { DeleteThemedModal } from 'components/Modals/styled';
import { PitRouter } from 'constants/routers';
import { formatUnits } from 'ethers/lib/utils';
import { useGetInfluencerWhitelist } from 'hooks/influencer-whitelist/useGetInfluencerWhitelist';
import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { ApplicationModal } from 'redux/slices/application';
import { useToggleModal } from 'redux/slices/application/hook';
import { removeInfluencerWhitelist } from 'services/api';

import {
  AdminPanelTable,
  BodyContentWrapper,
  HeaderContentItemDetail,
  HeaderContentItemValue,
  HeaderContentItemWrapper,
  HeaderContentWrapper,
  StyledPagination,
  TableContentWrapper,
  UserCellWrapper,
} from './styled';

export const AffiliateTable = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const defaultPageSize = 50;
  const [params, setParams] = useState({
    pageSize: defaultPageSize,
    pageIndex: 1,
    sortField: 'revenue',
    sortDirection: 'desc',
  });
  const [refresh, setRefresh] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [confirmVisible, setConfirmVisible] = useState<boolean>(false);
  const [confirmIndex, setConfirmIndex] = useState<number>(null);

  const {
    loading: whitelistLoading,
    affiliate,
    total,
  } = useGetInfluencerWhitelist(params, refresh);

  const handleDelete = async () => {
    setConfirmVisible(false);

    const removeRes = await removeInfluencerWhitelist({
      username: affiliate[confirmIndex].username,
    });
    if (removeRes?.success) {
      toast.success('You have successfully removed affiliate');
      setRefresh(!refresh);
    } else toast.error('Remove affiliate failed!!');

    setLoading(false);
    setConfirmIndex(null);
  };

  const columns = [
    {
      title: '',
      dataIndex: 'key',
      key: 'key',
      render: value => params.pageSize * (currentPage - 1) + value + 1,
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      render: (username, record) => (
        <UserCellWrapper>
          <img src={record?.avatarURL || defaultAvatarImg} />
          <div>{username}</div>
        </UserCellWrapper>
      ),
    },
    {
      title: 'Affiliate link',
      dataIndex: 'affiliateLink',
      key: 'affiliateLink',
      render: (affiliateLink, record) => (
        <UserCellWrapper>
          <CopyToClipboard
            onCopy={() => {
              toast.success('COPY successfully');
            }}
            text={
              location.origin + PitRouter.REGISTER + '/' + record?.affiliateCode
            }
          >
            <Tooltip title="Copy" placement="left">
              <CopyOutlined style={{ cursor: 'pointer' }} />
            </Tooltip>
          </CopyToClipboard>
          <div>
            {location.origin + PitRouter.REGISTER + '/' + record?.affiliateCode}
          </div>
        </UserCellWrapper>
      ),
    },
    {
      title: 'Sign Ups',
      dataIndex: 'signUps',
      key: 'signUps',
      sorter: true,
      render: signUps => <div>{signUps}</div>,
    },
    {
      title: 'Unique Players',
      dataIndex: 'uniquePlayers',
      key: 'uniquePlayers',
      sorter: true,
      render: uniquePlayers => <div>{uniquePlayers}</div>,
    },
    {
      title: 'Total Plays',
      dataIndex: 'totalPlays',
      key: 'totalPlays',
      sorter: true,
      render: totalPlays => <div>{totalPlays}</div>,
    },
    {
      title: 'Revenue',
      dataIndex: 'revenue',
      sorter: true,
      key: 'revenue',
      render: revenue => (
        <div>
          $
          {(revenue?.amount
            ? parseFloat(formatUnits(revenue?.amount, revenue?.decimals)) *
              revenue?.tokenPrice
            : 0
          ).toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
          })}
        </div>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record, index) => (
        <DeleteOutlined
          onClick={() => {
            setLoading(true);
            setConfirmIndex(index);
            setConfirmVisible(true);
          }}
          disabled={false}
          style={{ fontSize: '20px', cursor: 'pointer', color: '#ff525b' }}
        />
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setParams({
      ...params,
      sortField: sorter.field && sorter.order ? sorter.field : undefined,
      sortDirection: sorter.order
        ? sorter.order === 'descend'
          ? 'desc'
          : 'asc'
        : undefined,
    });
  };

  const addAffiliateToggle = useToggleModal(ApplicationModal.ADD_AFFILIATE);
  return (
    <TableContentWrapper>
      <HeaderContentWrapper>
        <HeaderContentItemWrapper>
          <HeaderContentItemDetail>
            Amount of Affiliate Users
          </HeaderContentItemDetail>
          <HeaderContentItemValue>{total}</HeaderContentItemValue>
        </HeaderContentItemWrapper>
        <HeaderContentItemWrapper>
          <HeaderContentItemDetail>Add Affiliate User</HeaderContentItemDetail>
          <UserAddOutlined
            style={{ fontSize: '24px', cursor: 'pointer' }}
            onClick={() => {
              addAffiliateToggle();
            }}
          />
          <AddAffiliateModal
            onRefresh={() => {
              setRefresh(!refresh);
            }}
          />
        </HeaderContentItemWrapper>
      </HeaderContentWrapper>
      <BodyContentWrapper>
        <AdminPanelTable
          dataSource={affiliate?.map((item, key) => ({ ...item, key }))}
          columns={columns}
          pagination={false}
          loading={whitelistLoading}
          onChange={handleTableChange}
        />
        <Row justify="center" className="mb-5">
          {total > defaultPageSize + 1 && (
            <StyledPagination
              className="mt-4"
              pageSize={defaultPageSize + 1}
              current={currentPage}
              onChange={page => {
                setCurrentPage(page);
                setParams({
                  ...params,
                  pageIndex: page,
                });
              }}
              total={total}
              showSizeChanger={false}
            />
          )}
        </Row>
        <DeleteThemedModal
          visible={confirmVisible}
          onOk={handleDelete}
          onCancel={() => {
            setLoading(false);
            setConfirmVisible(false);
            setConfirmIndex(null);
          }}
          okText={'Delete'}
          cancelText="No"
          width={400}
          style={{ marginTop: '50px' }}
        >
          <p>
            <b>IMPORTANT NOTE:</b> All the affiliate links connected with this
            user will be removed and this user will not receive the Affiliate
            benefit distribution anymore
          </p>
          {confirmIndex !== null && (
            <p>
              Do you want to delete <b>{affiliate[confirmIndex]?.username}</b>{' '}
              from the Affiliate User list?
            </p>
          )}
        </DeleteThemedModal>
      </BodyContentWrapper>
    </TableContentWrapper>
  );
};

import { useEffect, useState } from 'react';
import { getTotalUniquePlayers } from 'services/api';

export const useGetTotalUniquePlayers = (refresh?: boolean) => {
  const [totalUniquePlayers, setTotalUniquePlayers] = useState<number>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    void (async () => {
      setLoading(true);
      const res = await getTotalUniquePlayers();
      if (res?.success) {
        setTotalUniquePlayers(res?.payload?.data);
      }
      setLoading(false);
    })();
  }, [refresh]);
  return { totalUniquePlayers, loading };
};

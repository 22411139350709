import styled from 'styled-components';

export const Text = styled.div`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
`;

export const AffiliateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 56px 142px;
`;

export const AffiliateContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 142px;

  @media (max-width: 1300px) {
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
`;

export const AffiliateContentTitle = styled(Text)`
  font-size: 24px;
  font-weight: 600;
  width: 20%;
  @media (max-width: 1300px) {
    width: 100%;
    text-align: center;
  }
`;

export const AffiliateContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  flex: 1;
  max-width: 100%;
  padding: 0px 40px;
  border: 1px solid rgba(255, 255, 255, 0.16);

  @media (max-width: 1300px) {
    width: 100%;
  }
`;

export const AffiliateContentItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 720px) {
    flex-direction: column;
    gap: 8px;
    flex-wrap: wrap;
  }
`;

export const AffiliateContentItemTitle = styled(Text)`
  font-size: 16px;
  font-weight: 500;
`;

export const AffiliateContentItemValue = styled(Text)`
  font-size: 16px;
  font-weight: 600;
`;

export const UnderlineText = styled(Text)`
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #fff;

  cursor: pointer;
  user-select: none;
`;

import { combineReducers } from 'redux';
import applicationModal from 'redux/slices/application';
import connect from 'redux/slices/connect';
import counter from 'redux/slices/counter';
import page from 'redux/slices/page';
import user from 'redux/slices/user';

import collateral from './slices/collateral';
import detail from './slices/detail';
import navbar from './slices/navbar';
import player from './slices/player';
import refreshToken from './slices/refreshToken';
import signar from './slices/signar';
import socket from './slices/socket';
import template from './slices/template';
import timestamp from './slices/timestamp';

const rootReducer = combineReducers({
  signar,
  collateral,
  counter,
  connect,
  user,
  page,
  applicationModal,
  refreshToken,
  timestamp,
  detail,
  player,
  template,
  navbar,
  socket,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

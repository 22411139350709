import { createSlice } from '@reduxjs/toolkit';
import { Socket } from 'socket.io-client';

interface ISocket {
  socket: Socket;
  isConnected: boolean;
  isConnecting: boolean;
}

const initialState: ISocket = {
  socket: undefined,
  isConnected: false,
  isConnecting: false,
};

const connectSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    startConnect: state => {
      state.isConnecting = true;
    },
    connect: (state, { payload }) => {
      state.socket = payload;
      state.isConnected = true;
      state.isConnecting = false;
    },
    disconnect: state => {
      state.socket = null;
      state.isConnected = false;
      state.isConnecting = false;
    },
  },
});

export const { connect, startConnect, disconnect } = connectSlice.actions;

export default connectSlice.reducer;

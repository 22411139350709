export enum GameWebSignalToListen {
  CONNECTED_WS = 'connected_ws',
  DISCONNECTED_WS = 'disconnected_ws',
  PONG = 'pong',

  PLAYED_LEVEL = 'played_level',

  WON_LEVEL = 'won_level',
  ENTERED_LEADERBOARD = 'entered_leaderboard',
}

export enum GameWebSignalToSend {
  PING = 'ping',
}

import { useEffect, useState } from 'react';
import { getAverageMetricsAverageUserSpent, ITimeRange } from 'services/api';

export interface IAverageMetricsAverageUserSpent {
  address: string;
  name: string;
  symbol: string;
  decimals: number;
  amount: string;
  tokenPrice: number;
}

export const useGetAverageMetricsAverageUserSpent = (params: ITimeRange) => {
  const [averageMetricsAverageUserSpent, setAverageMetricsAverageUserSpent] =
    useState<IAverageMetricsAverageUserSpent>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    void (async () => {
      setLoading(true);
      const res = await getAverageMetricsAverageUserSpent(params);
      if (res?.success) {
        setAverageMetricsAverageUserSpent(res?.payload?.data);
      }
      setLoading(false);
    })();
  }, [params]);
  return {
    averageMetricsAverageUserSpent,
    loading,
  };
};

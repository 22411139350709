import { LoadingOutlined } from '@ant-design/icons';
import { useWeb3React } from '@web3-react/core';
import { Form, Spin, Tooltip } from 'antd';
import carvIcon from 'assets/images/carv_id.png';
import metaMaskIcon from 'assets/logo-metamask.png';
import geminiIcon from 'assets/vectors/login-gemini.svg';
import {
  CustomFormItem,
  FormWrapper,
  InputStyle,
  PasswordInputStyle,
  RegisterConnectButton,
  RegisterConnectButtonsWrapper,
  RegisterStepButton,
  RegisterStepButtonWrapper,
  RegisterStepDescription,
  RegisterStepLink,
  RegisterStepTitle,
} from 'components/Register/styled';
import { PlayerStatus } from 'constants/enum/PlayerStatus';
import { PitRouter } from 'constants/routers';
import { ethers } from 'ethers';
import useConnectors from 'hooks/useConnectors';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { loginUser } from 'redux/slices/player';
import { login, loginByWallet, oneTimeTokenForMiniApp } from 'services/api';
import signMessage from 'services/Contracts/signMessage';
import { LocalhostStorage } from 'utils/sessionStorage';
import {
  ForgotPasswordLinkWrapper,
  ForgotPasswordStepLink,
  LoginConnectText,
  LoginLinkWrapper,
  LoginWrapper,
} from './styled';

export const LoginForm = () => {
  const navigate = useNavigate();

  const [inputPass, setInputPass] = useState<string>();
  const [emailAddress, setEmailAddress] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);
  const [byWallet, setByWallet] = useState<boolean>(false);

  const [typeOfConnector, setTypeOfConnector] = useState<string>();
  const { account } = useWeb3React();

  const { onConnectMetamaskWallet, disconnect } = useConnectors();

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  let bot: boolean | undefined;
  let telegramId: number | undefined;
  const botParam: string = searchParams.get('bot');
  const telegramIdParam: string = searchParams.get('telegramId');
  if (botParam) {
    bot = botParam === 'true';
  }
  if (telegramIdParam) {
    telegramId = parseInt(telegramIdParam);
  }
  if (bot) {
    LocalhostStorage.remove('accessToken');
    LocalhostStorage.remove('refreshToken');
    LocalhostStorage.remove('tokenType');
  }

  const handleLoginByWallet = useCallback(async () => {
    if (loading) {
      return;
    }
    if (account && typeOfConnector) {
      setLoading(true);

      let signMess;
      if (typeOfConnector === 'Metamask') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const dataSignature = await signMessage(account, provider.provider);
        signMess = dataSignature;
      } else {
        setLoading(false);
        setByWallet(false);
        disconnect();
        return;
      }

      if (!signMess?.success) {
        toast.error('Login rejected.');
        setLoading(false);
        setByWallet(false);
        disconnect();
        return;
      }

      const loginRes = await loginByWallet({
        walletAddress: account,
        signature: signMess.data.signature,
        message: signMess.data.message,
        telegramId: telegramId,
      });
      setLoading(false);
      setByWallet(false);
      if (loginRes?.success) {
        LocalhostStorage.set(
          'accessToken',
          loginRes?.payload?.data?.accessToken,
        );
        LocalhostStorage.set(
          'refreshToken',
          loginRes?.payload?.data?.refreshToken,
        );
        LocalhostStorage.remove('tokenType');
        dispatch(loginUser(loginRes?.payload?.data));

        if (loginRes?.payload?.data?.status === PlayerStatus.UploadedProfile) {
          navigate(PitRouter.GAME_LIST);
        } else {
          navigate(PitRouter.REGISTER);
        }
      } else {
        toast.error(loginRes?.message || 'Login failed');
        disconnect();
      }
    }
  }, [account, typeOfConnector]);

  useEffect(() => {
    handleLoginByWallet();
  }, [handleLoginByWallet]);

  const handleSubmit = async () => {
    setLoading(true);

    const loginRes = await login({
      emailAddress,
      password: inputPass,
      telegramId: telegramId,
    });
    if (loginRes?.success) {
      LocalhostStorage.set('accessToken', loginRes?.payload?.data?.accessToken);
      LocalhostStorage.set(
        'refreshToken',
        loginRes?.payload?.data?.refreshToken,
      );
      LocalhostStorage.remove('tokenType');

      if (bot) {
        try {
          const { expiresIn, token } = await oneTimeTokenForMiniApp({
            telegramId: telegramId,
          });

          window.location.replace(
            'tg://resolve?domain=BigArcadeBot/games&startapp=' + token,
          );
        } catch (e) {
          toast.error(e?.message || 'Get one time token failed');
        }

        setLoading(false);
        return;
      }

      dispatch(loginUser(loginRes?.payload?.data));
      if (loginRes?.payload?.data?.status === PlayerStatus.UploadedProfile) {
        navigate(PitRouter.GAME_LIST);
      } else {
        navigate(PitRouter.REGISTER);
      }
      setLoading(false);
    } else toast.error(loginRes?.message || 'Login failed');
  };

  const carvLogin = () => {
    const currentTimestamp = Date.now();
    const carvAuthUrl = `https://auth.carv.io/auth/authorize?client_id=${process.env.REACT_APP_CARV_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_CARV_REDIRECT_URI}&response_type=code&scope=${process.env.REACT_APP_CARV_SCOPE}&state=${currentTimestamp}`;
    window.location.href = carvAuthUrl;
  };

  return (
    <LoginWrapper>
      <RegisterStepTitle>Welcome Back</RegisterStepTitle>

      <FormWrapper>
        <Form
          className="login-form"
          onFinish={handleSubmit}
          initialValues={{ remember: true }}
          disabled={loading}
        >
          <CustomFormItem
            name="email"
            rules={[
              {
                pattern: new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$'),
                message: 'Invalid email',
              },
            ]}
          >
            <InputStyle
              onChange={e => setEmailAddress(e.target.value)}
              required
              placeholder="Email address"
              onKeyDown={e => e.keyCode === 32 && e.preventDefault()}
            />
          </CustomFormItem>
          <Form.Item name="password">
            <PasswordInputStyle
              onChange={e => setInputPass(e.target.value)}
              type="password"
              placeholder="Password"
              required
            />
          </Form.Item>
          <RegisterStepButtonWrapper>
            <RegisterStepButton
              htmlType="submit"
              loading={loading && !byWallet}
            >
              Log In
            </RegisterStepButton>
          </RegisterStepButtonWrapper>
        </Form>
      </FormWrapper>

      <ForgotPasswordLinkWrapper>
        <ForgotPasswordStepLink
          disabled={loading}
          onClick={() => {
            navigate(PitRouter.FORGOT_PASSWORD);
          }}
        >
          Forgot password?
        </ForgotPasswordStepLink>
      </ForgotPasswordLinkWrapper>

      <LoginLinkWrapper>
        <RegisterStepDescription>
          Don’t have an account?
        </RegisterStepDescription>
        <RegisterStepLink
          disabled={loading}
          onClick={() => {
            if (bot) {
              navigate(
                PitRouter.REGISTER + `?bot=true&telegramId=${telegramId}`,
              );
            } else {
              navigate(PitRouter.REGISTER);
            }
          }}
        >
          Sign Up
        </RegisterStepLink>
      </LoginLinkWrapper>

      <LoginConnectText>
        <div className="inline-line"></div>
        <div>Or login via</div>
        <div className="inline-line"></div>
      </LoginConnectText>

      <RegisterConnectButtonsWrapper>
        <RegisterConnectButton
          disabled={loading}
          onClick={() => carvLogin()}
          style={{ backgroundColor: '#000', color: '#fff' }}
        >
          <img src={carvIcon} alt="carv-icon" />
          <span>Continue with CARV ID</span>
        </RegisterConnectButton>
        <RegisterConnectButton
          disabled={loading}
          onClick={async () => {
            setByWallet(true);
            await onConnectMetamaskWallet();
            setTypeOfConnector('Metamask');
          }}
        >
          <Spin
            spinning={loading && byWallet && typeOfConnector === 'Metamask'}
            indicator={<LoadingOutlined spin />}
          />
          <img src={metaMaskIcon} alt="metamask-icon" />
          <span>Connect Metamask</span>
        </RegisterConnectButton>
        <Tooltip title="Coming soon">
          <RegisterConnectButton disabled>
            <img src={geminiIcon} alt="gemini-icon" />
            <span>Gemini</span>
          </RegisterConnectButton>
        </Tooltip>
      </RegisterConnectButtonsWrapper>
    </LoginWrapper>
  );
};

import { PlatformConfig } from 'components/PlatformConfig';
import { AdminTable } from 'components/Table/AdminTable';
import { AffiliateTable } from 'components/Table/AffiliateTable';
import { GamerDataTable } from 'components/Table/GamerDataTable';
import { GameRevenue } from 'components/Table/GameRevenue';
import { ProviderWhitelistTable } from 'components/Table/ProviderWhitelistTable';
import { SupportedTokenTable } from 'components/Table/SupportedTokenTable';
import { PlayerRoles } from 'constants/enum/PlayerRole';
import { useState } from 'react';
import { useAppSelector } from 'redux/store';
import HomepageTemplate from 'template/Homepage';

import { TesterTable } from 'components/Table/TesterTable';
import {
  AdminPanelWrapper,
  ButtonBlockWrapper,
  ButtonWrapper,
  ContentBlockWrapper,
} from './styled';

export const AdminPanel = () => {
  const { playerInfo } = useAppSelector(state => state.player);
  const [activeButton, setActiveButton] = useState(
    playerInfo?.roles.includes(PlayerRoles.SuperAdmin)
      ? 'platformConfig'
      : 'gameProvider',
  );
  const [activeContent, setActiveContent] = useState(
    playerInfo?.roles.includes(PlayerRoles.SuperAdmin) ? (
      <PlatformConfig />
    ) : (
      <ProviderWhitelistTable />
    ),
  );

  const handleButtonClick = buttonName => {
    setActiveButton(buttonName);
    let contentComponent;

    switch (buttonName) {
      case 'platformConfig':
        contentComponent = <PlatformConfig />;
        break;
      case 'admin':
        contentComponent = <AdminTable />;
        break;
      case 'gameProvider':
        contentComponent = <ProviderWhitelistTable />;
        break;
      case 'affiliate':
        contentComponent = <AffiliateTable />;
        break;
      case 'tester':
        contentComponent = <TesterTable />;
        break;
      case 'supportedToken':
        contentComponent = <SupportedTokenTable />;
        break;
      case 'gamer-data':
        contentComponent = <GamerDataTable />;
        break;
      case 'game-revenue':
        contentComponent = <GameRevenue />;
        break;
      default:
        contentComponent = <PlatformConfig />;
        break;
    }

    setActiveContent(contentComponent);
  };

  return (
    <HomepageTemplate>
      <AdminPanelWrapper>
        <ButtonBlockWrapper>
          {playerInfo?.roles.includes(PlayerRoles.SuperAdmin) && (
            <>
              <ButtonWrapper
                active={activeButton === 'platformConfig'}
                onClick={() => handleButtonClick('platformConfig')}
              >
                Platform config
              </ButtonWrapper>
              <ButtonWrapper
                active={activeButton === 'admin'}
                onClick={() => handleButtonClick('admin')}
              >
                Manage Admins
              </ButtonWrapper>
            </>
          )}

          <ButtonWrapper
            active={activeButton === 'gameProvider'}
            onClick={() => handleButtonClick('gameProvider')}
          >
            Manage Game Providers
          </ButtonWrapper>

          <ButtonWrapper
            active={activeButton === 'affiliate'}
            onClick={() => handleButtonClick('affiliate')}
          >
            Manage Affiliate Users
          </ButtonWrapper>

          <ButtonWrapper
            active={activeButton === 'tester'}
            onClick={() => handleButtonClick('tester')}
          >
            Manage Testers
          </ButtonWrapper>

          <ButtonWrapper
            active={activeButton === 'gamer-data'}
            onClick={() => handleButtonClick('gamer-data')}
          >
            Gamer Data
          </ButtonWrapper>

          <ButtonWrapper
            active={activeButton === 'game-revenue'}
            onClick={() => handleButtonClick('game-revenue')}
          >
            Game Revenue
          </ButtonWrapper>
          {/* 
          {playerInfo?.roles.includes(PlayerRoles.SuperAdmin) && (
            <>
              <ButtonWrapper
                active={activeButton === 'supportedToken'}
                onClick={() => handleButtonClick('supportedToken')}
              >
                Supported tokens
              </ButtonWrapper>
            </>
          )} */}
        </ButtonBlockWrapper>

        <ContentBlockWrapper>{activeContent}</ContentBlockWrapper>
      </AdminPanelWrapper>
    </HomepageTemplate>
  );
};

import { Col, Dropdown, Row } from 'antd';
import arrowDownIcon from 'assets/vectors/logo-game-arrow-down.svg';
import { GameDetailDrawer } from 'components/Games/GameDetailDrawer';
import { GameItem } from 'components/Games/GameItem';
import { IGameActive, useGetGameActive } from 'hooks/games/useGetGameActive';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ApplicationModal } from 'redux/slices/application';
import { useToggleModal } from 'redux/slices/application/hook';
import HomepageTemplate from 'template/Homepage';
import { everflowConversion } from 'utils/everflow';

import {
  DropdownIcon,
  DropdownTitle,
  DropdownTitleWrapper,
  FilterText,
  FilterWrapper,
  GameStyledCol,
  GamesWrapper,
  GameWrapper,
  MenuItemWrapper,
  MenuWrapper,
  TitleStyled,
  TitleWrapper,
} from './styled';

export const GamesPage = () => {
  const { state } = useLocation();
  const menu = (
    <MenuWrapper>
      <MenuItemWrapper>All</MenuItemWrapper>
      <MenuItemWrapper>New Release</MenuItemWrapper>
      <MenuItemWrapper>Coming soon</MenuItemWrapper>
      <MenuItemWrapper>Alphabetical</MenuItemWrapper>
      <MenuItemWrapper>Price: High to Low</MenuItemWrapper>
      <MenuItemWrapper>Price: Low to High</MenuItemWrapper>
    </MenuWrapper>
  );
  const [params] = useState({
    pageIndex: 1,
    pageSize: 30,
    showStatistic: true,
  });

  const { gameActive } = useGetGameActive(params);

  const toggleDrawer = useToggleModal(ApplicationModal.GAME_DETAIL_DRAWER);
  const [gameDetail, setGameDetail] = useState<IGameActive>();

  const showDetail = (index: number) => {
    setGameDetail(gameActive?.at(index));
    toggleDrawer();
  };

  useEffect(() => {
    if (state?.isConversion)
      (async () => {
        await everflowConversion();
      })();
  }, [state]);

  return (
    <HomepageTemplate>
      <GamesWrapper>
        <TitleWrapper>
          <TitleStyled>Biga Games</TitleStyled>

          {/* <FilterWrapper>
            <FilterText>Filter:</FilterText>

            <Dropdown overlay={menu} trigger={['click']}>
              <DropdownTitleWrapper>
                <DropdownTitle>New Release</DropdownTitle>
                <DropdownIcon src={arrowDownIcon}></DropdownIcon>
              </DropdownTitleWrapper>
            </Dropdown>
          </FilterWrapper> */}
        </TitleWrapper>

        <GameWrapper>
          <Row gutter={[24, 40]} align="bottom">
            {gameActive?.map((item, index) => (
              <GameStyledCol
                xxl={6}
                xl={6}
                lg={8}
                md={12}
                sm={12}
                xs={24}
                key={index}
              >
                <GameItem {...item} showDetail={() => showDetail(index)} />
              </GameStyledCol>
            ))}
          </Row>
        </GameWrapper>

        <GameDetailDrawer gameDetail={gameDetail} />
      </GamesWrapper>
    </HomepageTemplate>
  );
};

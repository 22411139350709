import { Select } from 'antd';
import styled from 'styled-components';

export const Text = styled.div`
  font-style: normal;
  line-height: normal;
  font-family: Neue Haas Grotesk Display Pro;
`;

export const SelectGameStyled = styled(Select)`
  border: rgb(111 199 87 / 46%) 1px solid;
  border-radius: 6.5px;
  color: #0b3885;
  font-size: 17px;
  font-weight: 600;
  height: 38px;
  width: 180px;
  text-align: center;

  @media (max-width: 640px) {
    margin-top: 10px;
    width: 150px;
  }

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    display: flex;
    align-items: center;
    height: 38px;
    border-radius: 6.5px;
    border: none;
  }

  .ant-select-selection-item {
    font-weight: 600;
  }

  .ant-select-selection-placeholder {
    color: #0b3885;
  }
`;

export const LeaderBoardWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const LeftWrapper = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  position: relative;
  border-right: 1px solid rgba(255, 255, 255, 0.16);
  max-height: calc(100vh - 94px);
`;

export const RightWrapper = styled.div`
  gap: 16px;
  width: 80%;
  margin: 24px 41px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const GeneralButtonWrapper = styled.div`
  display: flex;
  height: 40px;
  max-width: 100%;
  background: rgba(255, 255, 255, 0.08);
`;

export const ButtonWrapper = styled.div<{ active?: boolean }>`
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border: ${props =>
    props?.active ? '1px solid rgba(255, 255, 255, 0.24)' : 'none'};
  background: ${props => (props?.active ? '#fff' : 'none')};
  color: ${props => (props?.active ? '#0A3885' : '#fff')};

  cursor: pointer;
`;

export const ButtonText = styled(Text)`
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
`;

export const ListGameWrapper = styled.div`
  overflow: auto;
  width: 100%;
  padding: 16px 40px;
  gap: 16px;

  &::-webkit-scrollbar {
    display: none;
  }

  .ant-list-split .ant-list-item {
    border: none;
  }

  .ant-list-item {
    padding: 0;
    margin-bottom: 16px;
  }
`;

export const GameWrapper = styled.img<{ selected?: boolean }>`
  width: 100%;
  object-fit: cover;
  position: relative;
  cursor: pointer;

  &:hover {
    outline: 8px solid lightgreen;
  }

  outline: ${props => (props?.selected ? '8px solid lightgreen' : 'none')};
`;

export const InformationWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  padding: 32px 0px;
  display: flex;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.9);
  @media (max-width: 948px) {
    flex-direction: column;
    gap: 12px;
  }
`;

export const DetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
`;

export const DetailLabelWrapper = styled(Text)`
  color: #000;
  font-size: 14px;
  font-weight: 400;
`;

export const DetailValueWrapper = styled(Text)`
  color: #000;
  font-size: 24px;
  font-weight: 600;
`;

export const SelectMultiLevel = styled(Select)`
  border: 1px solid rgba(255, 255, 255, 0.9);
  border-radius: 6.5px;
  color: #0b3885;
  font-size: 17px;
  font-weight: 600;
  height: 38px;
  width: 180px;
  text-align: center;
  background: rgba(255, 255, 255, 0.9);

  .ant-select-selector {
    .ant-select-selection-item {
      padding-right: 0;
      font-weight: 600;
      font-size: 17px;
    }
  }

  @media (max-width: 640px) {
    margin-top: 10px;
    width: 150px;
  }

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    display: flex;
    align-items: center;
    height: 38px;
    border-radius: 6.5px;
    border: none;
    background-color: rgba(243, 244, 246, 1);
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 36px 0 13px;
  }

  &.ant-select-dropdown.ant-select-dropdown-empty {
    border-radius: 6.5px;
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    box-shadow: none;
  }
`;

export const SelectMultiLevelMinisize = styled(SelectMultiLevel)`
  width: 140px;
  font-size: 14px;

  .ant-select-selector {
    .ant-select-selection-item {
      font-size: 14px;
    }
  }

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: rgba(255, 255, 255, 1);
  }
`;

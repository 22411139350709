import congratsPoster from 'assets/vectors/register-congrats.svg';
import { SplashVideoFullScreen } from 'components/AccessControl/styled';
import { GaActions, GaCategories, GaLabels } from 'constants/enum/GaEvent';
import { PitRouter } from 'constants/routers';
import { useState } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { everflowClick } from 'utils/everflow';

import {
  RegisterCongratulationHeadingWrapper,
  RegisterCongratulationWrapper,
  RegisterStepButton,
  RegisterStepDescription,
  RegisterStepTitle,
} from './styled';

export const RegisterCongratulations = () => {
  const navigate = useNavigate();
  const [showVideo, setShowVideo] = useState<boolean>(false);

  const handleEnterArcadeClick = () => {
    (async () => {
      await everflowClick();
    })();
    ReactGA.event({
      category: GaCategories.SIGNUP,
      action: GaActions.ACTION_SIGNUP_COMPLETED,
      label: GaLabels.LABEL_SIGNUP_COMPLETED,
    });

    setShowVideo(true);
  };

  return (
    <RegisterCongratulationWrapper>
      <img src={congratsPoster} alt="congratulation-poster" />

      <RegisterCongratulationHeadingWrapper>
        <RegisterStepTitle>Congratulations</RegisterStepTitle>
        <RegisterStepDescription>
          Your signup has been complete! Thank you for joining with us
        </RegisterStepDescription>
      </RegisterCongratulationHeadingWrapper>

      <RegisterStepButton onClick={handleEnterArcadeClick}>
        Enter Arcade
      </RegisterStepButton>
      {showVideo && (
        <SplashVideoFullScreen
          onEnded={() => {
            navigate(PitRouter.GAME_LIST, {
              state: { isConversion: true },
            });
            setShowVideo(false);
          }}
          className="splash-video-full-screen"
        />
      )}
    </RegisterCongratulationWrapper>
  );
};

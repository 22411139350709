import { Button, Form, Input } from 'antd';
import { SplashVideo } from 'components/SplashVideo';
import styled from 'styled-components';

export const AccessControlWrapper = styled.div`
  background-color: #0b3885;
  z-index: 1000;
`;

export const AccessControlStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const AccessControlContainer = styled.div`
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  font-size: 200px;
`;

export const AccessControlForm = styled(Form)<any>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 160px;
  width: 300px;
  label {
    font-size: 18px;
  }
`;

export const AccessControlLabel = styled.label`
  color: #0b3885;
  font-weight: 600;
  text-align: center;
  margin-bottom: 15px;
`;

export const AccessControlInput = styled(Input)<any>`
  padding: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  border-radius: 6.5px;
  font-weight: 600;
  height: 48px;
  width: 200px;
  :focus {
    border: 1px solid #0b3885;
  }
`;

export const AccessControlButton = styled(Button)<any>`
  background-color: #0b3885;
  border-radius: 6.5px;
  box-shadow: 4px 4px 3px #4a6ca4;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  text-transform: uppercase;
  width: 200px;
  :hover,
  :focus {
    border: 1px solid #0b3885;
    color: #0b3885;
  }
`;

export const SplashVideoFullScreen = styled(SplashVideo)`
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

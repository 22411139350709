import darkImg from 'assets/vectors/biga-dark-image.svg';
import whiteImg from 'assets/vectors/biga-white-image.svg';

// import { Rectangle } from 'react-shapes';
import { FooterContent, FooterWrapper, SideColumns } from './styled';

interface IFooterProps {
  theme?: string;
}

export const Footer = ({ theme }: IFooterProps) => {
  // const WHITE_INDEXES = [0, 4, 5, 7, 9];

  // const rectangles = Array.from({ length: 12 }, (_, i) => (
  //   <Rectangle
  //     key={i}
  //     width={'100%'}
  //     height={'100%'}
  //     fill={
  //       theme === 'white'
  //         ? { color: WHITE_INDEXES.includes(i) ? '#0b3885' : '#fff' }
  //         : { color: WHITE_INDEXES.includes(i) ? '#fff' : '#0b3885' }
  //     }
  //   />
  // ));

  return (
    <FooterWrapper>
      {/* <SideColumns>{rectangles}</SideColumns> */}
      <img src={theme === 'white' ? darkImg : whiteImg} alt="white-img" />
      <FooterContent param={theme}>MAKE AN INCOME PLAYING GAMES!</FooterContent>
      <SideColumns></SideColumns>
    </FooterWrapper>
  );
};

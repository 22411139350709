import { Table } from 'antd';
import styled from 'styled-components';

export const Text = styled.div`
  font-style: normal;
  line-height: normal;
  font-family: Neue Haas Grotesk Display Pro;
`;

export const LeaderBoardTableWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const LeaderboardTableStyled = styled(Table)<{ hasLoadMore?: boolean }>`
  width: 100%;
  gap: 16px;
  background: rgba(255, 255, 255, 0.9);

  .ant-table {
    background: rgba(255, 255, 255, 0);
  }
  .ant-table-thead tr,
  .ant-table-tbody tr {
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
  }

  .ant-table-thead > tr > th {
    background-color: rgba(255, 255, 255, 0);
  }

  .ant-table-thead tr th,
  .ant-table-tbody tr td {
    height: 40px;
    padding: 0px 16px;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }

  .ant-table-column-sorter-inner {
    color: #1c1b1f;
  }

  .ant-table-tbody tr td {
    height: 56px;
  }

  .ant-table-cell {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    font-family: Neue Haas Grotesk Display Pro;
  }

  .ant-table-column-title,
  .ant-table-thead th {
    color: #000;
    font-family: 'Neue Haas Grotesk Display Pro';
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
  }

  .ant-table-tbody tr:nth-child(even) {
    background-color: #fff;
  }

  .ant-table-column-sorters {
    display: inline-flex;
  }

  .ant-table-tbody tr:hover td {
    background-color: transparent;
  }

  & > .ant-table-tbody > tr.ant-table-row > td {
    background-color: red;
  }

  .ant-table-tbody tr td.ant-table-column-sort {
    background-color: transparent;
  }

  .ant-table-column-sorters .ant-table-column-sorter-down.active {
    border-bottom: none;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan]):before {
    width: 0;
  }

  td.column-align-left,
  th.column-align-left {
    text-align: left !important;
  }

  .ant-table-body {
    max-height: ${props =>
      props.hasLoadMore
        ? 'calc(100vh - 64px - 117px - 32px - 24px - 39px - 51px) !important'
        : 'calc(100vh - 64px - 117px - 32px - 24px - 39px) !important'};
    @media (max-width: 948px) {
      max-height: ${props =>
        props.hasLoadMore
          ? 'calc(100vh - 64px - 247px - 32px - 24px - 39px - 51px) !important'
          : 'calc(100vh - 64px - 247px - 32px - 24px - 39px) !important'};
    }
  }
`;

export const LeaderboardScoreTable = styled(LeaderboardTableStyled)<{
  firstRankIndex?: number;
  secondRankIndex?: number;
  thirdRankIndex?: number;
}>`
  /* table tr:nth-child(1) td:nth-child(1) {
    color: #fac519;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    font-style: normal;
    line-height: normal;
    font-family: Neue Haas Grotesk Display Pro;
  } */

  table tr:nth-child(${props => props.firstRankIndex + 1}) td:nth-child(1) {
    color: #fac519;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    font-style: normal;
    line-height: normal;
    font-family: Neue Haas Grotesk Display Pro;
  }

  table tr:nth-child(${props => props.firstRankIndex + 1}) td {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    font-family: Neue Haas Grotesk Display Pro;
  }

  /* .ant-table-tbody tr:nth-child(1) {
    height: 80px;
    padding: 0px 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    background: #d9b526;
  } */

  table
    tr:not(.ant-table-placeholder):nth-child(
      ${props => props.firstRankIndex + 1}
    )
    td {
    height: 80px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    background: #d9b526;
  }

  table
    tr:nth-child(${props => props.firstRankIndex + 1})
    td:nth-child(1)
    .circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background: #fff;
  }

  table
    tr:nth-child(${props => props.secondRankIndex + 1})
    td:nth-child(1)
    .circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background: #c7d2e0;
  }

  table
    tr:nth-child(${props => props.thirdRankIndex + 1})
    td:nth-child(1)
    .circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background: #d9af7c;
  }
`;

export const LoadMoreButtonWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
`;

export const LoadMoreContentWrapper = styled.div`
  display: flex;
  padding: 0px 24px;
  gap: 8px;
  margin: 16px 0;
  cursor: pointer;
`;

export const LoadMoreText = styled(Text)`
  color: var(--biga-blue, #0a3885);
  font-size: 16px;
  font-weight: 600;
`;

export const LoadMoreIcon = styled.img`
  width: 18px;
  height: 18px;
`;

export const OwnerWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const OwnerAvatar = styled.img`
  width: 28px;
  height: 28px;
  border-radius: 50%;
`;

export const OwnerInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
`;

export const OwnerName = styled(Text)`
  font-size: 14px;
  font-weight: 600;
`;

export const HighlightCurrentOwner = styled.span`
  background-color: lightgreen;
  font-style: normal;
  line-height: normal;
  font-family: Neue Haas Grotesk Display Pro;

  padding: 2px;
`;

import { Button, Modal } from 'antd';
import styled from 'styled-components';

export const Text = styled.div`
  color: #000;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
`;

export const ModalWrapper = styled(Modal)`
  & > .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
    .ant-modal-body {
      padding: 0;
    }
  }
`;

export const CloseWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  user-select: none;
`;

export const CloseIcon = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

export const ModalWithdrawWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ModalWithdrawContainer = styled.div`
  background-color: #fff;
`;

export const ModalWithdrawContent = styled.div`
  padding: 36px;
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 1rem;
  }
`;

export const SubmitButton = styled(Button)<{ height?: string; width?: string }>`
  color: white;
  border: none;
  font-size: 17px;
  font-weight: 600;
  height: ${props => (props.height ? props.height : '50px')};
  width: ${props => (props.width ? props.width : '250px')};
  background-color: #0a3885;
  margin-top: 20px;

  :hover,
  :focus,
  :active {
    color: white;
    background-color: #0a3885;
  }

  ::after {
    display: none;
  }

  :disabled,
  :disabled:hover {
    background-color: #0a3885;
    opacity: 0.6;
    background-image: none !important;
    color: white;
  }
`;

export const LinkButton = styled(Button)<{ height?: string; width?: string }>`
  color: #000;
  border: 1px solid #d1d1d1;
  font-size: 17px;
  font-weight: 600;
  height: ${props => (props.height ? props.height : '50px')};
  width: ${props => (props.width ? props.width : '250px')};
  background-color: #fff;
  margin-top: 20px;

  :hover,
  :focus,
  :active {
    color: #000;
    background-color: #fff;
    border: 1px solid #d1d1d1;
  }

  ::after {
    display: none;
  }

  :disabled,
  :disabled:hover {
    color: #000;
    background-color: #fff;
    border: 1px solid #d1d1d1;
    opacity: 0.6;
  }
`;

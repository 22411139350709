import { Form, Input } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 56px;
`;

export const InputField = styled(Input)<{
  color?: string;
  borderColor?: string;
}>`
  border: 1px solid ${({ borderColor }) => borderColor || '#bcdfce'};
  border-radius: 0.6rem;
  background-color: transparent;
  box-shadow: none !important;
  color: ${({ color }) => color || 'black'};
  font-size: 16px;

  input {
    color: ${({ color }) => color || 'black'} !important;
  }

  :focus,
  :hover {
    border-color: ${({ borderColor }) => borderColor || '#23c879'} !important;
  }

  :disabled,
  :disabled:hover {
    background-color: transparent !important;
    color: ${({ color }) => color || '#23c879'} !important;
  }

  .ant-input-clear-icon,
  .anticon.ant-input-clear-icon {
    color: ${({ color }) => color || '#23c879'};
    display: flex;
    justify-content: center;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;

export const FormItemWrapper = styled(Form.Item)`
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

export const FormItemInput = styled(Form.Item)`
  /* width: 98%; */
  margin-bottom: 0px;
  padding: 0px;
  .ant-form-item-label {
    label {
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      color: #525458;
    }
  }
`;

export const RatioGameType = styled.div`
  margin-top: 18px;
  .ant-radio-wrapper {
    font-size: 16px;
    color: black;
  }
`;

export const TextField = styled.p<{ param?: string; bgColor?: string }>`
  text-align: right;
  color: black;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 0;
  padding-right: 15px;
  height: 38px;
  width: 100%;
  max-width: ${props => props.param};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 0;
  flex: 1;
  @media (max-width: 768px) {
    /* max-width: 145px; */
    flex: 1;
    font-size: 16px;
  }
  background-color: ${props => props.bgColor};
  border: ${props => (props.bgColor ? '1px solid #a7a7a7' : 'none')};
  /* border: 1px solid #bcdfce;
  border-radius: 0.6rem;
  background-color: #e9eef2;
  color: black;
  font-size: 16px;
  line-height: 33px;
  margin-bottom: 0;
  padding-left: 10px;
  height: 35px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
`;

export const WrapReview = styled.div`
  max-width: 100vw;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding: 0;
  margin-bottom: 20px;
`;

export const BackButton = styled.button`
  background-color: transparent;
  color: #6f7071;
  border: none;
  font-size: 18px;
  padding: 0;
  margin-top: 0;
  position: absolute; // Add position absolute
  top: 0; // Add top 0
  left: 0; // Add left 0

  :hover {
    color: black;
  }
`;

export const HeaderTitle = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 50px;
`;

export const AvatarImg = styled.img`
  width: 30px;
  height: 30px;
  margin: 0 5px;
  border-radius: 50%;
`;

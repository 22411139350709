import { useEffect, useState } from 'react';
import { getListPaymentToken } from 'services/api';

export interface IListPaymentToken {
  address: string;
  name: string;
  symbol: string;
  decimals: number;
  iconUrl: string;
  chainId: number;
  type?: string;
}

export const useGetListPaymentToken = (params, refresh?: boolean) => {
  const [listPaymentToken, setListPaymentToken] =
    useState<IListPaymentToken[]>(undefined);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    void (async () => {
      setLoading(true);
      const res = await getListPaymentToken(params);
      if (res?.success) {
        setListPaymentToken(res?.payload?.data?.data);
        setTotal(res?.payload?.data?.totalCount);
      }
      setLoading(false);
    })();
  }, [params, refresh]);

  return { listPaymentToken, loading, total };
};

import AccessControl from 'components/AccessControl/AccessControl';
import { useEffect, useState } from 'react';

export const PassCodeRoute = ({ children }) => {
  const [accessKey, setAccessKey] = useState<string>('');
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const ENABLE_SITE_PASSWORD =
    process.env.REACT_APP_ENABLE_SITE_PASSWORD?.toLowerCase();
  const enableSitePassword: boolean =
    ENABLE_SITE_PASSWORD &&
    (ENABLE_SITE_PASSWORD === 'true' || ENABLE_SITE_PASSWORD === 'false')
      ? JSON.parse(ENABLE_SITE_PASSWORD)
      : false;

  useEffect(() => {
    if (!enableSitePassword) {
      return;
    }
    const storedAccessKey = localStorage.getItem('accessKey');
    if (storedAccessKey !== process.env.REACT_APP_PASSWORD) {
      setShowPopup(true);
    } else {
      setAccessKey(storedAccessKey);
    }
  }, []);

  const handleAccessGranted = (enteredAccessKey: string) => {
    localStorage.setItem('accessKey', enteredAccessKey);
    setAccessKey(enteredAccessKey);
    setShowPopup(false);
  };

  return (
    <>
      {showPopup && <AccessControl onAccessGranted={handleAccessGranted} />}
      {(!enableSitePassword || accessKey === process.env.REACT_APP_PASSWORD) &&
        children}
    </>
  );
};

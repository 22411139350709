import { CheckCircleTwoTone } from '@ant-design/icons';
import closeicon from 'assets/vectors/logo-profile-close.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApplicationModal } from 'redux/slices/application';
import { useModalIsOpen, useToggleModal } from 'redux/slices/application/hook';

import {
  CloseIcon,
  CloseWrapper,
  ModalPaymentSuccessContainer,
  ModalPaymentSuccessWrapper,
  ModalWrapper,
  SuccessDetailText,
  SuccessText,
} from './styled';

export const PaymentSuccessModal = () => {
  const open = useModalIsOpen(ApplicationModal.PAYMENT_CHECKOUT_SUCCESS);
  const toggleModal = useToggleModal(ApplicationModal.PAYMENT_CHECKOUT_SUCCESS);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleCancel = () => {
    toggleModal();
    navigate(pathname);
  };

  return (
    <ModalWrapper
      visible={open}
      onCancel={handleCancel}
      maskClosable={true}
      footer={null}
      closable={false}
      width="448px"
    >
      <ModalPaymentSuccessWrapper>
        <CloseWrapper>
          <CloseIcon src={closeicon} onClick={handleCancel} />
        </CloseWrapper>
        <ModalPaymentSuccessContainer>
          <CheckCircleTwoTone
            twoToneColor="#0aa74e"
            className="payment-checkout-icon"
          />
          <SuccessText>Payment Successful!</SuccessText>
          <SuccessDetailText>
            Your payment has been successfully processed. Thank you for your
            purchase!
          </SuccessDetailText>
        </ModalPaymentSuccessContainer>
      </ModalPaymentSuccessWrapper>
    </ModalWrapper>
  );
};

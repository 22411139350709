import { useEffect, useState } from 'react';
import { getGameStatistic } from 'services/api';

export interface IGameStatic {
  gameId: string;
  playsAmount: number;
  totalOfSpentAmount: string;
  totalOfRevenue: string;
  totalOfUSDRevenue: string;
  playerAmount: number;
  minLevel?: number;
  maxLevel?: number;
}

export const useGetGameStatistic = (
  gameId: string,
  level: string,
  isScoreGame: boolean,
) => {
  const [gameStatistic, setGameStatistic] = useState<IGameStatic>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    void (async () => {
      if (gameId) {
        setLoading(true);
        const res = isScoreGame
          ? await getGameStatistic(gameId, level)
          : await getGameStatistic(gameId);
        if (res?.success) {
          setGameStatistic(res?.payload?.data);
        }
        setLoading(false);
      }
    })();
  }, [gameId, level]);

  return { gameStatistic, loading };
};

import { useCallback } from 'react';
import { toast } from 'react-toastify';
import {
  checkUsernameExists as checkUsernameExistsApi,
  ICheckUsernameExists,
} from 'services/api';

const usePlayerCallback = () => {
  const checkUsernameExists: (
    username: string,
  ) => Promise<ICheckUsernameExists> = useCallback(async (username: string) => {
    try {
      if (username) {
        return (await checkUsernameExistsApi(username))?.payload?.data;
      }
    } catch (e) {
      console.log(e.message);
      toast.error(e.reason || e.message);
    }
  }, []);

  return { checkUsernameExists };
};

export default usePlayerCallback;

import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import store, { persistor } from 'redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { AppRouter } from 'routes';

import 'antd/dist/antd.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/config.css';
import './styles/dropdown.css';

function App(): JSX.Element {
  const helmetContext = {};
  return (
    <HelmetProvider context={helmetContext}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppRouter />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
            theme="dark"
            bodyStyle={{ fontFamily: 'Bicyclette' }}
          />
        </PersistGate>
      </Provider>
    </HelmetProvider>
  );
}

export default App;

import { DatePicker, Input, Pagination, Popover, Select, Table } from 'antd';
import styled from 'styled-components';

const { RangePicker } = DatePicker;

export const TableWrapper = styled.div`
  max-width: 700px;
  margin: auto;
`;

export const LeaderboardTable = styled(Table)<{ light?: string }>`
  overflow: auto;
  white-space: nowrap;
  .ant-table table {
    border-spacing: 0 6px;
  }

  .ant-table-content {
    background-color: ${props =>
      props.light === 'true' ? '#fff' : '#0b3885'} !important;
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    background-color: ${props =>
      props.light === 'true' ? '#0b3885' : '#4a6ca4'};
    color: #fff;
    font-size: 20px;
    font-family: 450;
    padding: 0.3rem 0 0.3rem 1.5rem;
    text-align: center;
    border: none;

    &:first-child {
      border-radius: 7px 0 0 7px;
    }

    &:last-child {
      border-radius: 0 7px 7px 0;
    }
  }

  .ant-table-thead > tr > th {
    background-color: ${props =>
      props.light === 'true' ? '#fff' : '#0b3885'} !important;
    color: ${props => (props.light === 'true' ? '#0b3885' : '#fff')};
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
  }

  .ant-table-tbody > tr:hover > td,
  .ant-table-tbody > tr:hover > th {
    background-color: #6b8ad6;
  }

  .ant-table-tbody > tr:not(:last-child) {
    border-bottom: 1px solid #23c879;
    margin-bottom: 10px;
    border-radius: 7px;
  }

  .ant-empty-description {
    color: ${props =>
      props.light === 'true' ? 'rgb(255 255 255 / 25%)' : 'rgba(0,0,0,.25)'};
  }
`;

export const AdminPanelTable = styled(Table)<{ light?: string }>`
  gap: 16px;
  background: rgba(255, 255, 255, 0.9);

  .ant-table {
    background: rgba(255, 255, 255, 0);
    overflow: auto;
  }

  .ant-table-thead tr,
  .ant-table-tbody tr {
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
  }

  .ant-table-thead tr th,
  .ant-table-tbody tr td {
    height: 40px;
    padding: 0px 16px;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }

  .ant-table-thead tr th:nth-child(2) {
    text-align: left;
  }

  .ant-table-tbody tr td:nth-child(2) {
    text-align: left;
  }

  .ant-table-thead tr th:nth-child(3) {
    text-align: left;
  }

  .ant-table-tbody tr td:nth-child(3) {
    text-align: left;
  }

  .ant-table-column-sorter-inner {
    color: #1c1b1f;
  }

  .ant-table-tbody tr td {
    height: 56px;
  }

  .ant-table-cell {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    font-family: Neue Haas Grotesk Display Pro;
  }

  .ant-table-column-title,
  .ant-table-thead th {
    color: #000;
    font-family: 'Neue Haas Grotesk Display Pro';
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
  }

  .ant-table-tbody tr:nth-child(even) {
    background-color: #fff;
  }

  .ant-table-column-sorters {
    display: inline-flex;
  }

  .ant-table-tbody tr:hover td {
    background-color: transparent;
  }

  & > .ant-table-tbody > tr.ant-table-row > td {
    background-color: red;
  }

  .ant-table-tbody tr td.ant-table-column-sort {
    background-color: transparent;
  }

  .ant-table-column-sorters .ant-table-column-sorter-down.active {
    border-bottom: none;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan]):before {
    width: 0;
  }

  td.column-align-left,
  th.column-align-left {
    text-align: left !important;
  }
`;

export const TwoColumnsTable = styled(Table)`
  margin-top: 10px;
  overflow: auto;
  border-radius: 15px;

  .ant-table-thead > tr > th {
    font-weight: 700;
    width: 50%;
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    background-color: #4a6ca4;
    border: none;
    color: #fff;
    font-size: 20px;
    font-family: 450;
    padding: 0.3rem 2rem;
    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }

  .ant-table-tbody > tr:hover > td,
  .ant-table-tbody > tr:hover > th {
    background-color: #6b8ad6;
  }
`;

export const StyledPagination = styled(Pagination)<{ light?: string }>`
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  & .ant-pagination-item {
    border: none;
    background-color: transparent;
    color: ${props =>
      props.light === 'true'
        ? 'rgba(11, 56, 133, 0.39)'
        : 'rgba(255, 255, 255, 0.39)'};
    &:hover a,
    &:focus a {
      color: ${props =>
        props.light === 'true'
          ? 'rgba(11, 56, 133, 0.39)'
          : 'rgba(255, 255, 255, 0.39)'};
    }
  }
  & .ant-pagination-item-active {
    color: ${props => (props.light === 'true' ? '#0b3885' : '#ffffff')};
    &:hover a,
    &:focus a {
      color: ${props => (props.light === 'true' ? '#0b3885' : '#ffffff')};
    }
  }

  & .ant-pagination-next .ant-pagination-item-link,
  .ant-pagination-prev .ant-pagination-item-link {
    border: none;
    background-color: transparent;

    color: ${props => (props.light === 'true' ? '#0b3885' : '#ffffff')};
    font-size: 8px;
  }

  & .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link {
    color: ${props =>
      props.light === 'true'
        ? 'rgba(11, 56, 133, 0.39)'
        : 'rgba(255, 255, 255, 0.39)'};
  }

  &
    .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis,
  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis {
    color: ${props =>
      props.light === 'true'
        ? 'rgba(11, 56, 133, 0.39)'
        : 'rgba(255, 255, 255, 0.39)'};
    display: inline-flex;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  &
    .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-link-icon,
  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-link-icon {
    color: ${props => (props.light === 'true' ? '#0b3885' : '#ffffff')};
    vertical-align: middle;
  }
`;

export const UserCellWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  img {
    width: 30px;
    height: 30px;
    margin: 0 5px;
    border-radius: 50%;
  }
`;

export const Text = styled.div`
  font-style: normal;
  line-height: normal;
  font-family: Neue Haas Grotesk Display Pro;
`;

export const TableContentWrapper = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
  margin: 0 128px;
`;

export const TableContentGamerDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 128px;
`;

export const HeaderContentWrapper = styled.div`
  display: flex;
  padding: 32px 0px;
  background: rgba(255, 255, 255, 0.9);
`;

export const HeaderContentGamerDataGameDropdownWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;

  @media (max-width: 1200px) {
  }
`;

export const HeaderContentItemWrapper = styled.div`
  gap: 8px;
  flex: 1 0 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-block: 10px;
`;

export const HeaderContentItemDetail = styled(Text)`
  color: #000;
  font-size: 14px;
  font-weight: 500;
`;
export const HeaderToggleItemDetail = styled.div`
  color: #000;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  margin-block: auto;
  height: 100%;

  @media (min-width: 1500px) and (max-width: 2000px) {
    width: 75%;
  }
`;

export const HeaderContentItemValue = styled(Text)`
  color: #000;
  font-size: 24px;
  font-weight: 600;
`;

export const BodyContentWrapper = styled.div``;

export const PopoverContentWrapper = styled.div`
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8px;
`;

export const PopoverContentBodyWrapper = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

export const PopoverContentHeaderWrapper = styled(Text)`
  color: #0b3885;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
`;

export const PopoverContentInput = styled(Input)`
  color: #0b3885;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .ant-input-affix-wrapper,
  .ant-input {
    border: none;
    outline: none;
    box-shadow: none;
    background-color: transparent;

    padding: 0;
    color: #0b3885;
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    font-style: normal;
    line-height: normal;
    font-family: Neue Haas Grotesk Display Pro;

    &:focus,
    &:hover,
    &.ant-input-affix-wrapper:focus,
    &.ant-input-affix-wrapper:hover {
      border: none;
      outline: none;
      box-shadow: none;
    }
    &::placeholder {
      color: #000;
      opacity: 0.24;
    }
    &:-ms-input-placeholder {
      color: #000;
      opacity: 0.24;
    }
    &::-ms-input-placeholder {
      color: #000;
      opacity: 0.24;
    }
    .anticon.anticon-close-circle {
      color: #000;
      opacity: 0.24;
    }
  }
`;

export const PopoverContentButtonWrapper = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .btn-cancel {
    padding: 0 12px;
    border-radius: 6.5px;
  }

  .btn-confirm {
    color: white;
    border: none;
    border-radius: 6.5px;
    background-color: #0b3885;

    :hover,
    :focus,
    :active {
      color: white;
      background-color: #0b3885;
      transform: scale(1.05);
    }

    :active {
      transform: scale(0.9);
    }

    ::after {
      display: none;
    }

    :disabled,
    :disabled:hover {
      background-color: #0b3885;
      opacity: 0.6;
      background-image: none !important;
      color: white;
    }
  }
`;

export const GamerDataUserInput = styled.div`
  width: 100%;
`;

export const GamerDataUserLable = styled.div``;

export const GamerDataGameOptionLable = styled.div`
  margin-block: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  gap: 20px;
  color: #fff;
  height: 39px;
  @media (max-width: 767px) {
    margin-bottom: 20px;
    justify-content: space-between;
  }
`;

export const SelectGameOption = styled(Select)<{
  overwriteMarginTop?: string;
  overwriteMarginLeft?: string;
  isGamerDataTable?: boolean;
}>`
  border: 1px solid rgba(255, 255, 255, 0.9);
  border-radius: 6.5px;
  color: #0b3885;
  font-size: 17px;
  font-weight: 600;
  height: 29px;
  width: 250px;
  text-align: center;
  background: rgba(255, 255, 255, 0.9);
  margin-left: ${({ overwriteMarginLeft }) => overwriteMarginLeft || ''};

  .ant-select-selector {
    .ant-select-selection-item {
      padding-right: 0;
      font-weight: 600;
      font-size: 17px;
      width: ${({ isGamerDataTable }) => (isGamerDataTable ? '150px' : '')};
    }
  }

  @media (min-width: 1800px) and (max-width: 2000px) {
    margin-left: ${({ isGamerDataTable }) => (isGamerDataTable ? '148px' : '')};
  }
  @media (min-width: 1600px) and (max-width: 1800px) {
    margin-left: ${({ isGamerDataTable }) => (isGamerDataTable ? '72px' : '')};
  }
  @media (min-width: 1500px) and (max-width: 1600px) {
    margin-left: ${({ isGamerDataTable }) => (isGamerDataTable ? '50px' : '')};
  }

  @media (max-width: 640px) {
    margin-top: 10px;
    margin-top: ${({ overwriteMarginTop }) => overwriteMarginTop || ''};
    width: 150px;
  }

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    display: flex;
    align-items: center;
    height: 29px;
    border-radius: 6.5px;
    border: none;
    background-color: rgba(243, 244, 246, 1);
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 36px 0 13px;
  }

  &.ant-select-dropdown.ant-select-dropdown-empty {
    border-radius: 6.5px;
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    box-shadow: none;
  }
`;

export const HeaderContentGamerDataWrapper = styled.div`
  gap: 8px;
  flex: 1 0 0px;
  display: flex;
  width: 50%;
  flex-direction: column;
  margin-left: 5%;
  margin-inline: auto;
  margin-block: 10px;
  @media (max-width: 767px) {
    width: 80%;
    justify-content: space-between;
  }
`;

export const GamerDataColumnWrapper = styled.div``;

export const GamerDataDateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: black;
  margin-top: 20px;

  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
`;

export const GamerDataTimePickerWrapper = styled(RangePicker)`
  height: 39px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.16);

  .ant-picker-clear {
    color: #fff;
    background-color: rgba(50, 88, 153, 1);
    border-radius: 50%;
  }

  .ant-picker-input > input {
    text-align: center;
    color: #fff;
  }

  .ant-picker-range-separator {
    color: #fff;
  }

  .ant-picker-suffix {
    color: #fff;
  }

  .anticon svg {
    color: #fff;
  }

  :hover {
    .ant-picker-suffix {
      opacity: 0;
    }
  }
`;
export const GamerDataSelectDaysFilter = styled(Select)`
  border: 1px solid rgba(255, 255, 255, 0.9);
  /* border-radius: 6.5px; */
  color: #0b3885;
  font-size: 17px;
  font-weight: 600;
  height: 38px;
  width: 140px;
  text-align: center;
  background: rgba(255, 255, 255, 0.9);
  margin-left: 20px;

  .ant-select-selector {
    .ant-select-selection-item {
      padding-right: 0;
      font-size: 15px;
      color: #fff;
    }
  }

  @media (max-width: 640px) {
    margin-top: 10px;
    width: 150px;
  }

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    display: flex;
    align-items: center;
    height: 38px;
    border: none;
    background-color: rgba(243, 244, 246, 1);
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background: transparent;
    color: white;
    padding: 0 36px 0 13px;
  }

  &.ant-select-dropdown.ant-select-dropdown-empty {
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
  }

  &.ant-select {
    border: 1px solid rgba(50, 88, 153, 1);
    background: none;

    :hover {
      border: 1px solid rgba(64, 169, 255, 1);
    }

    & .ant-select-arrow {
      svg path {
        fill: #fff;
      }
    }
  }
`;

export const GamerDataInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 95%;
  max-width: 100%;
  height: 39px;
  margin-block: auto;
  padding-left: 17%;
  padding-right: 12%;
  gap: 25px;
`;

export const GamerDataInfoTextWrapper = styled.div`
  display: flex;
`;

export const GamerDataInfoTitleText = styled(Text)`
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin-right: 10px;
  margin-block: auto;
  min-width: 75px;
`;
export const ScoreTextWrapper = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  display: flex;
`;

export const GamerDataInputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`;

export const ActionButtonWrapper = styled.div`
  margin-top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const GamerDataCheckboxWrapper = styled.div`
  margin-left: 10px;
`;

export const GameRevenueCell = styled.div`
  display: flex;
  justify-content: center;
`;

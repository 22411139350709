import styled from 'styled-components';

export const InputWrapper = styled.div<{
  overwriteDisplay?: string;
  overwriteJustifyContent?: string;
  overwriteMinWidth?: string;
}>`
  text-align: left;
  display: ${({ overwriteDisplay }) => overwriteDisplay || ''};
  justify-content: ${({ overwriteJustifyContent }) =>
    overwriteJustifyContent || ''};
  min-width: ${({ overwriteMinWidth }) => overwriteMinWidth || ''};
  & label {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  & .ant-input,
  & .ant-input-affix-wrapper {
    font-size: 16px;
    color: #fff;
    background-color: transparent;
  }
  @media (max-width: 500px) {
    min-width: ${({ overwriteMinWidth }) => (overwriteMinWidth ? '100px' : '')};
  }
`;

export const WarningStyled = styled.p`
  font-size: 12px;
  color: red;
  margin-top: 10px;
  margin-bottom: 0;
`;

export const AvatarImg = styled.img`
  width: 30px;
  height: 30px;
  margin: 0 5px;
  border-radius: 50%;
`;

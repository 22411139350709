import { useEffect, useState } from 'react';
import { getTotalArcadeRevenue } from 'services/api';

export interface ITotalArcadeRevenue {
  totalEntryFee: string;
  name: string;
  symbol: string;
  address: string;
  decimals: number;
  tokenPrice: string;
}

export const useGetTotalArcadeRevenue = (refresh?: boolean) => {
  const [totalArcadeRevenue, setTotalArcadeRevenue] =
    useState<ITotalArcadeRevenue>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    void (async () => {
      setLoading(true);
      const res = await getTotalArcadeRevenue();
      if (res?.success) {
        setTotalArcadeRevenue(res?.payload?.data);
      }
      setLoading(false);
    })();
  }, [refresh]);
  return { totalArcadeRevenue, loading };
};

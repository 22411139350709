import closeicon from 'assets/vectors/logo-profile-close.svg';
import { ApplicationModal } from 'redux/slices/application';
import { useModalIsOpen, useToggleModal } from 'redux/slices/application/hook';

import {
  ButtonWrapper,
  CloseIcon,
  CloseWrapper,
  LinkButton,
  ModalWithdrawContainer,
  ModalWithdrawContent,
  ModalWithdrawWrapper,
  ModalWrapper,
  SubmitButton,
  Text,
} from './styled';

interface IProps {
  value: any;
  handleWithdraw: () => void;
  loading: boolean;
}

export const WithdrawModal = ({ value, handleWithdraw, loading }: IProps) => {
  const open = useModalIsOpen(ApplicationModal.WITHDRAW);
  const toggleModal = useToggleModal(ApplicationModal.WITHDRAW);
  const handleCancel = () => {
    toggleModal();
  };

  return (
    <ModalWrapper
      visible={open}
      onCancel={handleCancel}
      maskClosable={true}
      footer={null}
      closable={false}
      width="520px"
    >
      <ModalWithdrawWrapper>
        <CloseWrapper>
          <CloseIcon src={closeicon} onClick={handleCancel} />
        </CloseWrapper>
        <ModalWithdrawContainer>
          <ModalWithdrawContent>
            <Text>
              <b>{value || 'xx'} BIGA</b> will be locked for proceeding
              transaction. If in case transaction is failed, your{' '}
              <b>{value || 'xx'} BIGA</b> will be unlocked after <b>05 mins.</b>
            </Text>
            <ButtonWrapper>
              <SubmitButton
                height="40px"
                width="250px"
                loading={loading}
                onClick={handleWithdraw}
              >
                I understand and continue
              </SubmitButton>
              <LinkButton height="40px" width="250px" onClick={handleCancel}>
                Close and go back
              </LinkButton>
            </ButtonWrapper>
          </ModalWithdrawContent>
        </ModalWithdrawContainer>
      </ModalWithdrawWrapper>
    </ModalWrapper>
  );
};

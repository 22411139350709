import editImg from 'assets/vectors/login-edit.svg';
import { GaActions, GaCategories, GaLabels } from 'constants/enum/GaEvent';
import { PitRouter } from 'constants/routers';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';

import {
  RegisterFirstStepButtonWrapper,
  RegisterFirstStepFooter,
  RegisterFirstStepWrapper,
  RegisterStepButton,
  RegisterStepDescription,
  RegisterStepLink,
  RegisterStepTitle,
} from './styled';

interface IProps {
  onSignUp: () => void;
}

export const FirstStep = ({ onSignUp }: IProps) => {
  const navigate = useNavigate();

  const handleSignUpClick = () => {
    ReactGA.event({
      category: GaCategories.SIGNUP,
      action: GaActions.ACTION_SIGNUP,
      label: GaLabels.LABEL_SIGNUP,
    });

    onSignUp();
  };

  return (
    <RegisterFirstStepWrapper>
      <RegisterStepTitle>
        Sign Up and become a member of BIGA Arcade
      </RegisterStepTitle>
      <RegisterFirstStepButtonWrapper onClick={handleSignUpClick}>
        <RegisterStepButton>
          <img src={editImg} alt="edit" />
          <span>Sign up</span>
        </RegisterStepButton>
      </RegisterFirstStepButtonWrapper>

      <RegisterFirstStepFooter>
        <RegisterStepDescription>
          Already have an account?
        </RegisterStepDescription>
        <RegisterStepLink
          onClick={() => {
            navigate(PitRouter.LOGIN);
          }}
        >
          Log In
        </RegisterStepLink>
      </RegisterFirstStepFooter>
    </RegisterFirstStepWrapper>
  );
};

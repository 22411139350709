/* eslint-disable react-hooks/exhaustive-deps */
import { useWeb3React } from '@web3-react/core';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { PitRouter } from 'constants/routers';
// import { resetPassesState } from "redux/slices/passes";
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from 'redux/slices/user';
import { LocalhostStorage } from 'utils/sessionStorage';

export const useDisconnect = () => {
  const { deactivate, connector } = useWeb3React();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleDeactivate = useCallback(() => {
    deactivate();

    if (connector instanceof WalletConnectConnector) {
      connector.walletConnectProvider = undefined;
    }
  }, [connector, deactivate]);
  const disconnect = async () => {
    try {
      const path = LocalhostStorage.get('path');
      handleDeactivate();
      // LocalhostStorage.clear();
      LocalhostStorage.remove('isConnected');
      LocalhostStorage.remove('account');
      LocalhostStorage.remove('chainId');
      LocalhostStorage.remove('typeOfConnector');
      LocalhostStorage.remove('path');
      LocalhostStorage.set('path', path);
      dispatch(logout());
      // navigate(PitRouter.CONNECT);
    } catch (error) {
      console.log({ error });
    }
  };
  return useCallback(disconnect, [dispatch, navigate, deactivate]);
};

import { PlayerRoles } from 'constants/enum/PlayerRole';
import { PitRouter } from 'constants/routers';
import { useGetPlayer } from 'hooks/player/useGetPlayer';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/store';

interface IProps {
  children;
  roles: PlayerRoles[];
}
export const RoleRoute = ({ children, roles }: IProps) => {
  const navigate = useNavigate();
  // const { playerInfo } = useGetPlayer(null);

  const { playerInfo: player } = useAppSelector(state => state.player);

  useEffect(() => {
    if (!player) {
      navigate(PitRouter.REGISTER);
    }
    if (player && !player?.roles.some(i => roles.some(j => i === j))) {
      navigate(PitRouter.GAME_LIST);
    }
  }, [navigate, player, roles]);

  return children;
};
